import { createAsyncThunk } from "@reduxjs/toolkit";
import { crypt, TokenHandler } from "../../helper";
import { GetUser } from "../GetUser";

export const LogWithPin = createAsyncThunk(
  "Auth/LogWithPin",
  async (pin, { dispatch, rejectWithValue }) => {
    if (crypt.PinCorrect(pin)) {
      crypt.StoreDecryptedKey(pin);
      TokenHandler.StoreDecryptedToken(pin);
      dispatch(GetUser());
      return { Access: true, show: true, to: "Main" };
    } else {
      return rejectWithValue({
        code: "0",
        errorContent: "Code PIN incorrect",
      });
    }
  }
);
