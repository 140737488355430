import React from "react";
import { connect } from "react-redux";
import ChampLogin from "../Login/ChampLogin";
import { crypt } from "../../helper";
import Modal from "./Modal";

const ModalAskPin = ({ ShowModal, hideAskPin, dispatch }) => {
  const [pin, setPin] = React.useState("");
  const [pinError, setPinError] = React.useState(false);
  const [error, setError] = React.useState("");
  const [nbrTry, setNbrTry] = React.useState(0);
  const HandleSubmit = async (e) => {
    e.preventDefault();
    if (pin.length < 4) {
      setError("Le code PIN doit être constitué de 4 à 8 chiffres");
      setPinError(true);
    } else {
      setPinError(false);
      setError("");
      setNbrTry(nbrTry + 1);
      if (crypt.PinCorrect(pin.toString())) {
        setPin("");
        setPinError(false);
        setError("");
        setNbrTry(0);
        hideAskPin({ status: true });
      } else {
        setError("Le code PIN est incorrect");
      }
    }
  };
  if (nbrTry >= 5)
    return (
      <div>
        <h1>Trop d'essais simultanés, veuillez actualiser la page.</h1>
      </div>
    );
  return (
    <Modal
      show={ShowModal.askPin}
      hide={hideAskPin}
      label={`Code PIN ${ShowModal.askPinFor}`}
    >
      <span>{error}</span>
      <ChampLogin
        id="pin"
        name="Veuillez confirmer votre code PIN"
        type="password"
        inputmode="numeric"
        value={pin}
        setValue={setPin}
        error={pinError}
        setError={setPinError}
        classN=""
        max="99999999"
        min="0"
        required
      />
      <div className="form__btns">
        <button className="btn btn--secondary" onClick={HandleSubmit}>
          Valider le code PIN
        </button>
        <button
          className="btn btn--light btn--link"
          onClick={() => dispatch(hideAskPin())}
        >
          Annuler
        </button>
      </div>
    </Modal>
  );
};

export default connect(({ ShowModal }) => ({
  ShowModal: {
    askPin: ShowModal.askPin,
    askPinFor: ShowModal.askPinFor,
  },
}))(
  React.memo(ModalAskPin, (oldProps, newProps) => {
    if (
      oldProps.ShowModal.askPin !== newProps.ShowModal.askPin ||
      newProps.ShowModal.askPin
    )
      return false;
    return true;
  })
);
