import React from "react";
import { connect } from "react-redux";
import GoToPWA from "./ServiceWorker/GoToPWA";
import ModalInstall from "./ServiceWorker/ModalInstall";
import NeedUpdate from "./ServiceWorker/NeedUpdate";

const ServiceWorker = () => {
  return (
    <>
      <ModalInstall />
      <GoToPWA />
      <NeedUpdate />
      <link rel="dns-prefetch" href={process.env.REACT_APP_SERVEUR} />
    </>
  );
};

export default connect()(ServiceWorker);
