import React from "react";
import { ChildHandler } from "../../../helper";
import { PhoneNumber } from "./PhoneNumber";

const ModalChildBodyDispParent = ({ contact, link_type }) => {
  return (
    <div className="contact__line grid__col6">
      {contact.has_photo
        ? <img
            className="contact__photo"
            src={contact.photo}
            alt={contact.first_name + " " + contact.last_name}
          />
        : <div className="contact__nophoto">
            <svg className="icon icon__no_photography" focusable="false">
              <use
                href={
                  process.env.PUBLIC_URL + "/picto/symbol-defs.svg#icon__no_photography"
                }
              ></use>
            </svg>
          </div>
      }
      <div className="contact__infos">
        <p className="contact__name">
          {contact.first_name} {contact.last_name} (
          {ChildHandler.GetStringLinkType()[link_type]})
        </p>
        <p>
          Téléphone :{" "}
          <PhoneNumber phone={contact.main_phone_number} />
        </p>
        {contact.backup_phone_number != null && (
          <p>
            Téléphone :{" "}
            <PhoneNumber phone={contact.backup_phone_number} />
          </p>
        )}
      </div>
    </div>
  );
};

export default ModalChildBodyDispParent;
