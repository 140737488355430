import React from "react";
import { connect } from "react-redux";
import ChampLogin from "./ChampLogin";
import { LogWithPin as LogWithPinAction } from "../../actions/Auth/LogWithPin";
import { ShowLostPin } from "../../actions/Auth/LostPin";
import { YouAreNotShow } from "../../actions/Auth/YouAreNot";

function LogWithPin({ Auth, dispatch, ...props }) {
  const [pin, setPin] = React.useState("");
  const [pinError, setPinError] = React.useState(false);
  const [error, setError] = React.useState("");
  const [nbrTry, setNbrTry] = React.useState(0);
  const [countDown, setCountDown] = React.useState(30);
  const HandleSubmit = async (e) => {
    e.preventDefault();
    if (pin.length < 4) {
      setError("Le code PIN doit être constitué de 4 chiffres minimum");
      setPinError(true);
    } else {
      setPinError(false);
      setError("");
      setNbrTry(nbrTry + 1);
      dispatch(LogWithPinAction(pin)).then((action) => {
        if (action.meta.requestStatus === "rejected" && nbrTry >= 4) {
          const decrement = (downCount) => () => {
            if (downCount > 0) {
              setCountDown(downCount - 1);
              setTimeout(decrement(downCount - 1), 1000);
            } else {
              setError("");
              setPin("");
            }
          };
          setTimeout(decrement(30), 1000);
        }
      });
    }
  };
  if (nbrTry >= 5)
    return (
      <div>
        <h1>Trop d'essais simultanés</h1>
        <p>Veuillez patienter {countDown} secondes avant de réessayer.</p>
        <button
          onClick={() => {
            setNbrTry(0);
          }}
          disabled={countDown > 0}
        >
          Réessayer
        </button>
      </div>
    );
  return (
    <>
      <div className="grid__col6">
        <img
          src={
            Auth.SessionData.CentreImage
              ? Auth.SessionData.CentreImage
              : process.env.PUBLIC_URL + "/pirouette.png"
          }
          alt="Logo Pirouette"
          className="login__logo"
        />
        <p className="login__version">v{process.env.REACT_APP_VERSION}</p>
      </div>

      <div className="grid__col6">
        <form onSubmit={HandleSubmit}>
          <ChampLogin
            id="pin"
            name="Code PIN"
            type="password"
            inputmode="numeric"
            value={pin}
            setValue={setPin}
            error={pinError}
            setError={setPinError}
            max="99999999"
            min="0"
            required
          />

          <span className="form--errormsg">
            {error}
            {Auth.ErrorContent !== null &&
            Auth.ErrorContent !== undefined &&
            Auth.ErrorContent.hasOwnProperty("message")
              ? Auth.ErrorContent.message
              : Auth.ErrorContent}

            {nbrTry > 0 && <p>Il vous reste {5 - nbrTry} essais</p>}
          </span>

          <div className="form__btns">
            <button className="btn btn--secondary" onClick={HandleSubmit}>
              Connexion
            </button>
          </div>

          <div className="form__grp form__grp--inline login__sublinks">
            <button
              className="link__lookalike"
              onClick={() => dispatch(ShowLostPin())}
            >
              Code PIN oublié
            </button>
            <a
              href="/Login"
              onClick={(e) => {
                e.preventDefault();
                dispatch(YouAreNotShow());
              }}
            >
              Vous n'êtes pas {Auth.SessionData.Name}{" "}
              {Auth.SessionData.usersurname} ?
            </a>
          </div>
        </form>
      </div>
    </>
  );
}

export default connect((state) => state)(LogWithPin);
