import React from "react";
import { connect } from "react-redux";
const Spinner = ({ spin }) => {
  if (!spin) return <></>;
  return (
    <div className="spinner__container">
      <div className="spinner--backdrop"></div>
      <div className="spinner">
        <p>Chargement en cours...</p>
      </div>
    </div>
  );
};

export default connect(({ Auth, Child, Pointage, serviceWorker, Synchro }) => ({
  spin:
    Auth.Pending || Child.Pending || Pointage.Pending || serviceWorker.Pending || Synchro.Pending,
}))(Spinner);
