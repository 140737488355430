import { useState, useLayoutEffect } from "react";

export const useWindowResize = () => {
  const [size, setSize] = useState([window.innerWidth, window.innerHeight]);

  useLayoutEffect(() => {
    function updateSize() {
      if (sessionStorage.getItem("dontReRender") !== "true") {
        setSize([window.innerWidth, window.innerHeight]);
      }
    }

    window.addEventListener("resize", updateSize);
    updateSize();

    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return size;
};
