import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Db, crypt, ChildHandler, GroupsHandler, MealServicesHandler, AutorisationsHandler, VigilancesHandler } from "../../helper";
import { formatImage } from "../../helper/compressPicture";
import {
  getAvailablePrestations,
  filterChild,
  userFilter,
} from "../../helper/filterChild";
import { tri } from "../../helper/orderChild";
import { ApplyFilter, setFilter, setTri, startupGetData } from "./Pointage";
import { delReservation } from "./Resa";
import _ from "lodash";
import { dateIsBetweenOrEqualDate, dateStringToDate } from "../../helper/date";
import { getLocationID } from "../../helper/useLocationId";
/**
 * * Action that get the list of the child
 */
export const FetchChild = createAsyncThunk(
  "database/getChild",
  async ({ pendingEnd = true, Force } = {}) => {
    return ChildHandler.GetChildTable(new Db(crypt.getSessionDecryptedKey()))
      .toArray()
      .then((child) => ({ childs: child, pendingEnd, Force }));
  },
  {
    condition: ({ Force = false } = {}, { getState }) => {
      const { Child } = getState();
      if (Force) return true;
      if (Child === null || Child.Pending === true || Child.child.length > 0)
        return false;
      return true;
    },
  }
);

export const FetchSomeChildOrderedByBirthday = createAsyncThunk(
  "database/getSomeChildBirthday",
  async (unknow, { getState, dispatch }) => {
    const { Child, Pointage } = getState();
    return new Promise((resolve, reject) => {
      if (Child.child.length > 0) return resolve(Child.child);
      dispatch(FetchChild({ Force: true, pendingEnd: false })).then(
        ({ payload }) => {
          if (!payload) return reject();
          resolve(payload.childs);
        }
      );
    }).then((childs) => {
      return [...childs]
        .filter(
          (child) =>
            child.signups.find(
              (value) =>
                value.year_id ===
                Pointage.Signup.find((signupG) =>
                  dateIsBetweenOrEqualDate(
                    new Date(),
                    dateStringToDate(signupG.start_date),
                    dateStringToDate(signupG.end_date)
                  )
                ).id
            ) !== undefined
        )
        .sort((a, b) => {
          var parsedA = new Date(a.birth_date),
            parsedB = new Date(b.birth_date);

          parsedA.setFullYear(new Date().getFullYear());
          parsedB.setFullYear(new Date().getFullYear());

          // Si le mois de l'anniversaire est inférieur au mois actuel
          if (parsedA.getMonth() < new Date().getMonth()) {
            // On ajoute un à l'année
            parsedA.setFullYear(parsedA.getFullYear() + 1);
          }
          if (parsedB.getMonth() < new Date().getMonth()) {
            // On ajoute un à l'année
            parsedB.setFullYear(parsedB.getFullYear() + 1);
          }
          // Si le mois est égal au mois actuel
          if (parsedA.getMonth() === new Date().getMonth()) {
            // Si le jour est inférieur au jour actuel
            if (parsedA.getDate() < new Date().getDate()) {
              // On ajoute un à l'année
              parsedA.setFullYear(parsedA.getFullYear() + 1);
            }
          }
          if (parsedB.getMonth() === new Date().getMonth()) {
            // Si le jour est inférieur au jour actuel
            if (parsedB.getDate() < new Date().getDate()) {
              // On ajoute un à l'année
              parsedB.setFullYear(parsedB.getFullYear() + 1);
            }
          }

          var comparA = parsedA.getTime(),
            comparB = parsedB.getTime();

          // Et retourne au final -1 si parse A est inférieur à B et 1 s'il est supérieur
          return (comparA > comparB) - (comparA < comparB);
        });
    });
  }
);

export const FetchAllChild = createAsyncThunk(
  "database/getAllChild",
  async (unknow, { getState, dispatch }) => {
    const { Child } = getState();
    return new Promise((resolve, reject) => {
      if (Child.child.length > 0) return resolve(Child.child);
      dispatch(FetchChild({ Force: true, pendingEnd: false })).then(
        ({ payload }) => {
          if (!payload) return reject();
          resolve(payload.childs);
        }
      );
    }).then((childs) => {
      return [...childs].sort((childA, childB) =>
        childA.last_name.toLowerCase().localeCompare(childB.last_name.toLowerCase())
      );
    });
  }
);

export const FetchSomeChild = createAsyncThunk(
  "database/getSomeChild",
  async (
    { Resa, date, prestations, momentId, isStarting } = {},
    { getState, dispatch }
  ) => {
    const { Child, Pointage } = getState();
    return new Promise((resolve, reject) => {
      if (Child.child.length > 0) return resolve(Child.child);
      dispatch(FetchChild({ Force: true, pendingEnd: false })).then(
        ({ payload }) => {
          if (getState().Child.child.length > 0)
            return resolve(getState().Child.child);
          if (!payload) return reject();
          return resolve(payload.child);
        }
      );
    })
      .then((childs) => {
        let resas = (Resa !== undefined ? Resa : Pointage.Reservation).filter(
          (resa) => {
            if (
              [10, 8, 6].some((type) =>
                getLocationID(document.location).includes(type)
              )
            ) {
              return (
                String(document.location.pathname.split("/")[3]) ===
                String(resa.prestation_id)
              );
            } else return true;
          }
        );
        return childs.filter((child) =>
          filterChild(
            child,
            Child.showAllChild,
            getAvailablePrestations(
              prestations || Pointage.Prestations,
              momentId || Pointage.momentID,
              Pointage.prestationsType,
            ),
            resas,
            Pointage.search,
            Pointage.Signup,
            date ? date : Pointage.date,
            Pointage.holidays,
          )
        )
      }
      )
      .then((childs) => {
        return ({
        child: childs
          .filter((child) => {
            return userFilter(
              child,
              Pointage.filter,
              Pointage.Signup,
              date ? date : Pointage.date,
              getAvailablePrestations(
                prestations || Pointage.Prestations,
                momentId || Pointage.momentID,
                Pointage.prestationsType
              ),
              Resa !== undefined ? Resa : Pointage.Reservation,
              Pointage.holidays,
              momentId || Pointage.momentID
            )
          })
          .sort(tri[Pointage.tri].call(Pointage)),
        nbrChild: childs.length,
      })});
  }
);

/**
 * * Action that show the Child Data Modal
 */
export const ShowChildModal = createAsyncThunk(
  "database/getChildForModal",
  async ({ ChildID, date }, { dispatch, getState }) => {
    var { Child } = getState();
    // if (Child.childModal?.id === ChildID)
    //   return { child: Child.childModal, date };
    var db = new Db(crypt.getSessionDecryptedKey());
    var activeChild = _.cloneDeep(
      Child.child.find((child) => child.id === ChildID)
    );
    return {
      child: await ChildHandler.GetFullChild(db, activeChild),
      date,
    };
  },
  {
    condition: (Force = false, { getState }) => {
      const { Child } = getState();
      if (Child !== null && Child.Pending === true && Boolean(Force) === false)
        return false;
    },
  }
);

/**
 * * Action That Allow the user to change the picture of the child
 */
export const ChangeChildPicture = createAsyncThunk(
  "database/ChangeChildPicture",
  async ({ ChildId, Picture }, { dispatch, getState }) => {
    var { Child } = getState();
    return formatImage(Picture)
      .then((compressedPicture) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.addEventListener("load", (eventLoader) => {
            resolve(eventLoader.target.result);
          });
          reader.readAsDataURL(compressedPicture.file);
        });
      })
      .then((compressedBase64) => {
        return ChildHandler.ChangeChildPicture(
          new Db(crypt.getSessionDecryptedKey()),
          ChildId,
          compressedBase64
        ).then(() => {
          dispatch(
            ShowChildModal({ ChildID: ChildId, date: Child.dateChildModal })
          );
          dispatch(FetchChild(true));
          return {
            ...Child.child.find((child) => child.id === ChildId),
            has_photo: true,
            photo: compressedBase64,
            PictureChange: 1,
          };
        });
      });
  }
);

export const setShowAllChild = createAsyncThunk(
  "database/showAllChild",
  async ({ checked, fetch = true }, { dispatch }) => {
    if (checked) {
      dispatch(setFilter({ categorie: "Special", checked: true, id: 1 }));
      dispatch(ApplyFilter());
    } else {
      dispatch(setFilter({ categorie: "Special", checked: false, id: 1 }));
      dispatch(ApplyFilter());
    }
    dispatch(LocalSetShowAllChild(checked));
    if (fetch) dispatch(FetchSomeChild({}));
  }
);

export const getChildGroup = createAsyncThunk(
  "database/GroupGetter",
  async () => GroupsHandler.getAllGroups(new Db(crypt.getSessionDecryptedKey()))
);

export const getChildMealServices = createAsyncThunk(
  "database/MealServicesGetter",
  async () => MealServicesHandler.getAllMealServices(new Db(crypt.getSessionDecryptedKey()))
);

export const getAutorisations = createAsyncThunk(
  "database/AutorisationGetter",
  async () => AutorisationsHandler.getAllAutorisations(new Db(crypt.getSessionDecryptedKey()))
);

export const getVigilances = createAsyncThunk(
  "database/VigilancesGetter",
  async () => VigilancesHandler.getAllAutorisations(new Db(crypt.getSessionDecryptedKey()))
);

export const setTriChildren = createAsyncThunk(
  "child/tri",
  async (trie, { getState, dispatch }) => {
    var { Child, Pointage } = getState();
    dispatch(setTri(trie));
    return [...Child.filteredChild].sort(tri[trie].call(Pointage));
  }
);

export const ChildSlice = createSlice({
  name: "Child",
  initialState: {
    child: [],
    filteredChild: [],
    Pending: false,
    showModal: false,
    childModal: {},
    nbrChild: 0,
    activeSignup: {},
    showAllChild: false,
    childGroup: [],
    childMealService: [],
    autorisations: [],
    vigilances: [],
    dateChildModal: "",
  },
  reducers: {
    hide: (state) => {
      state.showModal = false;
    },
    setShowAllChild: (state, { payload }) => {
      state.showAllChild = payload;
    },
    emptyFilteredChild: (state) => {
      state.filteredChild = [];
    },
    emptyChild: (state) => {
      state.filteredChild = [];
      state.child = [];
    },
    emptyChildModal: (state) => {
      state.childModal = {};
    }
  },
  extraReducers: {
    [delReservation.fulfilled]: (state, { payload }) => {
      if (payload === undefined || payload.secondary || state.showAllChild)
        return;
      state.filteredChild.splice(
        state.filteredChild.findIndex((value) => value.id === payload.childId),
        1
      );
    },
    [FetchChild.fulfilled]: (state, { payload }) => {
      if (payload.pendingEnd) state.Pending = false;
      state.child = payload.childs;
    },
    [FetchChild.pending]: (state, action) => {
      state.Pending = true;
    },
    [FetchChild.rejected]: (state, action) => {
      state.Pending = false;
    },
    [FetchSomeChild.fulfilled]: (state, { payload }) => {
      if (!payload.Force) {
        state.Pending = false;
      }
      state.filteredChild = payload.child;
      state.nbrChild = payload.nbrChild;
    },
    [FetchSomeChildOrderedByBirthday.fulfilled]: (state, { payload }) => {
      state.filteredChild = payload;
      state.Pending = false;
    },
    [FetchSomeChildOrderedByBirthday.pending]: (state, { payload }) => {
      state.Pending = true;
    },
    [FetchSomeChildOrderedByBirthday.rejected]: (state, { payload }) => {
      state.Pending = false;
    },
    [FetchAllChild.fulfilled]: (state, { payload }) => {
      state.filteredChild = payload;
      state.Pending = false;
    },
    [FetchAllChild.pending]: (state, { payload }) => {
      state.Pending = true;
    },
    [FetchAllChild.rejected]: (state, { payload }) => {
      state.Pending = false;
    },
    [setTriChildren.rejected]: (state, { payload }) => {
      state.Pending = false;
    },
    [setTriChildren.pending]: (state, { payload }) => {
      state.Pending = true;
    },
    [setTriChildren.fulfilled]: (state, { payload }) => {
      state.filteredChild = payload;
      state.Pending = false;
    },
    [FetchSomeChild.pending]: (state, action) => {
      state.Pending = true;
    },
    [FetchSomeChild.rejected]: (state, action) => {
      state.Pending = false;
    },
    [ShowChildModal.rejected]: (state, action) => console.log,
    [ShowChildModal.pending]: (state, action) => {
      // state.Pending = true;
    },
    [ShowChildModal.fulfilled]: (state, action) => {
      // state.Pending = false;
      state.showModal = true;
      state.childModal = action.payload.child;
      state.dateChildModal = action.payload.date;
    },
    [ChangeChildPicture.rejected]: (state, action) => console.log,
    [ChangeChildPicture.fulfilled]: (state, { payload }) => {
      state.child.splice(
        state.child.findIndex((value) => value.id === payload.id),
        1,
        payload
      );
      state.filteredChild.splice(
        state.filteredChild.findIndex((value) => value.id === payload.id),
        1,
        payload
      );
      if (state.childModal.id === payload.id) {
        state.childModal.photo = payload.photo;
        state.childModal.has_photo = true;
        state.childModal.new_photo = payload.photo;
      }
      state.Pending = false;
    },
    [ChangeChildPicture.pending]: (state, action) => {
      state.Pending = true;
    },
    [getChildGroup.fulfilled]: (state, { payload }) => {
      state.childGroup = payload;
    },
    [startupGetData.fulfilled]: (state, { payload }) => {
      state.childGroup = payload.group;
      state.childMealService = payload.meal_service;
      state.autorisations = payload.autorisations;
      state.vigilances = payload.vigilances;
      state.child = payload.child;
    },
    [getChildMealServices.fulfilled]: (state, { payload }) => {
      state.childMealService = payload;
    },
    [getAutorisations.fulfilled]: (state, { payload }) => {
      state.autorisations = payload;
    },
    [getVigilances.fulfilled]: (state, { payload }) => {
      state.vigilances = payload;
    },
  },
});
export const {
  hide,
  setShowAllChild: LocalSetShowAllChild,
  emptyFilteredChild,
  emptyChild,
  emptyChildModal,
} = ChildSlice.actions;
export default ChildSlice.reducer;
