import React from "react";
import { connect } from "react-redux";
import { findAllReservationSecondaryByChildId } from "../../../../helper";
import { filterPrestation } from "../../../../helper/filterChild";
import ResaSecondaireButton from "./ResaSecondaireButton";
import _ from "lodash";

const prestaSecondaire = ({ childId, className, availablePresta, resa, mainPresta = null, }) => {
  return (
    <div className={`${className}`}>
      {availablePresta.map((prestation) => {
        var resaSecondaire = resa.find(
          (resaSecondary) => resaSecondary.prestation_id === prestation.id
        );
        return (
          <ResaSecondaireButton
            key={prestation.id}
            presta={prestation}
            childId={childId}
            isUnexpected={resaSecondaire?.is_unexpected}
            resaId={resaSecondaire?.id}
            resaIsPresent={resaSecondaire?.is_present}
            mainPresta={mainPresta}
          />
        );
      })}
    </div>
  );
};

export default connect(({ Child, Pointage }, { childId }) => ({
  availablePresta: Pointage.Prestations.filter(
    (prestation) =>
      filterPrestation(
        prestation,
        Child.filteredChild.find((child) => child.id === childId),
        true,
        Pointage.Signup,
        Pointage.date,
        Pointage.holidays
      ) &&
      prestation.secondary === true &&
      (Pointage.momentID === 1 || prestation.moment === 1
        ? true
        : prestation.moment === Pointage.momentID)
  ),
  resa: findAllReservationSecondaryByChildId(
    Pointage.Reservation,
    Pointage.Prestations,
    childId
  ),
  moment: Pointage.momentID,
}))(
  React.memo(prestaSecondaire, (oldProps, newProps) => {
    if (
      _.isEqual(oldProps.resa, newProps.resa) &&
      oldProps.resa.length === 0 &&
      newProps.resa.length === 0 &&
      oldProps.moment === newProps.moment
    )
      return true;
  })
);
