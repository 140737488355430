import { setDateToHourZero } from "./date";
import { getLocationID } from "./useLocationId";
import { momentParser, prestationsParser } from "./user";

export const setTittle = (location, Where, { date, momentID }) => {
  var title = ["Pirouette"];
  if (location.pathname !== "/" && Where) {
    title[0] = Where.Label;
  }
  if (location.pathname.split("/")[2] !== undefined) {
    title[0] =
      Object.values(prestationsParser()).find(
        (presta) => presta.url === location.pathname.split("/")[2]
      )?.Label || "";
    if (
      getLocationID(location) !== [-1] &&
      (([6, 8, 10].some((id) => getLocationID(location).includes(id)) &&
        location.pathname.split("/").length > 3) ||
        ![6, 8, 10].some((id) => getLocationID(location).includes(id)))
    ) {
      if (
        setDateToHourZero(date).getTime() !== setDateToHourZero(0).getTime()
      ) {
        title.push(new Date(date).toLocaleDateString("fr-fr"));
        if (momentID !== -1 && momentID !== 1) {
          title.push(momentParser()[momentID].label);
        }
      } else if (
        ![6, 8, 10].some((id) => getLocationID(location).includes(id))
      ) {
        title.push("Redirection");
      }
    }
  }

  document.title = title.join(" > ");
};
