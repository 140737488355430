import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router";
import { setDate, setPrestationsId } from "../../../actions/Database/Pointage";
import { dateStringToDate } from "../../../helper/date";
import { useLocationId } from "../../../helper/useLocationId";
import PrestaSelector from "./PrestaSelector";

const SelectSingleDate = ({
  prestationsType,
  Prestations,
  dispatch,
  idDate,
}) => {
  const location = useLocation();
  const [locationId, locationTag] = useLocationId();
  useEffect(() => {
    if ([8].some((value) => locationId.includes(value)) && idDate === -1)
      new Promise((resolve, reject) => {
        if (Prestations.length === 0)
          dispatch(
            setPrestationsId({
              location: locationTag,
              init: true,
            })
          ).then((result) => {
            return resolve(result.payload.payload.prestas);
          });
        else return resolve(Prestations);
      }).then((prestations) => {
        var presta = prestations.find(
          ({ id }) => String(id) === String(location.pathname.split("/")[3])
        );
        if (presta !== undefined) {
          dispatch(setDate({ date: new Date(presta.start_date), dateId: 1 }));
        }
      }); // eslint-disable-next-line
  }, [locationId, idDate]);
  if (
    ![8].some((value) => locationId.includes(value)) ||
    location.pathname.split("/").length !== 4
  )
    return <></>;
  if (Prestations.length === 0) {
    return <></>;
  }
  var activePresta = Prestations.find(
    (presta) => String(presta.id) === String(location.pathname.split("/")[3])
  );
  return (
    <>
      <PrestaSelector />
      {activePresta && (
        <span>
          {dateStringToDate(activePresta.start_date).toLocaleDateString()}
        </span>
      )}
    </>
  );
};

export default connect(({ Pointage }) => ({
  prestationsType: Pointage.prestationsType,
  Prestations: Pointage.Prestations,
  idDate: Pointage.idDate,
}))(SelectSingleDate);
