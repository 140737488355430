import React from "react";
import { Route } from "react-router-dom";
import { connect } from "react-redux";
import {
  online,
  offline,
  setShowFilterPointage,
} from "../../actions/showModal";
import { GetLastDateSynchro } from "../../actions/Database/Synchro";
import Ariane from "./ariane";
import ActionTopPointage from "./ActionTopPointage";
import TopNav from "./topbar/topNavBar";
import { OnlineHandler } from "../../helper";
const TopNavBar = ({ dispatch, PointageManquants }) => {
  React.useEffect(() => {
    OnlineHandler.isOfflineListener(() => dispatch(offline()));
    OnlineHandler.isOnlineListener(async () =>
      dispatch(online(await OnlineHandler.isServerReachable()))
    );
    dispatch(GetLastDateSynchro());
    // eslint-disable-next-line
  }, []);
  return (
    <nav className="navbar" id="navTopBar">
      <TopNav />
      <div className="subBar">
        <Route path="/" component={Ariane} />
        <Route path="/pointage/:type" component={ActionTopPointage} />
      </div>
      {PointageManquants && (
        <div className="subBar subBar--error">
          <p>Attention : vous ne voyez que les pointages manquants.</p>{" "}
          <button
            className="btn"
            onClick={() => dispatch(setShowFilterPointage(true))}
          >
            Modifier les filtres
          </button>
        </div>
      )}
    </nav>
  );
};

export default connect(({ Pointage }) => ({
  PointageManquants:
    Pointage.momentID !== -1 &&
    Pointage.filter.Special[Pointage.momentID].filter.includes(3),
}))(TopNavBar);
