import { TokenHandler, GetBuses } from "../helper";

export const getBusesTable = (db) => db.getDB().table(db.getTableName().Buses);

export const cleanBuses = (db) => db.clearTable(db.getTableName().Buses);

export const TableBusesFiller = (db, dispatch, StepDone) => {
  return GetBuses(TokenHandler.getToken()).then((client) =>
    client
      .buses__index()
      .then((res) => res.body.data)
      .then((data) => {
        return db.insertLotsOfData(data, db.getTableName().Buses).then(() => {
          dispatch(
            StepDone({
              key: "Bus",
              action: "Récupération des trajets de bus",
              end: true,
              size: data.length,
            })
          );
        });
      })
  );
};

export const getAllBuses = (db) => getBusesTable(db).toCollection().toArray();

export const BusesHandler = {
  getBusesTable,
  cleanBuses,
  TableBusesFiller,
  getAllBuses,
};
