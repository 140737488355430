import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { setIdDate, setPrestationsId } from "../../actions/Database/Pointage";
import { getPrestaLabel, SessionHandler } from "../../helper";
import {
  checkIfDateIsInAllowed,
  dateIsGreeterThan,
  dateStringToDate,
  formatDate,
  incrementDate,
} from "../../helper/date";
import { useLocationId } from "../../helper/useLocationId";

const PointageMiddle = ({
  dispatch,
  Prestations,
  checkDate,
  after,
  before,
}) => {
  const locationPath = useLocationId()[1];
  const history = useHistory();
  useEffect(() => {
    var histo = history.listen(() => {
      if (history.action === "POP") {
        dispatch(setPrestationsId({ location: locationPath, init: true }));
      }
    });
    return () => histo();
    // eslint-disable-next-line
  }, []);
  var presta = Object.values(SessionHandler.prestationsParser()).find(
    (prestations) => prestations.url === locationPath
  );
  return (
    <div>
      <h1>{presta.Label}</h1>
      <table className="table">
        <thead className="table__head">
          <tr>
            <th>Nom</th>
            <th>Date</th>
            <th className="is-hidden show-medium">Nombre d'inscrits</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {Prestations.filter(
              ({ start_date, end_date, secondary }) =>
              (checkDate(start_date) ||
              checkDate(end_date) ||
              dateIsGreeterThan(end_date, before) ||
              dateIsGreeterThan(start_date, after)) &&
              !secondary
            ).map((prestations) => (
            <tr key={prestations.id}>
              <td>{getPrestaLabel(prestations, undefined, true)}</td>
              <td>
                {prestations.start_date !== null &&
                  `du ${dateStringToDate(
                    prestations.start_date
                  ).toLocaleDateString()}`}
                {(
                  (
                    prestations.type !== 10 &&
                    prestations.start_date !== prestations.end_date
                  ) ||
                    prestations.type === 10
                 ) &&
                  prestations.end_date !== null &&
                  ` au ${dateStringToDate(
                    prestations.end_date
                  ).toLocaleDateString()}`}
              </td>
              <td className="is-hidden show-medium">
                {prestations.member || 0}
              </td>
              <td>
                <Link
                  className="btn btn--secondary"
                  onClick={() => dispatch(setIdDate())}
                  to={`/pointage/${locationPath}/${prestations.id}`}
                >
                  Pointage
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default connect(({ Pointage, Auth, Synchro }) => ({
  Prestations: Pointage.Prestations,
  checkDate: checkIfDateIsInAllowed(Auth, Synchro),
  after: new Date(
    formatDate(
      incrementDate(
        Synchro.lastSync ? new Date(Synchro.lastSync) : new Date(),
        0 - Auth.SessionData.RootPeriodBefore
      )
    )
  ),
  before: new Date(
    formatDate(
      incrementDate(
        Synchro.lastSync ? new Date(Synchro.lastSync) : new Date(),
        0 + Auth.SessionData.RootPeriodAfter
      )
    )
  ),
}))(PointageMiddle);
