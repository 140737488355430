import { TokenHandler, GetReservations, GetPresences } from "../helper";

/**
 * * Function that return the reservations table
 * @param {DexieDb} db
 */
export const getReservationsTable = (db) =>
  db.getDB().table(db.getTableName().Reservations);
export const getReservationsCollection = (db) =>
  db.getDB().table(db.getTableName().Reservations).toCollection();

/**
 *  * Function that clear the Reservations table
 * @param {DexieDb} db
 */
export const clearReservations = (db) =>
  db.clearTable(db.getTableName().Reservations);

/**
 * * Permet la récupération des reservation par leur jours,type et id de prestation
 * * Le filtre par type en premier permet de grandement réduire le temps d'exécution de la query
 * @param {DexieDb} db
 * @param {Array<Integer>} prestationId
 * @param {Array<Integer>} prestationType
 * @param {Date} date
 * @returns {Array<Presta>}
 */
export const getReservationByDayTypeAndPrestationId = (
  db,
  prestationId,
  prestationType,
  date
) => {
  /**
   * On ajoute les prestation_type 7 pour les prestations "journée spéciale"
   */
  return getReservationsTable(db)
    .where("prestation_type")
    .anyOf([...prestationType, 7])
    .and(
      (resa) =>
      {
        if (!prestationId.includes(resa.prestation_id)) return false;
        if (resa.prestation_date !== date) return false;
        if(resa.prestation_type === 7 && !prestationType.includes(resa.service_type)) return false;
        return true;
      }
    )
    .toArray()};
export const getReservationPrestationsId = (db, prestationId) =>
  getReservationsTable(db).where("prestation_id").anyOf(prestationId).toArray();

export const getAllResaFromAPI = () =>
  GetReservations(TokenHandler.getToken()).then((client) =>
    client
      .App_Center_API_Controllers_ReservationsController__index()
      .then((res) => res.body.data)
  );

export const getAllTempPresenceFromApi = () =>
  GetPresences(TokenHandler.getToken()).then((client) =>
    client
      .App_Center_API_Controllers_PresencesController__index()
      .then((res) => res.body.data)
  );

/**
 * * Function that fill the database
 * @param {DexieDb} db
 * @param {Redux Dispatch} dispatch
 * @param {Redux Action} StepDone
 */
export const tableReservationsFiller = (db, dispatch, StepDone) => {
  var allPromise = [getAllTempPresenceFromApi, getAllResaFromAPI].map((call) =>
    call()
  );
  return Promise.all(allPromise).then(([tempPresta, Resa]) => {
    return new Promise((resolve, reject) => {
      var allResa = [];
      Resa.forEach((element, Index) => {
        var presence = tempPresta.find(
          (temp) =>
            temp.child_id === element.child_id &&
            temp.prestation_moment === element.prestation_moment &&
            temp.prestation_date === element.prestation_date &&
            temp.reservation_id === element.id
        );
        allResa.push({
          ...element,
          is_present: element.is_present
            ? 1
            : element.is_present === false
            ? 0
            : null,
          arrived_at: presence ? presence.arrived_at : undefined,
          departed_at: presence ? presence.departed_at : undefined,
        });
      });
      db.insertLotsOfData(allResa, db.getTableName().Reservations).then(() => {
        dispatch(
          StepDone({
            key: "presence",
            action: "Récupération des horaires de presence",
            end: true,
            size: tempPresta.length,
          })
        );
        dispatch(
          StepDone({
            key: "Reservations",
            action: "Récupération des réservations",
            end: true,
            size: Resa.length,
          })
        );
        resolve();
      });
    });
  });
};

export const delResaNotPushed = (db, id) => {
  getReservationsTable(db).delete(id);
};
export const delResaPushed = (db, data) => {
  db.insertOrUpdate(
    data.id,
    { ...data, is_modified: 1, is_deleted: 1 },
    db.getTableName().Reservations
  );
};

export const UpdateResa = (db, data) =>
  db.insertOrUpdate(
    data.id,
    { ...data, is_modified: 1 },
    db.getTableName().Reservations
  );

export const insertReservation = (
  db,
  {
    childId,
    child_signup_id,
    prestation_id,
    prestation_type,
    date,
    moment,
    date_id,
    ...data
  }
) => {
  return getReservationsCollection(db)
    .last()
    .then((resa) => ({
      id: (resa?.id || 0) + 1,
      is_add: 1,
      is_present: 1,
      is_unexpected: 1,
      is_modified: 1,
      child_id: childId,
      child_signup_id: child_signup_id,
      prestation_id: prestation_id,
      prestation_type: prestation_type,
      prestation_date: date,
      prestation_moment: moment,
      is_forfait: false,
      date_id: date_id,
      ...data,
    }))
    .then((resa) => {
      db.insertOrUpdate(resa.id, resa, db.getTableName().Reservations);
      return resa;
    });
};

export const deleteResa = (needToBeDeleted, id) =>
  GetReservations(TokenHandler.getToken()).then((client) =>
    client.App_Center_API_Controllers_ReservationsController__removeUnexpectedPresences(
      {},
      {
        requestBody: {
          sync_id: id,
          reservation_id: needToBeDeleted,
        },
      }
    )
  );

export const addResa = (needToBeAdded, id) =>
  GetReservations(TokenHandler.getToken()).then((client) =>
    client.App_Center_API_Controllers_ReservationsController__addUnexpectedPresences(
      {},
      {
        requestBody: {
          sync_id: id,
          reservations: needToBeAdded,
        },
      }
    )
  );
export const presenceChange = (changePresence, id) =>
  GetReservations(TokenHandler.getToken()).then((client) =>
    client.App_Center_API_Controllers_ReservationsController__updatePresences(
      {},
      {
        requestBody: {
          sync_id: id,
          reservations: changePresence,
        },
      }
    )
  );
export const changeTempPresence = (tempPresenceChange, id) =>
  GetPresences(TokenHandler.getToken()).then((client) =>
    client.App_Center_API_Controllers_PresencesController__update(
      {},
      {
        requestBody: {
          sync_id: id,
          presences: tempPresenceChange,
        },
      }
    )
  );
export const prestaChange = (prestaChange, id) =>
  GetReservations(TokenHandler.getToken()).then((client) =>
    client.App_Center_API_Controllers_ReservationsController__changePrestations(
      {},
      {
        requestBody: {
          sync_id: id,
          reservations: prestaChange,
        },
      }
    )
  );

export const getCountChildAdded = (db) => {
  return getReservationsTable(db)
    .where("is_add")
    .equals(1)
    .toArray()
    .then((value) => value.length);
};

export const getCountPresenceModified = (db) => {
  return getReservationsCollection(db)
    .filter(
      (resa) =>
        Boolean(resa.is_add) ||
        Boolean(resa.is_deleted) ||
        Boolean(resa.is_modified)
    )
    .toArray()
    .then((value) => value.length);
};

export const getCountChildModified = (db) =>
  getReservationsTable(db)
    .where("is_modified")
    .equals(1)
    .toArray()
    .then((value) => value.length);

export const ReservationHandler = {
  ClearReservations: clearReservations,
  TableReservationsFiller: tableReservationsFiller,
  getReservationByDayTypeAndPrestationId,
  insertReservation,
  getReservationsTable,
  delResaNotPushed,
  delResaPushed,
  UpdateResa,
  getCountChildAdded,
  getCountChildModified,
  deleteResa,
  addResa,
  changeTempPresence,
  presenceChange,
  getCountPresenceModified,
  prestaChange,
  getReservationPrestationsId,
};
