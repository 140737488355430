import { dateIsBetweenOrEqualDate, dateStringToDate } from "./date";
import { getChildAge } from "./dbChild";

export const tri = {
  AgeCroissant: {
    Label: "Age Croissant",
    call: () => (childA, childB) =>
      getChildAge(childA.birth_date) - getChildAge(childB.birth_date),
  },
  AgeDecroissant: {
    Label: "Age Décroissant",
    call: () => (childA, childB) =>
      getChildAge(childB.birth_date) - getChildAge(childA.birth_date),
  },
  NiveauxCroissant: {
    Label: "Niveaux Croissant",
    call: () => (childA, childB) =>
      childA.signups[0].level - childB.signups[0].level,
  },
  NiveauxDecroissant: {
    Label: "Niveaux Décroissant",
    call: () => (childA, childB) =>
      childB.signups[0].level - childA.signups[0].level,
  },
  Nom: {
    Label: "Nom",
    call: () => (childA, childB) =>
      childA.last_name.toLowerCase() > childB.last_name.toLowerCase()
        ? 1
        : childA.last_name.toLowerCase() < childB.last_name.toLowerCase()
        ? -1
        : 0,
  },
  Prenom: {
    Label: "Prénom",
    call: () => (childA, childB) =>
      childA.first_name.toLowerCase() > childB.first_name.toLowerCase()
        ? 1
        : childA.first_name.toLowerCase() < childB.first_name.toLowerCase()
        ? -1
        : 0,
  },
  Classes: {
    Label: "Enseignant",
    call: ({ Signup, date }) => {
      return (
        childA,
        childB //childA.signups[0].teacher_id
      ) => {
        var signup = Signup.find((signupG) =>
          dateIsBetweenOrEqualDate(
            new Date(date),
            dateStringToDate(signupG.start_date),
            dateStringToDate(signupG.end_date)
          )
        );
        return signup.teachers
          .find((teacher) => teacher.id === childA.signups[0].teacher_id)
          ?.name?.toLowerCase() >
          signup.teachers
            .find((teacher) => teacher.id === childB.signups[0].teacher_id)
            ?.name?.toLowerCase()
          ? 1
          : signup.teachers
              .find((teacher) => teacher.id === childA.signups[0].teacher_id)
              ?.name?.toLowerCase() <
            signup.teachers
              .find((teacher) => teacher.id === childB.signups[0].teacher_id)
              ?.name?.toLowerCase()
          ? -1
          : 0;
      };
    },
  },
  Groupe: {
    Label: "Groupe",
    call: ({ prestationsType }) => {
      return (childA, childB) => {
        var childAValue =
          childA.signups[0].affectations.find((value) =>
            prestationsType.includes(value.type)
          )?.group_id || 0;
        var childBValue =
          childB.signups[0].affectations.find((value) =>
            prestationsType.includes(value.type)
          )?.group_id || 0;
        return childAValue > childBValue
          ? 1
          : childAValue > childBValue
          ? -1
          : 0;
      };
    },
  },
};
