const sworker = "sworker";

export const getDontWantInstall = () =>
  localStorage.getItem(`${sworker}DontWant`);
export const setDontWantInstall = (value) =>
  localStorage.setItem(`${sworker}DontWant`, value);

export const isInstalled = () => {
  // For iOS
  if (window.navigator.standalone) return true;

  // For Android and windows ?
  if (window.matchMedia("(display-mode: standalone)").matches) return true;

  // If neither is true, it's not installed
  return false;
};
