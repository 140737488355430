import React from "react";
import { connect } from "react-redux";
import Modal from "./Modal";

const ErrorSyncModal = ({ ErrorSync, hideError, SessionData }) => {
  return (
    <Modal
      show={ErrorSync.show}
      hide={hideError}
      label="Erreur de synchronisation"
      modalHeaderClassName="modal-header--error"
    >
      <div className="color--error">
        <strong>Une erreur lors de la synchronisation a eu lieu :</strong>
      </div>
      <div className="error__content">
        {Array.isArray(ErrorSync.why) &&
          ErrorSync.why.map(({ resaId, message }, index) => (
            <p key={index}>
              <span className="tag tag--error tag--small">Reservation {resaId}</span> <code>{message}</code>
            </p>
          ))}
        {!Array.isArray(ErrorSync.why) && (
          <p>
            Erreur : <code>{ErrorSync.why}</code>
          </p>
        )}
      </div>
      <div>
        <span className="tag tag--primary tag--small">{SessionData.NomCentre}</span>&nbsp;
        <span className="tag tag--primary tag--small">utilisateur {ErrorSync.userID}</span>&nbsp;
        <span className="tag tag--primary tag--small">version {process.env.REACT_APP_VERSION}</span>
      </div>
      <br></br>
      <p><strong>Afin de résoudre cette erreur deux choses sont à verifier :</strong></p>
      <ul>
        <li>
          Êtes-vous bien connecté à internet et n'avez-vous pas eu de problème
          de connexion au cours de la synchronisation ?
        </li>
        <li>Existe-t-il une mise à jour de votre navigateur ?</li>
      </ul>
      <br></br>
      <p><strong>Dans les deux cas veuillez effectuer les actions suivantes :</strong></p>
      <ul>
        <li>Tenter à nouveau une synchronisation.</li>
        <li>En cas d'échec veuillez contacter
          votre administrateur en lui communiquant les informations ci-dessus.
        </li>
      </ul>
    </Modal>
  );
};

export default connect(({ ShowModal, Auth }) => ({
  ErrorSync: ShowModal.ErrorSync,
  SessionData: Auth.SessionData,
}))(
  React.memo(ErrorSyncModal, (oldProps, newProps) => {
    if (
      oldProps.ErrorSync.show !== newProps.ErrorSync.show ||
      newProps.ErrorSync.show
    )
      return false;
    return true;
  })
);
