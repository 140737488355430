import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { updateHorairePresence } from "../../../../actions/Database/Resa";
import { formatTime, dateToHourString } from "../../../../helper/date";
import TimePickerModal from "./TimePickerModal";

const parseTime = (time) =>
  (!(time === undefined || time === null || time === "") && formatTime(time)) ||
  dateToHourString(new Date());

const TimePicker = ({
  inputTime,
  resaId,
  isArrivedAt,
  otherValue,
  action = undefined,
  dispatch,
  addClass = "",
}) => {
  const [show, setShow] = useState(false);
  const [time, setTime] = useState(parseTime(inputTime));
  const handlerEvent = (event) => {
    event.preventDefault();
    if (event.keyCode === 13 || event.keyCode === 32) {
      setShow(true);
    }
  };
  const handleFocus = (e) => {
    e.currentTarget.addEventListener("keydown", handlerEvent);
    e.currentTarget.addEventListener("blur", (event) => {
      event.currentTarget.removeEventListener("keydown", handlerEvent);
    });
  };

  useEffect(() => {
    setTime(parseTime(inputTime));
  }, [inputTime]);

  const onClick = (formateTime) => {
    setShow(false);
    if (action === undefined) {
      dispatch(
        updateHorairePresence({
          resaId,
          arrivedAt: isArrivedAt ? formateTime : otherValue,
          departedAt: isArrivedAt ? otherValue : formateTime,
        })
      );
    } else {
      action(formateTime);
    }
  };
  const addListener = () => {
    setShow(true);
    setTime(parseTime(inputTime));
  };

  return (
    <>
      <label
        className="form__label"
        htmlFor={`${resaId}${isArrivedAt ? "Arrived" : "Departed"}`}
      >
        {isArrivedAt ? "Arrivée à" : "Départ à"}
      </label>
      <input
        id={`${resaId}${isArrivedAt ? "Arrived" : "Departed"}`}
        onFocus={handleFocus}
        type="time"
        value={
          (!(
            inputTime === undefined ||
            inputTime === null ||
            inputTime === ""
          ) &&
            formatTime(inputTime)) ||
          ""
        }
        readOnly={true}
        className={addClass ? addClass : undefined}
        onClick={addListener}
      />
      <TimePickerModal
        coming={isArrivedAt}
        time={time}
        show={show}
        hide={() => {
          setShow(false);
        }}
        onClick={onClick}
      />
    </>
  );
};
export default connect()(TimePicker);
