import React from "react";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import {
  actionGlobal,
  filterResaForWorkGlobal,
} from "../../../actions/Database/Resa";
import TimePicker from "../childContent/resaPointage/TimePicker";
import MotifPicker from "../childContent/resaPointage/MotifPicker";

const ModalActionGlobal = ({
  PointageGlobalAction,
  dispatch,
  hideAction,
  Child,
  canWork,
  Auth,
  Pointage,
}) => {
  const [arrivedAt, setArrivedAt] = React.useState("");
  const [departedAt, setDepartedAt] = React.useState("");
  const [motifAbs, setMotifAbs] = React.useState('none');
  const [modifAuto, setModifAuto] = React.useState(false);

  if (!canWork) return <div></div>;
  // var resas = Pointage.Reservation.filter(
  //   filterResaForWorkGlobal(PointageGlobalAction, Child, Pointage.Prestations)
  // );
  var affectedResa = Pointage.Reservation.filter(
    filterResaForWorkGlobal(PointageGlobalAction, Child, Pointage.Prestations)
  );

  var prestaAffected = [];
  if (PointageGlobalAction.allPresent) {
    affectedResa.forEach(resa => {
      if (!prestaAffected.some(presta => presta.id === resa.prestation_id)) {
        prestaAffected.push(Pointage.Prestations.find(prestation => prestation.id === resa.prestation_id))
      }
    })
  }
  return (
    <Modal
      show={canWork}
      onHide={hideAction}
      animation={false}
      dialogClassName="ChildModalContainer"
    >
      <Modal.Header closeButton className="ChildModalHeader">
        Actions groupées sur les pointages
      </Modal.Header>
      <Modal.Body className="ChildModalBody">

        {(affectedResa.length > 0 && (
          <>
            <p className="withMargin">
              <strong>Êtes-vous sûr de vouloir effectuer cette action ?</strong>
            </p>

            <div>
              <span className="tag tag--primary">
                {affectedResa.length} enfant{affectedResa.length > 1 && "s"}{" "}
                {affectedResa.length > 1 ? "seront" : "sera"}{" "}
                impacté{affectedResa.length > 1 && "s"}.
              </span>
            </div>

            <p>
              Attention cette action n'impactera que les enfants <strong>non pointés</strong> avec une réservation et
              correspondant à vos filtres actifs
              {PointageGlobalAction.allPresent === true && "."}{" "}
              {PointageGlobalAction.allPresent === false &&
                `et dans le cas ${
                  PointageGlobalAction.allAbsent
                    ? "d'une absence"
                    : "d'un non pointé"
                } supprimera les réservations des enfants non prévus.`}
            </p>
            {PointageGlobalAction.allPresent === true &&
              prestaAffected.length > 0 &&
              prestaAffected.some(presta => presta.autofill_hours === false) && (
              <>
              <div className="modal__timepicker">
                <div className="form__grp">
                  <TimePicker
                    isArrivedAt={true}
                    inputTime={arrivedAt}
                    action={(parsedTime) => setArrivedAt(parsedTime)}
                  />
                </div>
                <div className="form__grp">
                  <TimePicker
                    inputTime={departedAt}
                    action={(parsedTime) => setDepartedAt(parsedTime)}
                  />
                </div>
              </div>
              {Auth.SessionData.RootRecalculPresta !== 4 &&
              ![6, 8, 10].some((value) => Pointage.prestationsType.includes(value)) &&
              <div className="form__grp form__grp--inline form__grp--mt1">
                <input
                  type="checkbox"
                  id="modifPrestaHoraire"
                  onChange={() => setModifAuto(!modifAuto)}
                  checked={modifAuto}
                />
                <label htmlFor="modifPrestaHoraire" className="form__label--fw400">
                  Modifier automatiquement les prestations si elles ne couvrent pas la plage horaire pointée
                  {Auth.SessionData.RootRecalculPresta === 1 ? " à la hausse" :
                  Auth.SessionData.RootRecalculPresta === 2 ? " à la baisse" :
                  " à la hausse et à la baisse" }
                </label>
              </div>
              }
              </>
            )}
            {PointageGlobalAction.allAbsent === true && (
              <div>
                <div className="form__grp">
                  <MotifPicker
                    inputMotif={motifAbs}
                    action={(newmotif) => setMotifAbs(newmotif)}
                    allResa={true}/>
                </div>
              </div>
            )}
          </>
        ))}

        {affectedResa === 0 && (
          <p>
            <strong>
              {(PointageGlobalAction.allPresent === false && PointageGlobalAction.allAbsent === false) ?
                "Il n'y a aucun pointage pour les enfants correspondants à vos filtres actifs." // action de suppression
              : "Il ne reste aucun pointage à faire pour les enfants correspondants à vos filtres actifs." // absent / présent
              }
            </strong>
          </p>
        )}

        <div className="form__btns">
          <button
            className="btn btn--secondary loginButton"
            disabled={affectedResa <= 0 || Pointage.Pending}
            onClick={() => {
              hideAction();
              setArrivedAt(undefined);
              setDepartedAt(undefined);
              dispatch(
                actionGlobal({
                  action: PointageGlobalAction,
                  arrivedAt,
                  departedAt,
                  motifAbs,
                  modifAuto,
                  numRecalcul: Auth.SessionData.RootRecalculPresta,
                })
              );
            }}
          >
            Confirmer
          </button>

          <button className="btn btn--light btn--link loginButton" onClick={hideAction}>
            Annuler
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default connect(({ Child, Pointage, ShowModal, Auth }) => ({
  Child: Child.filteredChild,
  PointageGlobalAction: ShowModal.PointageGlobalAction,
  canWork: Object.values(ShowModal.PointageGlobalAction).reduce(
    (state, value) => state || value
  ),
  Auth: Auth,
  Pointage,
}))(React.memo(ModalActionGlobal), (oldProps, newProps) => {
  if (oldProps.canWork !== newProps.canWork || newProps.canWork) return false;
  return true;
});
