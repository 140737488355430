import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setShowAllChild, setTriChildren } from "../../actions/Database/Child";
import { setFilter } from "../../actions/Database/Pointage";
import { setShowFilterPointage, setShowStats } from "../../actions/showModal";
import { tri } from "../../helper/orderChild";
import { useLocationId } from "../../helper/useLocationId";

export const ActionBottomPointage = ({ Child, Pointage, dispatch }) => {
  const [locationId] = useLocationId();
  const [checkAllChild, setCheckAllChild] = useState(false);

  useEffect(() => {
    if (Child.showAllChild && [10, 6].some((id) => locationId.includes(id)))
      dispatch(setShowAllChild({checked: false}));
  }, [locationId, Child.showAllChild, dispatch]);
  var nbrReservationPrimaire = Pointage.Reservation.filter(
    (value) =>
      (!Pointage.Prestations.find((presta) => presta.id === value.prestation_id)
        ?.secondary ||
        false) &&
      Child.filteredChild.findIndex((child) => child.id === value.child_id) !==
        -1
  ).length;

  useEffect(() => {
    if (  Pointage.filter.Special[Pointage.momentID] &&
          Pointage.filter.Special[Pointage.momentID].enabled &&
          Pointage.filter.Special[Pointage.momentID].filter.includes(1)) {
      if (!Child.showAllChild) {
        dispatch(setFilter({ categorie: "Special", checked: false, id: 1 }));
      }
    }
    setCheckAllChild(Child.showAllChild);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Child.showAllChild, Pointage]);

  return (
    <nav className="nav__filters" id="navBottomPointage">
      <div className="nav__filters__wrapper">
        <div className="nav__filters__btns">
          <div
            className="btn btn--secondary cursor-click"
            onClick={() => dispatch(setShowFilterPointage(true))}
          >
            {Pointage.momentID !== -1 &&
              !Pointage.filter.active[Pointage.momentID] &&
              "Aucun filtre actif"}
            {Pointage.momentID !== -1 &&
              Pointage.filter.active[Pointage.momentID] &&
              (Pointage.nbrFiltreActif === 1
                ? `1 filtre actif`
                : `${Pointage.nbrFiltreActif} filtres actifs`)}
            {Pointage.momentID === -1 && "Filtre"}
          </div>
          <div
            className="btn btn--secondary cursor-click"
            onClick={() => dispatch(setShowStats(true))}
          >
            Stats
          </div>
        </div>
        <div className="wrapperTopBarNav">
          <p>
            {Child.filteredChild.length} enfant
            {Child.filteredChild.length > 1 && "s"}
            {Child.showAllChild &&
              Child.filteredChild.length !== 0 &&
              Pointage.Reservation.length > 0 &&
              ` dont ${nbrReservationPrimaire} réservation${
                (nbrReservationPrimaire > 1 && "s") || "" || ""
              }`}
            {Pointage.momentID !== -1 &&
              Pointage.filter.active[Pointage.momentID] &&
              ` (sans filtre : ${Child.nbrChild} enfant${
                (Child.nbrChild > 1 && "s") || "" || ""
              })`}
          </p>
        </div>
        {![10, 6].some((id) => locationId.includes(id)) && (
          <div className="wrapperTopBarNav form__grp form__grp--inline">
            <input
              type="checkbox"
              id="seeAllChilds"
              checked={checkAllChild}
              onChange={(e) => {
                setCheckAllChild(!checkAllChild);
                dispatch(setShowAllChild({ checked: !checkAllChild }))
              }
              }
            />
            <label htmlFor="seeAllChilds">
              Afficher les enfants sans réservation
            </label>
          </div>
        )}

        <select
          className="wrapperTopBarNav"
          onChange={(e) => dispatch(setTriChildren(e.target.value))}
          value={Pointage.tri}
        >
          {Object.entries(tri).map(([key, el]) => (
            <option value={key} key={key}>
              Trier par {el.Label}
            </option>
          ))}
        </select>
      </div>
    </nav>
  );
};

const compareProps = (prevProps, nextProps) => {
  if (
    prevProps.Pointage.Reservation.length !==
      nextProps.Pointage.Reservation.length ||
    prevProps.Child.filteredChild.length !==
      nextProps.Child.filteredChild.length ||
    prevProps.Child.showAllChild !== nextProps.Child.showAllChild ||
    prevProps.Pointage.filter.Active !== nextProps.Pointage.filter.Active ||
    prevProps.Pointage.nbrFiltreActif !== nextProps.Pointage.nbrFiltreActif
  ) {
    return false;
  }
  return true;
};

export default connect(({ Pointage, Child }) => ({
  Pointage: {
    tri: Pointage.tri,
    momentID: Pointage.momentID,
    filter: Pointage.filter,
    Reservation: Pointage.Reservation,
    Prestations: Pointage.Prestations,
    nbrFiltreActif: Object.values(Pointage.filter).reduce((nbrValue, value) => {
      if (value[Pointage.momentID]?.enabled) {
        return nbrValue + 1;
      }
      return nbrValue;
    }, 0),
  },
  Child: {
    filteredChild: Child.filteredChild,
    showAllChild: Child.showAllChild,
    nbrChild: Child.nbrChild,
  },
}))(React.memo(ActionBottomPointage, compareProps));
