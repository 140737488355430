import React from "react";
import { connect } from "react-redux";
import { addReservation } from "../../../actions/Database/Resa";

const NoResa = ({ child, dispatch }) => {
  return (
    <>
      <button
        className="btn btn--secondary child__newresa"
        onClick={() =>
          dispatch(
            addReservation({
              childId: child,
            })
          )
        }
      >
        Ajouter une prestation
      </button>
    </>
  );
};

export default connect()(NoResa);
