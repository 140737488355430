import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getStat, GeneraleSync, setSyncDuration } from "../../actions/Database/Synchro";
import Modal from "./Modal";

const ModalSync = ({ Synchro, ShowModal, hideSynchro, dispatch }) => {
  useEffect(() => {
    if (ShowModal.synchro === true) dispatch(getStat());
    // eslint-disable-next-line
  }, [ShowModal.synchro]);
  if (Synchro.Pending === true) return <div></div>;
  if (Synchro.stats === undefined) return <div></div>;
  return (
    <Modal show={ShowModal.synchro} hide={hideSynchro} label="Synchronisation">
      {!ShowModal.online && (
        <p className="color--error">
          Vous ne pouvez pas synchroniser car vous n'êtes pas connecté.
        </p>
      )}
      {ShowModal.online && (
        <p
          className={
            ShowModal.ServerAvailable
              ? "color--primary withMargin"
              : "color--error withMargin"
          }
        >
          <strong>
            {ShowModal.ServerAvailable
              ? "Synchronisation disponible"
              : "Synchronisation non disponible, le serveur n'est pas accessible"}
          </strong>
        </p>
      )}

      <div>
        <span className="counter">
          {Synchro.stats.nbrPointe ? Synchro.stats.nbrPointe : 0}
        </span>{" "}
        pointage{Synchro.stats.nbrPointe > 1 && "s"} impacté
        {Synchro.stats.nbrPointe > 1 && "s"}
        <span className="small">
          {" "}
          dont {Synchro.stats.nbrAjoute} ajout
          {Synchro.stats.nbrAjoute > 1 && "s"}
        </span>
      </div>
      <div>
        <span className="counter">
          {Synchro.stats.nbrPhoto ? Synchro.stats.nbrPhoto : 0}
        </span>{" "}
        photo{Synchro.stats.nbrPhoto > 1 && "s"} modifiée
        {Synchro.stats.nbrPhoto > 1 && "s"}
      </div>
      <div className="form__btns">
        <button
          className="btn btn--secondary loginButton"
          onClick={() => {
            const startTime = Date.now();
            dispatch(
              GeneraleSync({
                WipeDatabase: true,
              })
            ).then(() => {
              dispatch(setSyncDuration(Date.now() - startTime));
            });
          }}
          disabled={!ShowModal.online || !ShowModal.ServerAvailable}
        >
          Synchroniser les données
        </button>

        <button
          className="btn btn--light btn--link loginButton"
          onClick={hideSynchro}
        >
          Annuler
        </button>
      </div>
    </Modal>
  );
};

export default connect(({ ShowModal, Synchro }) => ({
  ShowModal: {
    online: ShowModal.online,
    ServerAvailable: ShowModal.ServerAvailable,
    synchro: ShowModal.synchro,
  },
  Synchro: {
    stats: Synchro.stats,
    Pending: Synchro.Pending,
  },
}))(
  React.memo(ModalSync, (oldProps, newProps) => {
    if (
      oldProps.ShowModal.synchro !== newProps.ShowModal.synchro ||
      newProps.ShowModal.synchro ||
      oldProps.ShowModal.online !== newProps.ShowModal.online
    )
      return false;
    return true;
  })
);
