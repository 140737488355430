import React from "react";
import { connect } from "react-redux";
import { Toast } from "react-bootstrap";
import { setShowNeedUpdate, update } from "../../actions/serviceWorker";
const NeedUpdate = ({ showNeedUpdate, dispatch }) => {
  return (
    <Toast
      show={showNeedUpdate || false}
      onClose={() => dispatch(setShowNeedUpdate(false))}
      animation={false}
    >
      <Toast.Header closeButton={false}>
        <h2>
          Mise à jour de l'application
        </h2>
      </Toast.Header>
      <Toast.Body>
        <p><br />
          Une mise à jour de l'application {String(process.env.REACT_APP_LABEL)}{" "}
          est disponible.
        </p>
        <div className="form__btns">
          <button className="btn btn--secondary" onClick={() => dispatch(update())}>Faire la mise à jour</button>
          <button className="btn btn--error btn--link" onClick={() => dispatch(setShowNeedUpdate(false))}>Plus tard</button>
        </div>
      </Toast.Body>
    </Toast>
  );
};

export default connect((state) => state.serviceWorker)(NeedUpdate);
