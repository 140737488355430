import { cryptData, decryptData } from "./crypt";
export const TOKENKEY = "LocalAccessToken";
export const CRYPTEDTOKENKEY = "CryptedToken";
export const setToken = (token) => sessionStorage.setItem(TOKENKEY, token);
export const StoreDecryptedToken = (pin) => setToken(getCryptedToken(pin).data);
export const getToken = () => sessionStorage.getItem(TOKENKEY);
export const ResetToken = () => sessionStorage.setItem(TOKENKEY, "");
export const setCryptedToken = (token, pin) =>
  localStorage.setItem(CRYPTEDTOKENKEY, cryptData(token, pin));
export const getCryptedToken = (pin) => {
  var decrypt = decryptData(localStorage.getItem(CRYPTEDTOKENKEY), pin);
  return { data: decrypt, PinCorrect: decrypt !== "" };
};
export const ResetCryptedToken = () =>
  localStorage.setItem(CRYPTEDTOKENKEY, "");
export const hasCryptedToken = () =>
  localStorage.getItem(CRYPTEDTOKENKEY) !== null &&
  localStorage.getItem(CRYPTEDTOKENKEY) !== "";
export const hasDecryptedToken = () =>
  sessionStorage.getItem(TOKENKEY) !== null &&
  sessionStorage.getItem(TOKENKEY) !== "";
export var TokenHandler = {
  setToken,
  getToken,
  ResetToken,
  setCryptedToken,
  getCryptedToken,
  ResetCryptedToken,
  hasCryptedToken,
  hasDecryptedToken,
  StoreDecryptedToken,
};
