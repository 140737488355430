import React, { useEffect, useState } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

const ImageResize = ({handleChangePicture, urlFile, hideCrop}) => {
  const [stateCrop, setStateCrop] = useState({
    src: null,
    crop: {
      unit: 'px',
      width: 300,
      aspect: 1,
    },
    croppedImageUrl: urlFile,
  });
  const [imageRef, setImageRef] = useState(null);

  useEffect(() => {
    // Au rechargement de l'image, on détecte le changement et on créer un nouveau "file"
    onFileChange(urlFile);
  }, [urlFile]);

  useEffect(() => {
    if (imageRef) {
      // Centrage du crop
      let x = 0;
      let y = 0;
      // Si la largeur est inférieur à 300 et est plus grande que la hauteur, on centre le crop sur la moitié de l'image en fonction de la hauteur de l'image
      if (imageRef.naturalHeight > 300 || imageRef.naturalWidth > 300) {
        // On trouve le plus grand et on prend sa motiié pour l'autre
        if (imageRef.height > imageRef.width) {
          // On centre le crop sur la moitié de l'image en fonction de la largeur et on laisse l'autre à 0
          y = ( imageRef.height - imageRef.width) / 2;
        } else {
          // On centre le crop sur la moitié de l'image en fonction de la hauteur et on laisse l'autre à 0
          x = (imageRef.width -  imageRef.height) / 2;
        }
      } else {
        // On trouve le plus grand et on prend la moitié comme dit plus haut
        if (imageRef.naturalHeight > imageRef.naturalWidth) {
          // On centre le crop sur la moitié de l'image en fonction de la largeur et on laisse l'autre à 0
          y = (imageRef.naturalHeight - imageRef.naturalWidth) / 2;
        } else {
          // On centre le crop sur la moitié de l'image en fonction de la hauteur et on laisse l'autre à 0
          x = (imageRef.naturalWidth - imageRef.naturalHeight) / 2;
        }
      }
      setStateCrop(prevState => ({
        ...prevState,
        crop: {
          ...prevState.crop,
          x: x,
          y: y,
        }
      }))
    }
  }, [imageRef])

  useEffect(() => {
    const makeClientCrop = async (crop) => {
      if (imageRef && crop.width && crop.height) {
        const croppedImageUrl = await getCroppedImg(
          imageRef,
          crop,
          'newFile.jpeg'
        );
        setStateCrop(prevState => ({
          ...prevState,
          croppedImageUrl: croppedImageUrl }));
      }
    }
    makeClientCrop(stateCrop.crop);
  }, [stateCrop.crop, imageRef])

  const onFileChange = async (urlFile) => {
    return await fetch(urlFile)
      .then(r => r.blob())
      .then(blobFile => new File([blobFile], "nomdufichier", {type: "image/jpeg"}))
      .then(function(result) {
        if (result) {
          const reader = new FileReader();
          const test = {0: result, length: 1};
          reader.addEventListener('load', () =>
           {
            setStateCrop(prevState => ({
              ...prevState,
              src: reader.result,
            }))
           }
          );
          reader.readAsDataURL(test[0]);
        }
      });
  }

  // If you setState the crop in here you should return false.
  const onImageLoaded = (image) => {
    setImageRef(image);
  };

  const onCropComplete = async (crop) => {
    makeClientCrop(crop);
  };

  const onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    setStateCrop(prevState => ({
      ...prevState,
      crop: crop }));
  };

  const makeClientCrop = async (crop) => {
    if (imageRef && crop.width && crop.height) {
      const croppedImageUrl = await getCroppedImg(
        imageRef,
        crop,
        'newFile.jpeg'
      );
      setStateCrop(prevState => ({
        ...prevState,
        croppedImageUrl: croppedImageUrl }));
    }
  }

  const getCroppedImg = (image, crop, fileName) => {
    const canvas = document.createElement('canvas');
    const pixelRatio = window.devicePixelRatio;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY,
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            //reject(new Error('Canvas is empty'));
            console.error('Canvas is empty');
            return;
          }
          blob.name = fileName;
          var fileUrl;
          window.URL.revokeObjectURL(fileUrl);
          fileUrl = window.URL.createObjectURL(blob);
          resolve(fileUrl);
        },
        'image/jpeg',
        1
      );
    });
  }

  return (
    <div className="App">
      {/* <div>
        <input type="file" accept="image/*" id="ChildModalChangePicture" hidden onChange={this.onFileChange} />
      </div> */}
      <div className='cropImage'>
        <div className='cropImage__recadre'>
          <h3>Veuillez recadrer votre image :</h3>
          {stateCrop.src && (
            <ReactCrop
              src={stateCrop.src}
              crop={stateCrop.crop}
              ruleOfThirds={false}
              onImageLoaded={onImageLoaded}
              onComplete={onCropComplete}
              onChange={onCropChange}
            />
          )}
        </div>
        <div className='cropImage__prev'>
          <h3>Prévisualisation :</h3>
          {stateCrop.croppedImageUrl && (
            <img alt="Crop" src={stateCrop.croppedImageUrl} style={{width: '300px', height: '300px'}}/>
          )}
        </div>
      </div>
      <div className='form__btns'>
        <button className='btn btn--secondary' id='validation' onClick={() => {
          handleChangePicture(stateCrop.croppedImageUrl)
        }}>Valider</button>
        <button className='btn btn--light btn--link' onClick={() => hideCrop()}>Annuler</button>
      </div>
    </div>
  )
}

export default ImageResize;