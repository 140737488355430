import React, { useState } from "react";
import { connect } from "react-redux";
// import { SchoolLevelEnum } from "../../helper/dbSignUp";
// import {
//   resetFilter,
//   setFilter,
//   // setOperator,
//   ApplyFilter,
//   LoadFilter,
// } from "../../actions/Database/Pointage";
// import { FilterOperator } from "./filtre/Operator";
// import { FilterSelect } from "./filtre/select";
import { getAvailablePrestations, getFilterChild } from "../../helper/filterChild";
import { dateIsBetweenOrEqualDate, dateStringToDate } from "../../helper/date";
import Modal from "./Modal";
// import { getAvailablePrestations } from "../../helper/filterChild";

// INCLUDES / SUB COMPONENTS
import { FilterTag } from "./includes/FilterTag";
import { FilterCategory } from "./includes/FilterCategory";
import { FormRadio } from "./includes/FormRadio";
import { FormCheckbox } from "./includes/FormCheckbox";
import { CheckboxDropdown } from "./includes/CheckboxDropdown";
import { FetchSomeChild, setShowAllChild } from "../../actions/Database/Child";
import { ApplyFilter, LoadFilter, resetFilter } from "../../actions/Database/Pointage";

const Filtre = ({
    filterPointage,
    Child,
    Pointage,
    hideFilter,
    dispatch,
    swapFilter,
    moment,
}) => {
    const [statutActive, setStatutActive] = useState(0);

    var signup =
    Pointage.Signup &&
    Pointage.Signup.find((Signup) =>
        dateIsBetweenOrEqualDate(
            new Date(Pointage.date),
            dateStringToDate(Signup.start_date),
            dateStringToDate(Signup.end_date)
        )
    );

    if (signup === undefined || moment === -1) return <div></div>;

    // Différents statuts des filtres
    const statutsFiltre = [
        {id: 0, name: "Statut des réservations", mode: 'radio'},
        {id: 7, name: "Lieux d'accueil", mode: 'checkbox'},
        {id: 1, name: "Niveaux scolaires", mode: 'checkbox'},
        {id: 2, name: "Écoles et classes", mode: 'dropdown'},
        {id: 3, name: "Groupes", mode: 'checkbox'},
        {id: 4, name: "Prestations", mode: 'checkbox'},
        {id: 5, name: "Services de restauration", mode: 'checkbox'},
        {id: 6, name: "Bus et arrêts", mode: 'dropdown'},
    ];

    // Différents statuts des résas (absent, presents...)
    const statutsResa = [
        {id: 1, name: "Afficher tous les enfants même sans réservation"},
        {id: 2, name: "Afficher toutes les réservations"},
        {id: 3, name: "Afficher uniquement les pointages manquants"},
        {id: 4, name: "Afficher uniquement les présences"},
        {id: 5, name: "Afficher uniquement les absences"},
    ];

    const getNbAmountFilter = (id, moment) => {
        let total = 0;

        switch(id) {
            // Statut des réservations (toujours à 1)
            case 0:
                if (swapFilter.Special[moment].enabled) {
                    total += swapFilter.Special[moment].filter.length;
                }
                if (total === 0) {
                    total = 1;
                }
                return total;
            // Lieux d'accueil
            case 7:
                if (swapFilter.Location !== undefined && swapFilter.Location[moment].enabled) {
                    total += swapFilter.Location[moment].filter.length;
                }
                return total;
            // Classes
            case 1:
                if (swapFilter.Level[moment].enabled) {
                    total += swapFilter.Level[moment].filter.length;
                }
                return total;
            // Écoles et enseignants
            case 2:
                if (swapFilter.Teacher[moment].enabled) {
                    total += swapFilter.Teacher[moment].filter.length;
                }
                if (swapFilter.School[moment].enabled) {
                    total += swapFilter.School[moment].filter.length;
                }
                return total;
            // Groupes
            case 3:
                if (swapFilter.Groupe[moment].enabled) {
                    total += swapFilter.Groupe[moment].filter.length;
                }
                return total;
            // Prestations
            case 4:
                if (swapFilter.Presta[moment].enabled) {
                    total += swapFilter.Presta[moment].filter.length;
                }
                return total;
            // Services de restauration
            case 5:
                if (swapFilter.Service && swapFilter.Service[moment].enabled) {
                    total += swapFilter.Service[moment].filter.length;
                }
                return total;
            // Bus et arrêts
            case 6:
                if (swapFilter.Bus[moment].enabled) {
                    total += swapFilter.Bus[moment].filter.length;
                }
                if (swapFilter.Stop[moment].enabled) {
                    total += swapFilter.Stop[moment].filter.length;
                }
                return total;
            default:
                return 0;
        }
    }

    return (
    <Modal show={filterPointage} hide={hideFilter} label="Filtres" modalClassName="modal-filters">
        {/* Affiche et controler les filtres actifs */}
        <div className="active-filters">
            <div className="ttl">Filtres sélectionnés</div>
            <div className="filters">
                {getFilterChild(Pointage, Child)[0].name !== 'Aucun filtre actif' ?
                    getFilterChild(Pointage, Child).map(filter => (
                        <FilterTag key={filter.name + "-" + filter.id} dispatch={dispatch} id={filter.id} category={filter.category} notAction={false}>{filter.name}</FilterTag>
                    )) :
                    <i>Aucun filtre actif</i>
                }
            </div>
        </div>
        <div className="filter-selection">
            <div className="filter-categories">
                {/* Menu latéral */}
                {statutsFiltre.map(statut => 
                    ((statut.id === 5 &&
                    swapFilter.Service) ||
                    statut.id !== 5) && (
                    <FilterCategory activeAmount={getNbAmountFilter(statut.id, Pointage.momentID)} key={statut.name + "-" + statut.id} isActive={statut.id === statutActive} handleNav={() => setStatutActive(statut.id)}>{statut.name}</FilterCategory>
                ))}
                {/*<FilterCategory isActive activeAmount="2">Statut des réservations</FilterCategory>*/}
            </div>

            <div className="filters">
                <div className="ttl">{statutsFiltre.find(statut => statut.id === statutActive).name}</div>
                <div className="grid">
                    <div className="grid__row">
                        {/* Filtrage */}
                        {statutsFiltre.find(statut => statut.id === statutActive).mode.includes('radio') &&
                            <div className="grid__col6">
                                {statutActive === 0 &&
                                statutsResa.length > 0 &&
                                statutsResa.map(statut => (
                                    <FormRadio
                                        key={statut.name + "-" + statut.id}
                                        dispatch={dispatch}
                                        id={statut.name}
                                        name="statut"
                                        category="Special"
                                        categoryId={statut.id}
                                        isActive={() =>
                                            (statut.id === 2 && swapFilter.Special[moment].enabled === false) || swapFilter.Special[moment].filter.includes(statut.id)
                                        }
                                    >{statut.name}</FormRadio>
                                ))}
                            </div>
                        }
                        {statutsFiltre.find(statut => statut.id === statutActive).mode.includes('checkbox') &&
                            <div className="grid__col6">
                                {statutActive === 4 && <p className="color--error">Le filtre par prestation n'est pas compatible avec l'affichage des enfants sans réservation.</p>}
                                {/* Filtre par niveaux */}
                                {statutActive === 1 && (<div>
                                    {signup.levels !== undefined &&
                                Object.entries(signup.levels).map((level) => (
                                    <FormCheckbox
                                        key={level[0] + "-" + level[1]}
                                        dispatch={dispatch}
                                        id={level[0]}
                                        name={level[0]}
                                        isActive={swapFilter.Level[moment].filter.includes(level[1])}
                                        category="Level"
                                        categoryId={level[1]}
                                    >
                                            {level[0]}
                                    </FormCheckbox>
                                ))}
                                </div>)
                                }

                                {/* Filtre par lieu d'accueil */}
                                {statutActive === 7 && swapFilter.Location !== undefined && (<div>
                                    {signup.locations !== undefined &&
                                signup.locations.map((location) => (
                                    <FormCheckbox
                                        key={location.id + "-" + location.name}
                                        dispatch={dispatch}
                                        id={location.id}
                                        name={location.name}
                                        isActive={swapFilter.Location[moment].filter.includes(location.id)}
                                        category="Location"
                                        categoryId={location.id}
                                    >
                                            {location.name}
                                    </FormCheckbox>
                                ))}
                                </div>)
                                }


                                {/* Filtre par groupes */}
                                {statutActive === 3 &&
                                Child.childGroup !== undefined &&
                                Child.childGroup.length > 0 &&
                                Child.childGroup.map((group) => (
                                    <FormCheckbox
                                        key={group.name + "-" + group.id}
                                        dispatch={dispatch}
                                        id={group.name}
                                        name={group.name}
                                        isActive={swapFilter.Groupe[moment].filter.includes(group.id)}
                                        category="Groupe"
                                        categoryId={group.id}
                                    >
                                            {group.name}
                                    </FormCheckbox>
                                ))}

                                {/* Filtre par prestations */}
                                {statutActive === 4 &&
                                Pointage.Prestations.length > 0 &&
                                getAvailablePrestations(
                                    Pointage.Prestations,
                                    Pointage.momentID,
                                    Pointage.prestationsType
                                ).map((presta) => (
                                    <FormCheckbox
                                        key={presta.name + "-" + presta.id}
                                        dispatch={dispatch}
                                        id={presta.name}
                                        forId={presta.name + "-" + presta.id}
                                        name={presta.name}
                                        isActive={swapFilter.Presta[moment].filter.includes(presta.id)}
                                        category="Presta"
                                        categoryId={presta.id}
                                    >
                                            {presta.name}
                                    </FormCheckbox>
                                ))}

                                {/* Filtre par services */}
                                {statutActive === 5 &&
                                swapFilter.Service &&
                                Child.childMealService !== undefined &&
                                Child.childMealService.length > 0 &&
                                Child.childMealService.map((service) => (
                                    <FormCheckbox
                                        key={service.name + "-" + service.id}
                                        dispatch={dispatch}
                                        id={service.name}
                                        name={service.name}
                                        isActive={swapFilter.Service[moment].filter.includes(service.id)}
                                        category="Service"
                                        categoryId={service.id}
                                    >
                                            {service.name}
                                    </FormCheckbox>
                                ))}
                            </div>
                        }
                        {statutsFiltre.find(statut => statut.id === statutActive).mode.includes('dropdown') &&
                            <div className="grid__col6">
                                {/* Filtre par écoles et enseignants */}
                                {statutActive === 2 &&
                                signup.schools !== undefined &&
                                signup.schools.length > 0 && (
                                signup.schools.map((school) => (
                                    <CheckboxDropdown
                                        key={school.name + "-" + school.id}
                                        dispatch={dispatch}
                                        label={school.name}
                                        childrens={{array: signup.teachers.filter(teacher => teacher.school_id === school.id), category: "Teacher"}}
                                        isActive={swapFilter.School[moment].filter.includes(school.id)}
                                        category="School"
                                        categoryId={school.id}
                                    >
                                        {signup.teachers
                                            .filter(teacher => teacher.school_id === school.id)
                                            .map(teacher => (
                                                <FormCheckbox
                                                    key={teacher.name + "-" + teacher.id}
                                                    dispatch={dispatch}
                                                    id={teacher.name}
                                                    name={teacher.name}
                                                    forId={school.id + '-' + teacher.name}
                                                    isActive={swapFilter.Teacher[moment].filter.includes(teacher.id)}
                                                    category="Teacher"
                                                    categoryId={teacher.id}
                                                >{teacher.name}</FormCheckbox>
                                            ))}
                                    </CheckboxDropdown>
                                )))}
                                {/* Filtre par bus et arrêts */}
                                {statutActive === 6 && (
                                    <>
                                        <CheckboxDropdown
                                            key={"bus-0"}
                                            dispatch={dispatch}
                                            label={"Sans bus"}
                                            isActive={swapFilter.Bus[moment].filter.includes(-1)}
                                            category="Bus"
                                            categoryId={-1}
                                        ></CheckboxDropdown>
                                        {Pointage.Bus !== undefined &&
                                        Pointage.Bus.length > 0 && (
                                        Pointage.Bus.map((bus) => (
                                            <CheckboxDropdown
                                                key={bus.name + "-" + bus.id}
                                                dispatch={dispatch}
                                                label={bus.name}
                                                isActive={swapFilter.Bus[moment].filter.includes(bus.id)}
                                                childrens={{array: bus.stops, category: "Stop"}}
                                                category="Bus"
                                                categoryId={bus.id}
                                            >
                                                {bus.stops !== undefined &&
                                                bus.stops.length > 0 &&
                                                bus.stops
                                                    .map(stop => (
                                                        <FormCheckbox
                                                            key={stop.name + "-" + stop.id}
                                                            dispatch={dispatch}
                                                            id={stop.name}
                                                            name={stop.name}
                                                            forId={bus.id + '-' + stop.name}
                                                            isActive={swapFilter.Stop[moment].filter.includes(stop.id)}
                                                            category="Stop"
                                                            categoryId={stop.id}
                                                        >{stop.name}</FormCheckbox>
                                                    ))}
                                            </CheckboxDropdown>
                                        )))}
                                    </>)}
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>

        <div className="form__btns">
            <button
                className="btn btn--secondary"
                onClick={() => {
                    if (swapFilter.Special[moment].filter.includes(1)) {
                        dispatch(setShowAllChild({ checked: true, fetch: true}));
                    }
                    if (swapFilter.Special[moment].filter.includes(2)) {
                        dispatch(setShowAllChild({ checked: false, fetch: true}));
                    }
                    dispatch(ApplyFilter());
                    dispatch(FetchSomeChild({}));
                    hideFilter();
                }}
            >Appliquer les filtres</button>
            <button
                className="btn btn--error btn--link align-right"
                onClick={() => {
                    dispatch(resetFilter());
                    dispatch(ApplyFilter());
                    dispatch(FetchSomeChild());
                    hideFilter();
                }}
            >Supprimer les filtres</button>
            <button
                className="btn btn--light btn--link"
                onClick={() => {
                    hideFilter();
                    dispatch(LoadFilter());
                }}
            >Annuler</button>
        </div>
    </Modal>
  );
};

export default connect(({ ShowModal, Child, Pointage }) => ({
    filterPointage: ShowModal.filterPointage,
    Child: Child,
    Pointage: Pointage,
    swapFilter: Pointage.swapFilter,
    moment: Pointage.momentID,
}))(
    React.memo(Filtre, (oldProps, newProps) => {
        if (
            oldProps.filterPointage !== newProps.filterPointage ||
            newProps.Pointage.Prestations !== oldProps.Pointage.Prestations ||
            newProps.swapFilter !== oldProps.swapFilter ||
            newProps.moment !== oldProps.moment
        )
        return false;
        return true;
    })
);
