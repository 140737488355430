import React from "react";
import { connect } from "react-redux";
import { setShowUpdateResa } from "../../../../actions/showModal";
import { getPrestaLabel } from "../../../../helper";
import {
  filterPrestation,
  getAvailablePrestations,
} from "../../../../helper/filterChild";

const Presta = ({
  dispatch,
  Prestations,
  canShowPresta,
  childId,
  prestation_id,
  resa,
}) => {
  return (
    <>
      {canShowPresta >= 1 ?
      (resa.is_forfait === null || resa.is_forfait === false) && (
        <div
          className={((resa.presta_change || resa.is_add || resa.is_unexpected || resa.is_change) && 'editPresta--error') + ' editPresta'}
          onClick={() =>
            dispatch(setShowUpdateResa({ show: true, childId: childId }))
          }
        >
          {
            resa.is_add || resa.is_unexpected ?
              <span className="circle__icon">
                <svg className="icon icon__plus">
                  <use
                    href={process.env.PUBLIC_URL + "/picto/symbol-defs.svg#icon__plus"}
                  ></use>
                </svg>
              </span> :
            (resa.presta_change || resa.is_change) ?
              <span className="circle__icon">
                <svg className="icon icon__warning">
                  <use
                    href={process.env.PUBLIC_URL + "/picto/symbol-defs.svg#icon__warning"}
                  ></use>
                </svg>
              </span> : ''
          }
          <svg className="icon icon__pen">
            <use
              href={process.env.PUBLIC_URL + "/picto/symbol-defs.svg#icon__pen"}
            ></use>
          </svg>
          <span htmlFor="editPrestation" className="span_edit">{getPrestaLabel(Prestations, prestation_id)}</span>
        </div>
      ):(
        <span htmlFor="editPrestation" className="span_edit">{getPrestaLabel(Prestations, prestation_id)}</span>
      )}
      {resa.is_forfait >= 1 && (
        <span className="span_edit">Forfait semaine</span>
      )}
    </>
  );
};

export default connect(({ Pointage, Child }, { childId, prestation_id }) => ({
  canShowPresta:
    childId !== -1
      ? getAvailablePrestations(Pointage.Prestations, Pointage.momentID).filter(
          (value) =>
            value.id !== prestation_id &&
            filterPrestation(
              value,
              Child.filteredChild.find((e) => e.id === childId),
              false,
              Pointage.Signup,
              Pointage.date,
              Pointage.holidays
            )
        ).length
      : 0,
  Prestations: Pointage.Prestations,
}))(
  React.memo(Presta, (oldProps, newProps) => {
    if (
      oldProps.canShowPresta === newProps.canShowPresta &&
      oldProps.prestation_id === newProps.prestation_id
    )
      return true;
  })
);
