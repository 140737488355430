import { createSlice } from "@reduxjs/toolkit";
import { SessionHandler, crypt } from "../../helper";
import { Login } from "./Login";
import { Logout } from "./LogOut";
import { Lock } from "./Lock";
import { ActionLostPin, ShowLostPin, HideLostPin } from "./LostPin";
import { YouAreNotAction, YouAreNotShow, YouAreNotHide } from "./YouAreNot";
import { LogWithPin } from "./LogWithPin";
import { Startup } from "../onStartUp";
import { SetPin } from "./SetPin";
import { GetUser } from "../GetUser";

const stateError = (state, { payload, ...truc }) => {
  if (process.env.REACT_APP_DEBUG === "true")
    console.log(truc, payload?.code | state.Error);
  state.Pending = false;
  state.Error = payload?.code || payload.error || payload.Error;
  if (payload !== undefined) {
    state.ErrorContent = payload.errorContent;
    stateRedirect(state, payload);
  }
};

const stateSuccess = (state, { payload, ...truc }) => {
  state.Pending = false;
  state.SessionData =
    crypt.hasCryptedKey() || SessionHandler.hasSession()
      ? SessionHandler.getSession()
      : "";
  state.PinSet = crypt.hasCryptedKey();
  if (!payload) return;
  if (payload.Access !== undefined) state.Access = payload.Access;
  if (payload.sync !== undefined) {
    state.syncAfterPinSet = payload.sync;
    if (payload.sync) state.show.Query = {};
  }
  stateRedirect(state, payload);
};
const stateRedirect = (state, { show, to }) => {
  if (show) {
    state.show.Active = true;
    state.show.to = to;
  }
};
const stateReset = (state) => {
  state.Pending = true;
  state.Error = false;
  state.ErrorContent = false;
};

var extraReducers = (Action) =>
  Action.reduce(
    (returnObject, valeurCourante) => ({
      ...returnObject,
      [valeurCourante.pending]: stateReset,
      [valeurCourante.rejected]: stateError,
      [valeurCourante.fulfilled]: stateSuccess,
    }),
    {}
  );

export const AuthSlice = createSlice({
  name: "Auth",
  initialState: {
    Pending: false,
    Access: false,
    Error: false,
    ErrorContent: false,
    syncAfterPinSet: true,
    PinSet: crypt.hasCryptedKey(),
    show: {
      Active: false,
      to: null,
      Query: {},
    },
  },
  reducers: {
    setQuery: (state, { payload }) => {
      state.show.Query = payload;
    },
    resetQuery: (state, { payload }) => {
      state.show.Query = {};
    },
    addQuery: (state, { payload }) => {
      state.show.Query[payload.param] = payload.value;
    },
    resetShow: (state, { payload }) => {
      state.Active = false;
      state.to = null;
      state.show.redirect = false;
    },
    setShow: (state, { payload }) => {
      state.show.to = payload.to;
      state.show.Active = true;
      state.show.redirect = true;
    },
  },
  extraReducers: extraReducers([
    Login,
    Logout,
    LogWithPin,
    Lock,
    ShowLostPin,
    ActionLostPin,
    YouAreNotAction,
    YouAreNotShow,
    Startup,
    SetPin,
    GetUser,
    HideLostPin,
    YouAreNotHide,
  ]),
});

export const { setQuery, resetQuery, addQuery, resetShow, setShow } =
  AuthSlice.actions;
export default AuthSlice.reducer;
