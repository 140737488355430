import React from "react";
import { connect } from "react-redux";
import ModalInfo from "./info";
import ModalSync from "./sync";
import ModalChild from "./ModalChildren/modalChild";
import ModalReset from "./reset";
import ModalClearData from "./clearData";
import ModalAskPin from "./askPin";
import ModalChangePin from "./ChangeCodePin";
import ModalLock from "./Disconnect";
import ModalFiltre from "./filtre";
import ModalStats from "./Stats";
import ModalAddResa from "../Pointage/addResa";
import ModalUpdateResa from "../Pointage/changeResa";
import {
  hideInfo,
  hideDeviceName,
  hideSynchro,
  hideReset,
  hideAskPin,
  hideChangePin,
  hideLock,
  setShowFilterPointage,
  setShowAddResa,
  setShowUpdateResa,
  setShowAllAbsent,
  setShowErrorSync,
  setShowCrop,
  setShowStats,
  hideClearData,
} from "../../actions/showModal";
import { LoadFilter } from "../../actions/Database/Pointage";
import ModalAction from "../Pointage/globalAction/ModalAction";
import ModalErrorSync from "./ErrorSync";
import ModalCropImage from "./CropImage";
import ModalDeviceName from "./DeviceName";

const ModalHandler = ({ dispatch }) => {
  return (
    <div>
      <ModalChild />
      <ModalInfo hideInfo={() => dispatch(hideInfo())} />
      <ModalDeviceName hideDeviceName={() => dispatch(hideDeviceName())} />
      <ModalSync hideSynchro={() => dispatch(hideSynchro())} />
      <ModalReset hideReset={() => dispatch(hideReset())} />
      <ModalClearData hideClearData={() => dispatch(hideClearData())} />
      <ModalAskPin hideAskPin={(param) => dispatch(hideAskPin(param))} />
      <ModalChangePin
        hideChangePin={(param) => dispatch(hideChangePin(param))}
      />
      <ModalLock hideLock={(param) => dispatch(hideLock(param))} />
      <ModalFiltre
        hideFilter={(param) => {
          dispatch(setShowFilterPointage(false));
          dispatch(LoadFilter());
        }}
      />
      <ModalStats
        hideStats={(param) => {
          dispatch(setShowStats(false));
        }}
      />
      <ModalAddResa
        hideResa={() => dispatch(setShowAddResa({ show: false }))}
      />
      <ModalUpdateResa hideUpdateResa={() => dispatch(setShowUpdateResa())} />
      <ModalAction hideAction={() => dispatch(setShowAllAbsent(false))} />
      <ModalErrorSync
        hideError={() => dispatch(setShowErrorSync({ show: false }))}
      />
      <ModalCropImage hideCrop={() => dispatch(setShowCrop(false))} />
    </div>
  );
};
export default connect()(ModalHandler);
