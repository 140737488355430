import React, { useEffect, useState } from "react";
import Modal from "./Modal";
import { dateIsBetweenOrEqualDate, dateStringToDate } from "../../helper/date";
import { connect } from "react-redux";
import { getFilterChild } from "../../helper/filterChild";
import { FilterTag } from "./includes/FilterTag";
import { setShowFilterPointage, setShowStats } from "../../actions/showModal";


const Stats = ({
    showStats,
    hideStats,
    Child,
    Pointage,
    momentID,
    dispatch,
    Signups,
}) => {
    const [statsNb, setStatsNb] = useState({
        presents: 0,
        absents: 0,
        nonPointe: 0,
        presPartiels: 0,
        absentsPartiels: 0,
    });
    const [resasMain, setResasMain] = useState([]);
    const [resasSecondary, setResasSecondary] = useState([]);
    const [arrayStats, setArrayStats] = useState({});
    const [totalStats, setTotalStats] = useState({});
    const [ventilActions, setVentilActions] = useState({
        section: false,
        prestations: false,
    });
    const [filterModeSelect, setFilterModeSelect] = useState("all");
    const [optModeSelect, setOptModeSelect] = useState([
        {
            key: "all",
            label: "Afficher uniquement le total"
        }
    ]);

    const getNbPresentByLevel = (reservations) => {
        const res = {
            mat: 0,
            elem: 0,
            col: 0,
            lyc: 0,
            total: 0,
        };

        // Pour chaque reservation présentes
        reservations.filter(resa => resa.is_present !== 0)
            .forEach(resa => {
                let the_child = Child.child.find(child => child.id === resa.child_id);
                let signup = the_child.signups.find(
                    (value) =>
                      value.year_id ===
                      Pointage.Signup.find((signupG) =>
                        dateIsBetweenOrEqualDate(
                          new Date(),
                          dateStringToDate(signupG.start_date),
                          dateStringToDate(signupG.end_date)
                        )
                      ).id);
                if ([2,3,4,5].includes(signup.level)) {
                    res.mat += 1;
                }
                if ([6,7,8,9,10].includes(signup.level)) {
                    res.elem += 1;
                }
                if ([11,12,13,14].includes(signup.level)) {
                    res.col += 1;
                }
                if ([15,16,17].includes(signup.level)) {
                    res.lyc += 1;
                }
                res.total += 1;
            }
        )
        return res;
    }
    useEffect(() => {
        setResasMain(Pointage.Reservation &&
            Pointage.Prestations &&
            Child.filteredChild &&
            Pointage.Reservation.filter(
                resa => Pointage.Prestations.find(
                            presta => presta.id === resa.prestation_id
                        ).secondary === false &&
                        Child.filteredChild.find(
                            child => child.id === resa.child_id
                        )
            ));
        setResasSecondary(Pointage.Reservation &&
            Pointage.Prestations &&
            Child.filteredChild &&
            Pointage.Reservation.filter(
                resa => Pointage.Prestations.find(
                    presta => presta.id === resa.prestation_id
                ).secondary === true &&
                Child.filteredChild.find(
                    child => resa.child_id === child.id
                )
            ));

        if (Pointage.prestationsType.includes(5)) {
            const res_total = [];
            // Pour chaque type de restauration
            Pointage.MealTypes.forEach(meal_type => {
                if (meal_type !== undefined) {
                    // On initialise le tableau de type de restauration
                    const res_meal_type = {
                        id: meal_type.id,
                        name: meal_type.name,
                        prestations: [],
                        services: [],
                        groupes: [],
                        total: {}
                    };

                    // On recherche toutes les prestas associées à ce type de restauration
                    const prestas = Pointage.Prestations.filter(prestation => prestation.meal_type && prestation.meal_type === meal_type.id);
                    const res_prestas = [];
                    prestas.forEach(presta => {
                        const res_presta = {
                            id: presta.id,
                            name: presta.name,
                            services: [],
                            groupes: [],
                            total: {
                                mat: 0,
                                elem: 0,
                                col: 0,
                                lyc: 0,
                            }
                        }

                        // Rechercher tout les services associés à la presta
                        var res_services = [];
                        if (Child.childMealService && Child.childMealService.length > 0) {
                            Child.childMealService.forEach(service => {
                                let reservations = Pointage.Reservation.filter(resa => {
                                    if (resa.prestation_id !== presta.id) {
                                        return false;
                                    }
                                    let the_child = Child.child.find(child => child.id === resa.child_id);
                                    let signup = the_child.signups.find(
                                        (value) =>
                                        value.year_id ===
                                        Pointage.Signup.find((signupG) =>
                                            dateIsBetweenOrEqualDate(
                                            new Date(),
                                            dateStringToDate(signupG.start_date),
                                            dateStringToDate(signupG.end_date)
                                            )
                                        ).id);
                                    return signup.affectations.some(affect => {
                                        return affect.mealservice_id && affect.mealservice_id === service.id;
                                    })
                                })

                                let res_resas = getNbPresentByLevel(reservations);
                                res_services.push({
                                    id: service.id,
                                    name: service.name,
                                    total: res_resas,
                                })
                            })

                            // Ajout d'une colonne "sans services"
                            let reservations_without_services = Pointage.Reservation.filter(resa => {
                                if (resa.prestation_id !== presta.id) {
                                    return false;
                                }
                                let the_child = Child.child.find(child => child.id === resa.child_id);
                                let signup = the_child.signups.find(
                                    (value) =>
                                    value.year_id ===
                                    Pointage.Signup.find((signupG) =>
                                        dateIsBetweenOrEqualDate(
                                        new Date(),
                                        dateStringToDate(signupG.start_date),
                                        dateStringToDate(signupG.end_date)
                                        )
                                    ).id);
                                return signup.affectations.some(affect => {
                                    return affect.type === 7 && affect.mealservice_id === null;
                                })
                            })
                            res_services.push({
                                id: 0,
                                name: "Sans services",
                                total: getNbPresentByLevel(reservations_without_services)
                            })
                        }

                        // Rechercher tout les groupes associés à la presta
                        var res_groupes = [];
                        if (Child.childGroup && Child.childGroup.length > 0) {
                            Child.childGroup.forEach(group => {
                                let reservations = Pointage.Reservation.filter(resa => {
                                    if (resa.prestation_id !== presta.id) {
                                        return false;
                                    }
                                    let the_child = Child.child.find(child => child.id === resa.child_id);
                                    let signup = the_child.signups.find(
                                        (value) =>
                                        value.year_id ===
                                        Pointage.Signup.find((signupG) =>
                                            dateIsBetweenOrEqualDate(
                                            new Date(),
                                            dateStringToDate(signupG.start_date),
                                            dateStringToDate(signupG.end_date)
                                            )
                                        ).id);
                                    return signup.affectations.some(affect => {
                                        return affect.group_id && affect.group_id === group.id;
                                    })
                                })

                                let res_resas = getNbPresentByLevel(reservations);
                                res_groupes.push({
                                    id: group.id,
                                    name: group.name,
                                    total: res_resas,
                                });
                            })
                            // Ajout d'une colonne "sans groupes"
                            let reservations_without_groupes = Pointage.Reservation.filter(resa => {
                                if (resa.prestation_id !== presta.id) {
                                    return false;
                                }
                                let the_child = Child.child.find(child => child.id === resa.child_id);
                                let signup = the_child.signups.find(
                                    (value) =>
                                    value.year_id ===
                                    Pointage.Signup.find((signupG) =>
                                        dateIsBetweenOrEqualDate(
                                        new Date(),
                                        dateStringToDate(signupG.start_date),
                                        dateStringToDate(signupG.end_date)
                                        )
                                    ).id);
                                return signup.affectations.some(affect => {
                                    return affect.type === 7 && affect.group_id === null;
                                })
                            })
                            res_groupes.push({
                                id: 0,
                                name: "Sans groupes",
                                total: getNbPresentByLevel(reservations_without_groupes)
                            })
                        }

                        res_presta.services = res_services;
                        res_presta.groupes = res_groupes;
                        res_presta.total = getNbPresentByLevel(Pointage.Reservation.filter(resa => resa.prestation_id === presta.id))
                        res_prestas.push(res_presta);
                    });

                    // Rechercher tout les services associés au type de restauration
                    var res_services = [];
                    if (Child.childMealService && Child.childMealService.length > 0) {
                        Child.childMealService.forEach(service => {
                            let reservations = Pointage.Reservation.filter(resa => {
                                if (!prestas.some(presta => presta.id === resa.prestation_id)) {
                                    return false;
                                }
                                let the_child = Child.child.find(child => child.id === resa.child_id);
                                let signup = the_child.signups.find(
                                    (value) =>
                                    value.year_id ===
                                    Pointage.Signup.find((signupG) =>
                                        dateIsBetweenOrEqualDate(
                                        new Date(),
                                        dateStringToDate(signupG.start_date),
                                        dateStringToDate(signupG.end_date)
                                        )
                                    ).id);
                                return signup.affectations.some(affect => {
                                    return affect.mealservice_id && affect.mealservice_id === service.id;
                                })
                            })

                            let res_resas = getNbPresentByLevel(reservations);
                            res_services.push({
                                id: service.id,
                                name: service.name,
                                total: res_resas,
                            })
                        })
                        // Ajout d'une colonne "sans services"
                        let reservations_without_services = Pointage.Reservation.filter(resa => {
                            if (!prestas.some(presta => presta.id === resa.prestation_id)) {
                                return false;
                            }
                            let the_child = Child.child.find(child => child.id === resa.child_id);
                            let signup = the_child.signups.find(
                                (value) =>
                                value.year_id ===
                                Pointage.Signup.find((signupG) =>
                                    dateIsBetweenOrEqualDate(
                                    new Date(),
                                    dateStringToDate(signupG.start_date),
                                    dateStringToDate(signupG.end_date)
                                    )
                                ).id);
                            return signup.affectations.some(affect => {
                                return affect.type === 7 && affect.mealservice_id === null;
                            })
                        })
                        res_services.push({
                            id: 0,
                            name: "Sans services",
                            total: getNbPresentByLevel(reservations_without_services)
                        })
                    }
                    console.log(res_services);

                    // Rechercher tout les groupes associés au type de restauration
                    var res_groupes = [];
                    if (Child.childGroup && Child.childGroup.length > 0) {
                        Child.childGroup.forEach(group => {
                            let reservations = Pointage.Reservation.filter(resa => {
                                if (!prestas.some(presta => presta.id === resa.prestation_id)) {
                                    return false;
                                }
                                let the_child = Child.child.find(child => child.id === resa.child_id);
                                let signup = the_child.signups.find(
                                    (value) =>
                                    value.year_id ===
                                    Pointage.Signup.find((signupG) =>
                                        dateIsBetweenOrEqualDate(
                                        new Date(),
                                        dateStringToDate(signupG.start_date),
                                        dateStringToDate(signupG.end_date)
                                        )
                                    ).id);
                                return signup.affectations.some(affect => {
                                    return affect.group_id && affect.group_id === group.id;
                                })
                            })

                            let res_resas = getNbPresentByLevel(reservations);
                            res_groupes.push({
                                id: group.id,
                                name: group.name,
                                total: res_resas,
                            });
                        })
                        // Ajout d'une colonne "sans groupes"
                        let reservations_without_groupes = Pointage.Reservation.filter(resa => {
                            if (!prestas.some(presta => presta.id === resa.prestation_id)) {
                                return false;
                            }
                            let the_child = Child.child.find(child => child.id === resa.child_id);
                            let signup = the_child.signups.find(
                                (value) =>
                                value.year_id ===
                                Pointage.Signup.find((signupG) =>
                                    dateIsBetweenOrEqualDate(
                                    new Date(),
                                    dateStringToDate(signupG.start_date),
                                    dateStringToDate(signupG.end_date)
                                    )
                                ).id);
                            return signup.affectations.some(affect => {
                                return affect.type === 7 && affect.group_id === null;
                            })
                        })
                        res_groupes.push({
                            id: 0,
                            name: "Sans groupes",
                            total: getNbPresentByLevel(reservations_without_groupes)
                        })
                    }

                    res_meal_type.prestations = res_prestas;
                    res_meal_type.services = res_services;
                    res_meal_type.groupes = res_groupes;

                    res_meal_type.total = getNbPresentByLevel(Pointage.Reservation.filter(resa => {
                        return prestas.some(presta => presta.id === resa.prestation_id);
                    }))

                    res_total.push(res_meal_type);
                }
            })

            setArrayStats({meal_types: res_total});

            const res_total_final = {
                groupes: [],
                services: [],
                total: {},
            };

            const res_groupes = [];
            if (Child.childGroup && Child.childGroup.length > 0) {
                Child.childGroup.forEach(group => {
                    let reservations = Pointage.Reservation.filter(resa => {
                        let the_child = Child.child.find(child => child.id === resa.child_id);
                        let signup = the_child.signups.find(
                            (value) =>
                            value.year_id ===
                            Pointage.Signup.find((signupG) =>
                                dateIsBetweenOrEqualDate(
                                new Date(),
                                dateStringToDate(signupG.start_date),
                                dateStringToDate(signupG.end_date)
                                )
                            ).id);
                        return signup.affectations.some(affect => {
                            return affect.group_id && affect.group_id === group.id;
                        })
                    })

                    let res_resas = getNbPresentByLevel(reservations);
                    res_groupes.push({
                        id: group.id,
                        name: group.name,
                        total: res_resas,
                    });
                })
                // Ajout d'une colonne "sans groupes"
                let reservations_without_groupes = Pointage.Reservation.filter(resa => {
                    let the_child = Child.child.find(child => child.id === resa.child_id);
                    let signup = the_child.signups.find(
                        (value) =>
                        value.year_id ===
                        Pointage.Signup.find((signupG) =>
                            dateIsBetweenOrEqualDate(
                            new Date(),
                            dateStringToDate(signupG.start_date),
                            dateStringToDate(signupG.end_date)
                            )
                        ).id);
                    return signup.affectations.some(affect => {
                        return affect.type === 7 && affect.group_id === null;
                    })
                });
                res_groupes.push({
                    id: 0,
                    name: "Sans groupes",
                    total: getNbPresentByLevel(reservations_without_groupes)
                })
            }

            const res_services = [];
            if (Child.childMealService && Child.childMealService.length > 0) {
                Child.childMealService.forEach(service => {
                    let reservations = Pointage.Reservation.filter(resa => {
                        let the_child = Child.child.find(child => child.id === resa.child_id);
                        let signup = the_child.signups.find(
                            (value) =>
                            value.year_id ===
                            Pointage.Signup.find((signupG) =>
                                dateIsBetweenOrEqualDate(
                                new Date(),
                                dateStringToDate(signupG.start_date),
                                dateStringToDate(signupG.end_date)
                                )
                            ).id);

                        return signup.affectations.some(affect => {
                            return affect.mealservice_id && affect.mealservice_id === service.id;
                        })
                    })

                    let res_resas = getNbPresentByLevel(reservations);
                    res_services.push({
                        id: service.id,
                        name: service.name,
                        total: res_resas,
                    });
                })
                // Ajout d'une colonne "sans services"
                let reservations_without_services = Pointage.Reservation.filter(resa => {
                    let the_child = Child.child.find(child => child.id === resa.child_id);
                    let signup = the_child.signups.find(
                        (value) =>
                        value.year_id ===
                        Pointage.Signup.find((signupG) =>
                            dateIsBetweenOrEqualDate(
                            new Date(),
                            dateStringToDate(signupG.start_date),
                            dateStringToDate(signupG.end_date)
                            )
                        ).id);
                    return signup.affectations.some(affect => {
                        return affect.type === 7 && affect.mealservice_id === null;
                    })
                })
                res_services.push({
                    id: 0,
                    name: "Sans services",
                    total: getNbPresentByLevel(reservations_without_services)
                })
            }

            res_total_final.groupes = res_groupes;
            res_total_final.services = res_services;
            res_total_final.total = getNbPresentByLevel(Pointage.Reservation);
            setTotalStats(res_total_final);
        }
    // eslint-disable-next-line
    }, [Pointage, Child]);

    useEffect(() => {
        // Nombre de présents
        // Nombre d'absents
        // Nombre de non pointé
        if (resasMain.length > 0) {
            setStatsNb(prevState => ({
                ...prevState,
                presents: resasMain.filter(stat => stat.is_present === 1).length,
                absents: resasMain.filter(stat => stat.is_present === 0).length,
                nonPointe: resasMain.filter(stat => stat.is_present === null).length,
                presPartiels: resasMain.filter(stat => {
                    // S'il est présent
                    return  (stat.is_present === 1) &&
                    // Si l'une des horaires est incomplète
                    ((stat.arrived_at === undefined || stat.departed_at === undefined) ||
                    // Ou si l'une des prestas complémentaires est incomplètes
                    (resasSecondary.filter(resaSecond => resaSecond.child_id === stat.child_id).some(resa => [0, null].includes(resa.is_present))))}
                ).length,
                absentsPartiels: resasMain.filter(stat =>
                    // S'il est absent
                    (stat.is_present === 0) &&
                    // Ou si l'une des prestas complémentaires est incomplètes
                    (resasSecondary.filter(resaSecond => resaSecond.child_id === stat.child_id).some(resa => [1, null].includes(resa.is_present)))
                ).length,
            }));
        }
    }, [resasMain, resasSecondary, Pointage.Prestations]);

    useEffect(() => {
        if (arrayStats.meal_types && arrayStats.meal_types.length > 0) {
            if (arrayStats.meal_types[0].groupes.length > 0 && !optModeSelect.some(option => option.key === "groupes")) {
                setOptModeSelect(prevState => ([
                    ...prevState,
                    {
                        key: "groupes",
                        label: "Organiser par groupe"
                    }
                ]))
                setFilterModeSelect("groupes");
            }
            if (arrayStats.meal_types[0].services.length > 0 && !optModeSelect.some(option => option.key === "services")) {
                setOptModeSelect(prevState => ([
                    ...prevState,
                    {
                        key: "services",
                        label: "Organiser par service"
                    }
                ]))
                setFilterModeSelect("services");
            }
        }
    }, [arrayStats, optModeSelect]);

    return <Modal
        show={showStats}
        hide={hideStats}
        label={
            "Statistiques " +
            new Date(Pointage.date).toLocaleDateString("fr-fr", {
                day: "numeric",
                month: "numeric",
                year: "numeric",
              }) + " " +
            ((Object.entries(Pointage.moment).length > 0 && Pointage.momentID !== -1 && Pointage.moment[momentID].code) || "")
        }
        modalClassName="modal-stats"
        >
        <div className="grid">
            {/** Filtres actifs **/}
            <div className="active-filters">
                <div className="ttl">Filtres sélectionnés</div>
                <div className="filters">
                    {getFilterChild(Pointage, Child).map((filtre) =>
                        <FilterTag key={filtre.name} notAction={true}>{filtre.name}</FilterTag>
                    )}
                </div>
                <button
                    className="btn btn--primary is-at-right"
                    onClick={() => {
                        dispatch(setShowStats(false));
                        dispatch(setShowFilterPointage(true));
                    }}
                >
                    Modifier les filtres
                </button>
            </div>

            {/** Fiche des données principales **/}
            <section className="stats-card__container">
                <article className="card stats-card stats-card--success">
                    <div className="big">
                        <strong>{statsNb.presents}</strong>
                    </div>
                    <div className="medium">présent{statsNb.presents > 1 && "s"}</div>
                    <div>dont {statsNb.presPartiels} pointage{statsNb.presPartiels > 1 && "s"} partiel{statsNb.presPartiels > 1 && "s"}</div>
                </article>

                <article className="card stats-card stats-card--error">
                    <div className="big">
                        <strong>{statsNb.absents}</strong>
                    </div>
                    <div className="medium">absent{statsNb.absents > 1 && "s"}</div>
                    <div>dont {statsNb.absentsPartiels} pointage{statsNb.absentsPartiels > 1 && "s"} partiel{statsNb.absentsPartiels > 1 && "s"}</div>
                </article>

                <article className="card stats-card stats-card--base">
                    <div className="big">
                        <strong>{statsNb.nonPointe}</strong>
                    </div>
                    <div className="medium">non pointé{statsNb.nonPointe > 1 && "s"}</div>
                </article>
            </section>
            <br/>

            {/** Données sous forme de tableau */}

            {Pointage.prestationsType.includes(5) &&
                arrayStats.meal_types &&
                arrayStats.meal_types.length > 0 &&
                <section className="table-stats__container">
                    <header className="table-stats__controls">
                        <label htmlFor="ventSection">
                            <input type="checkbox" id="ventSection" checked={ventilActions.section} onChange={() => setVentilActions(prevState => ({...prevState, section: !ventilActions.section}))}/>
                            <span>Ventiler par section</span>
                        </label>
                        <label htmlFor="ventPresta">
                            <input type="checkbox" id="ventPresta" checked={ventilActions.prestations} onChange={() => setVentilActions(prevState => ({...prevState, prestations: !ventilActions.prestations}))}/>
                            <span>Ventiler par prestation</span>
                        </label>
                        {/** Select pour sélectionner l'affichage par service, groupe ou total **/}
                        <select
                            onChange={(e) => setFilterModeSelect(e.target.value)}
                            value={filterModeSelect}
                            className="is-at-right"
                            >
                            {optModeSelect.map(option => (
                                <option value={option.key} key={option.key}>
                                    {option.label}
                                </option>
                            ))}
                        </select>
                    </header>
                    <table className="table table-stats">
                        <thead>
                            <tr>
                                <th>Type de repas</th>
                                {/* Nom des services */}
                                {filterModeSelect === "services" &&
                                arrayStats.meal_types[0].services.map(service => (
                                    <th className="has-border-left" key={"service-" + service.id}>{service.name}</th>
                                ))}
                                {/* Nom des groupes */}
                                {filterModeSelect === "groupes" &&
                                arrayStats.meal_types[0].groupes.map(group => (
                                    <th className="has-border-left" key={"groupe-" + group.id}>{group.name}</th>
                                ))}
                                <th className="has-border-left">Total</th>
                            </tr>
                        </thead>

                        <tbody className="table__firstlevel">
                            {arrayStats.meal_types.map(type => (
                                <tr key={"type-" + type.id}>
                                    {/* Prestations / Type de repas */}
                                    <td className="has-border-bottom">
                                        <table className="nested-table-stats">
                                            <tbody>
                                                <tr><td>{type.name}</td></tr>
                                                {ventilActions.prestations &&
                                                    type.prestations.map(presta => (
                                                        <tr key={"presta-" + presta.id}><td>{presta.name}</td></tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </td>
                                    {/* Données par groupes / sections */}
                                    {filterModeSelect === "groupes" &&
                                    type.groupes.map(group => (
                                        <td key={"groupe-" + group.id} className="has-border-left has-border-bottom">
                                            <table className="nested-table-stats">
                                                <tbody>
                                                    <tr>
                                                        {ventilActions.section ?
                                                            <>
                                                                <td>
                                                                    Mat<br />
                                                                    {group.total.mat}
                                                                </td>
                                                                <td>
                                                                    Elem<br />
                                                                    {group.total.elem}
                                                                </td>
                                                                <td>
                                                                    Total<br />
                                                                    {group.total.total}
                                                                </td>
                                                            </> :
                                                            <td>{group.total.total}</td>
                                                        }
                                                    </tr>

                                                    {ventilActions.prestations && type.prestations.map(presta => presta.groupes.filter(groupe => groupe.id === group.id).map(resGroup => (
                                                        <tr key={"statGroupe-" + resGroup.id}>
                                                            {ventilActions.section ?
                                                                <>
                                                                    <td>
                                                                        Mat<br />
                                                                        <span className="digit-bold">{resGroup.total.mat}</span>
                                                                    </td>
                                                                    <td>
                                                                        Elem<br />
                                                                        <span className="digit-bold">{resGroup.total.elem}</span>
                                                                    </td>
                                                                    <td>
                                                                        Total<br />
                                                                        <span className="digit-bold">{resGroup.total.total}</span>
                                                                    </td>
                                                                </> :
                                                                <td>{resGroup.total.total}</td>
                                                            }
                                                        </tr>
                                                )))}
                                                </tbody>
                                            </table>
                                        </td>
                                    ))}

                                    {/* Données par services / sections */}
                                    {filterModeSelect === "services" &&
                                    type.services.map(service => (
                                        <td key={"service-" + service.id} className="has-border-left has-border-bottom">
                                            <table className="nested-table-stats">
                                                <tbody>
                                                    <tr>
                                                        {ventilActions.section ?
                                                            <>
                                                                <td>
                                                                    Mat<br />
                                                                    {service.total.mat}
                                                                </td>
                                                                <td>
                                                                    Elem<br />
                                                                    {service.total.elem}
                                                                </td>
                                                                <td>
                                                                    Total<br />
                                                                    {service.total.total}
                                                                </td>
                                                            </> :
                                                            <td>{service.total.total}</td>
                                                        }
                                                    </tr>
                                                    {ventilActions.prestations && type.prestations.map(presta => presta.services.filter(serve => serve.id === service.id).map(resService => (
                                                        <tr key={"statService-" + resService.id}>
                                                            {ventilActions.section ?
                                                                <>
                                                                    <td>
                                                                        Mat<br />
                                                                        <span className="digit-bold">{resService.total.mat}</span>
                                                                    </td>
                                                                    <td>
                                                                        Elem<br />
                                                                        <span className="digit-bold">{resService.total.elem}</span>
                                                                    </td>
                                                                    <td>
                                                                        Total<br />
                                                                        <span className="digit-bold">{resService.total.total}</span>
                                                                    </td>
                                                                </> :
                                                                <td>{resService.total.total}</td>
                                                            }
                                                        </tr>
                                                )))}
                                                </tbody>
                                            </table>
                                        </td>
                                    ))}

                                    {/* Total vertical */}
                                    <td className="has-border-left has-border-bottom">
                                        <table className="nested-table-stats">
                                            <tbody>
                                                <tr>
                                                    {ventilActions.section ?
                                                        <>
                                                            <td>
                                                                Mat<br/>
                                                                <span className="digit-bold">{type.total.mat}</span>
                                                            </td>
                                                            <td>
                                                                Elem<br/>
                                                                <span className="digit-bold">{type.total.elem}</span>
                                                            </td>
                                                            <td>
                                                                Total<br/>
                                                                <span className="digit-bold">{type.total.total}</span>
                                                            </td>
                                                        </> :
                                                        <td>{type.total.total}</td>
                                                    }
                                                </tr>

                                                {ventilActions.prestations && type.prestations.map(presta => (
                                                    <tr key={"presta-" + presta.id}>
                                                        {ventilActions.section ?
                                                            <>
                                                                <td>
                                                                    Mat<br/>
                                                                    <span className="digit-bold">{presta.total.mat}</span>
                                                                </td>
                                                                <td>
                                                                    Elem<br/>
                                                                    <span className="digit-bold">{presta.total.elem}</span>
                                                                </td>
                                                                <td>
                                                                    Total<br/>
                                                                    <span className="digit-bold">{presta.total.total}</span>
                                                                </td>
                                                            </> :
                                                            <td>{presta.total.total}</td>
                                                        }
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            ))}
                        </tbody>

                        <tfoot>
                            <tr className="total">
                                {/* Total des services */}
                                <td>Total</td>
                                {filterModeSelect === "services" &&
                                totalStats.services.map(service => (
                                    <td className="has-border-left" key={"totService-" + service.id}>
                                        {ventilActions.section ?
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            Mat<br />
                                                            <span className="digit-bold">{service.total.mat}</span>
                                                        </td>
                                                        <td>
                                                            Elem<br />
                                                            <span className="digit-bold">{service.total.elem}</span>
                                                        </td>
                                                        <td>
                                                            Total<br />
                                                            <span className="digit-bold">{service.total.total}</span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table> :
                                            <span className="digit-bold">{service.total.total}</span>
                                        }
                                    </td>
                                ))}
                                {/* Total des groupes */}
                                {filterModeSelect === "groupes" &&
                                totalStats.groupes.map(group => (
                                    <td className="has-border-left" key={"totGroupe-" + group.id}>
                                        {ventilActions.section ?
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            Mat<br />
                                                            <span className="digit-bold">{group.total.mat}</span>
                                                        </td>
                                                        <td>
                                                            Elem<br />
                                                            <span className="digit-bold">{group.total.elem}</span>
                                                        </td>
                                                        <td>
                                                            Total<br />
                                                            <span className="digit-bold">{group.total.total}</span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table> :
                                            <span className="digit-bold">{group.total.total}</span>
                                        }
                                    </td>
                                ))}
                                {/* Total général */}
                                <td className="has-border-left">
                                    <table>
                                        <tbody>
                                            <tr>
                                                {ventilActions.section ?
                                                    <>
                                                        <td>
                                                            Mat<br/>
                                                            <span className="digit-bold">{totalStats.total.mat}</span>
                                                        </td>
                                                        <td>
                                                            Elem<br />
                                                            <span className="digit-bold">{totalStats.total.elem}</span>
                                                        </td>
                                                        <td>
                                                            Total<br />
                                                            <span className="digit-bold">{totalStats.total.total}</span>
                                                        </td>
                                                    </> :
                                                    <td>
                                                        <span className="digit-bold">{totalStats.total.total}</span>
                                                    </td>
                                                }
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </section>
            }

        </div>

        <div className="form__btns">
            <button className="btn btn--light btn--link" onClick={hideStats}>
                Fermer
            </button>
        </div>
    </Modal>
};

export default connect(({ ShowModal, Child, Pointage }) => ({
    showStats: ShowModal.showStats,
    Child: Child,
    ChildGroupe: Child.Groupe,
    Pointage: Pointage,
    momentID: Pointage.momentID,
}))(
    React.memo(Stats, (oldProps, newProps) => {
        if (
            oldProps.showStats !== newProps.showStats
        ) {
            return false;
        }
        return true;
    })
)
