import React from "react";
import { connect } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import LoginForm from "./LoginForm";
import PinSetForm from "./SetPinForm";
import LogWithPin from "./LogWithPin";
import CodePinOublierForm from "./CodePinOublier";
import YouAreNot from "./YouAreNot";
import Spinner from "../spinner";
const Login = ({ Auth }) => {
  return (
    <Route path="/login">
      {Auth.Access && (
        <Redirect
          to={{
            pathname:
              Auth.show.Query.location !== null
                ? Auth.show.Query.location
                : "/",
            search: "?from=login",
          }}
        />
      )}
      <div className="grid grid--large login">
        <main className="grid__row login__wrapper">
          {Auth.show.to === "Login" && <LoginForm />}
          {Auth.show.to === "SetPin" && <PinSetForm />}
          {Auth.show.to === "ForgotPin" && <CodePinOublierForm />}
          {Auth.show.to === "YouAreNot" && <YouAreNot />}
          {!Auth.Access && Auth.PinSet && Auth.show.to === "LogWithPin" && (
            <LogWithPin />
          )}
          {Auth.show.to === null && <Spinner />}
        </main>
      </div>
    </Route>
  );
};

export default connect(({ Auth }) => ({ Auth }))(Login);
