import { TokenHandler, GetMealServices } from "../helper";
/**
 * * Function that return the MealServices Table
 * @param {DexieDb} db
 * @returns {MealServicesTable}
 */
export const getMealServicesTable = (db) =>
  db.getDB().table(db.getTableName().MealServices);

export const clearMealServices = (db) => db.clearTable(db.getTableName().MealServices);

export const getAllMealServices = (db) => getMealServicesTable(db).toArray();

export const TableMealServicesFiller = (db, dispatch, StepDone) => {
  return GetMealServices(TokenHandler.getToken()).then((client) => {
    return client
      .App_Center_API_Controllers_MealServicesController__index()
      .then((res) => res.body.data)
      .then((res) => {
        return db.insertLotsOfData(res, db.getTableName().MealServices).then(() =>
          dispatch(
            StepDone({
              key: "Meal Services",
              action: "Récupération des services de restauration",
              end: true,
              size: res.length,
            })
          )
        );
      })
    .catch((err) => console.log(err))
  }
    
  );
};


export const MealServicesHandler = {
  getAllMealServices,
  TableMealServicesFiller,
  clearMealServices,
};
