import React from "react";
import { updatePresence } from "../../../actions/Database/Resa";
import { connect } from "react-redux";
import Presta from "./resaPointage/resaPresta";
import ResaButton from "./resaPointage/resaButton";
import SetPresenceHour from "./resaPointage/SetPresenceHour";
import PrestaSecondaire from "./prestaSecondaire";
import { useLocationId } from "../../../helper/useLocationId";
import MotifPicker from "./resaPointage/MotifPicker";

const ResaPointage = ({ dispatch, childId, prestation_id, resaId, resa, presta }) => {

  const [locationId] = useLocationId();
  const changePresta = (e) =>
    dispatch(
      updatePresence({ presenceId: e.currentTarget.id, resaId: resaId })
    );

  const presences = [
    {
      id: "1",
      text: "Présent",
      color: "success",
      colorCondition: resa.is_present === 1,
    },
    {
      id: "0",
      text: "Absent",
      color: "error",
      colorCondition: resa.is_present === 0,
    },
    {
      id: "2",
      text: "Non pointé",
      color: "base",
      colorCondition: resa.is_present === null,
    },
  ];
  return (
    <>
      <div
        className={
          ([6, 10, 8].some((Id) => locationId.includes(Id)) &&
            "child__pointage") ||
          null
        }
      >
        {![6, 10, 8].some((Id) => locationId.includes(Id)) && (
          <div className="child__resa">
            <Presta childId={childId} prestation_id={prestation_id} resa={resa}/>
          </div>
        )}
        <div className="child__resabody">
          <div className="child__presence">
            {presences.map((presence) => (
              <ResaButton
                key={presence.id}
                {...presence}
                changePresta={changePresta}
              />
            ))}
          </div>
          <PrestaSecondaire className="child__prestasec" childId={childId} mainPresta={presta}/>
          <div className="child__hours">
            {resa.is_present === 1 &&
              ![6].some((Id) => locationId.includes(Id)) &&
              !presta.autofill_hours && (
                <SetPresenceHour childId={childId} resaId={resaId} />
              )}

            {resa.is_present === 0 && <MotifPicker resaId={resaId} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(({ Pointage }, { resaId }) => ({
  resa: Pointage.Reservation.find((reservation) => reservation.id === resaId),
  presta: Pointage.Prestations.find((presta) => presta.id === Pointage.Reservation.find((reservation) => reservation.id === resaId).prestation_id ),
}))(ResaPointage);
