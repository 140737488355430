import React from "react";
import { connect } from "react-redux";
import Modal from "../modal/Modal";
import {
  dontWantInstall,
  setShowInstallPrompt,
  validateInstall,
} from "../../actions/serviceWorker";
const ModalInstall = ({ showInstallPrompt, dispatch }) => {
  return (
    <Modal
      show={showInstallPrompt}
      hide={() => dispatch(setShowInstallPrompt(false))}
      ComponentHeader={() => (
        <p>
          Voulez-vous installer l'application{" "}
          {String(process.env.REACT_APP_LABEL).toUpperCase()} ?
        </p>
      )}
    >
      <p>
        Cette application ne prend que quelques secondes à installer et ne
        nécessite aucun store. Elle vous permettra d'avoir une meilleure
        expérience avec notre solution Pirouette.
      </p>
      <div className="form__btns">
        <button className="btn btn--secondary" onClick={() => dispatch(validateInstall())}>
          Installer l'application
        </button>
        <button className="btn btn--error btn--link" onClick={() => dispatch(dontWantInstall())}>
          Je ne veux pas installer l'application
        </button>
      </div>
    </Modal>
  );
};

export default connect((state) => state.serviceWorker)(ModalInstall);
