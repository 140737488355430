import { useEffect, useState } from "react";
import { setFilter } from "../../../actions/Database/Pointage";

export const FormRadio = ({
    dispatch,
    name,
    id = name,
    children,
    category,
    categoryId,
    isActive
}) => {
    const [isCheck, setIsCheck] = useState(isActive);
    useEffect(() => {
        setIsCheck(isActive);
    }, [isActive]);
    return (
        <label htmlFor={id} className="form-check">
            <input className="form-radio" checked={isCheck} type="radio" onChange={(e) => {
                setIsCheck(!isCheck)
                dispatch(
                    setFilter({
                        categorie: category,
                        checked: !isCheck,
                        id: categoryId,
                    })
                )
            }} name={name} id={id} />
            <span>{children}</span>
        </label>
    );
};
