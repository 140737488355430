import React, { useState } from "react";
import { connect } from "react-redux";
import { addReservation } from "../../../actions/Database/Resa";
import { formatTime } from "../../../helper/date";
import Modal from "../../modal/Modal";
const AddResaModal = ({
  addResa,
  resaData,
  hideResa,
  child,
  date,
  dispatch,
}) => {
  const [prestation, setPrestation] = useState(-1);
  if (child === undefined) return <div></div>;
  return (
    <Modal show={addResa} hide={hideResa} label="Ajout d'une prestation">
      <div className="modal--newresa">
        <p className="withMargin">
          Sélectionnez la prestation à ajouter pour{" "}
          <strong>
            {child.first_name} {child.last_name}
          </strong>{" "}
          le <strong>{new Date(date).toLocaleDateString()}</strong> :
        </p>

        <ul className="list--unstyled">
          {resaData.prestaAvailable.map((presta) => (
            <li className="list__item" key={presta.id}>
              <label>
                <input
                  type="radio"
                  name="prestaAddResa"
                  value={presta.id}
                  onClick={(e) => setPrestation(e.currentTarget.value)}
                />
                <strong>{presta.name}</strong>{" "}
                <p>de {formatTime(presta.start_time)} à {formatTime(presta.end_time)}</p>
              </label>
            </li>
          ))}
        </ul>

        <div className="form__btns">
          <button
            className="btn btn--secondary"
            disabled={prestation === -1}
            onClick={() =>
              dispatch(
                addReservation({ childId: child.id, prestaId: prestation })
              ).then(() => {
                hideResa();
                setPrestation(-1);
              })
            }
          >
            Ajouter la prestation
          </button>
          <button className="btn btn--light btn--link" onClick={hideResa}>
            Annuler
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default connect(({ ShowModal, Pointage, Child }) => ({
  child: Child.filteredChild.find(
    (childToTreat) => childToTreat.id === ShowModal.resaData.childId
  ),
  date: Pointage.date,
  addResa: ShowModal.addResa,
  resaData: ShowModal.resaData,
}))(
  React.memo(AddResaModal, (oldProps, newProps) => {
    if (oldProps.addResa !== newProps.addResa || newProps.addResa) return false;
    return true;
  })
);
