import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import "./style/master.scss";
import App from "./components/App";
import ErrorHandler from "./components/ErrorHandler";
import store from "./reducer";
import { registerWorker } from "./actions/serviceWorker";

store.dispatch(registerWorker());

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <ErrorHandler>
          <App />
        </ErrorHandler>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
