import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { showGoToPWa as functionShowGoToPwa } from "../../actions/serviceWorker";
import Modal from "../modal/Modal";
import { hideInfo } from "../../actions/showModal";
const GotToPWA = ({ showGoToPWa, dispatch }) => {
  const OnClick = () => {
    dispatch(functionShowGoToPwa(false));
    dispatch(hideInfo());
  };
  return (
    <Modal
      show={showGoToPWa}
      hide={OnClick}
      ComponentHeader={() => (
        <p>
          Vous avez installé {String(process.env.REACT_APP_LABEL).toUpperCase()}
        </p>
      )}
    >
      <p className="withMargin color--primary">
        <strong>
          Vous avez installé {String(process.env.REACT_APP_LABEL).toUpperCase()}
        </strong>
      </p>
      <p className="withMargin">
        Il est recommandé d'utiliser l'application Pirouette que vous avez
        précédemment installée.
      </p>
      <div className="flex">
        <Link onClick={OnClick} to="/pwainstall" className="btn btn--secondary">
          OK
        </Link>
      </div>
    </Modal>
  );
};

export default connect((state) => state.serviceWorker)(GotToPWA);
