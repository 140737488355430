/*
 *  Permet de gérer les données locales non encodées
 */
const SESSIONKEY = "SESSIONUserLocal";
export const getSession = () => JSON.parse(localStorage.getItem(SESSIONKEY));
export const setSession = (session) =>
  localStorage.setItem(SESSIONKEY, JSON.stringify(session));
export const ResetSession = () => localStorage.setItem(SESSIONKEY, "");

export const getLogoCentre = () => getSession().CentreImage;
export const setLogoCentre = (Logo) =>
  setSession({ ...getSession(), CentreImage: Logo });

export const getNomCentre = () => getSession().NomCentre;
export const setNomCentre = (nom) =>
  setSession({ ...getSession(), NomCentre: nom });

export const getNameUser = () => getSession().Name;
export const setNameUser = (name) =>
  setSession({ ...getSession(), Name: name });

export const getIdUser = () => getSession().id;
export const setIdUser = (name) => setSession({ ...getSession(), id: name });

export const getUserSurname = () => getSession().usersurname;
export const setUserSurname = (surname) =>
  setSession({ ...getSession(), usersurname: surname });

export const getUserName = () => getSession().username;
export const setUserName = (surname) =>
  setSession({ ...getSession(), username: surname });

export const getPermissionLevel = () => getSession().permissionLevel;
export const setPermissionLevel = (permissionLevel) =>
  setSession({ ...getSession(), permissionLevel: permissionLevel });

export const getPrestations = () => getSession().Prestations;
export const setPrestations = (Prestations) =>
  setSession({ ...getSession(), Prestations: Prestations });

export const getPrestationsMap = () => getSession().PrestationsMap;
export const setPrestationsMap = (Prestations) =>
  setSession({ ...getSession(), PrestationsMap: Prestations });

export const getRootPeriodBefore = () => getSession().RootPeriodBefore;
export const setRootPeriodBefore = (rootParam) =>
  setSession({ ...getSession(), RootPeriodBefore: rootParam });

export const setRootPeriodAfter = (rootParam) =>
  setSession({ ...getSession(), RootPeriodAfter: rootParam });
export const getRootPeriodAfter = () => getSession().RootPeriodAfter;

export const setRootRecalculPresta = (rootParam) =>
  setSession({ ...getSession(), RootRecalculPresta: rootParam});
export const getRootRecalculPresta = () => getSession().RootRecalculPresta;

export const prestationsParser = () => ({
  1: {
    Label: prestationTypeParser()[1],
    url: "periscolaire",
    id: [1],
    svgIcon: "school",
    homeIcon: true,
    order: 1,
  },
  2: {
    Label: prestationTypeParser()[3],
    url: "extrascolaire",
    id: [3, 4],
    svgIcon: "island",
    homeIcon: true,
    order: 4,
  },
  3: {
    Label: prestationTypeParser()[5],
    url: "restauration-scolaire",
    id: [5],
    svgIcon: "spoonfork",
    homeIcon: true,
    order: 3,
  },
  4: {
    Label: prestationTypeParser()[6],
    url: "sejour",
    id: [6],
    svgIcon: "bed",
    homeIcon: true,
    middleScreen: true,
    order: 6,
  },
  5: {
    Label: prestationTypeParser()[7],
    url: "journee-speciale",
    id: [7],
    svgIcon: "star",
    homeIcon: false,
    order: 5,
  },
  6: {
    Label: prestationTypeParser()[8],
    url: "evenement",
    id: [8],
    svgIcon: "cheers",
    homeIcon: true,
    middleScreen: true,
    order: 7,
  },
  7: {
    Label: prestationTypeParser()[9],
    url: "ado",
    id: [9],
    svgIcon: "people-roof",
    homeIcon: true,
    order: 9,
  },
  8: {
    Label: prestationTypeParser()[10],
    url: "atelier",
    id: [10],
    svgIcon: "gamepad",
    homeIcon: true,
    middleScreen: true,
    order: 8,
  },
  9: {
    Label: prestationTypeParser()[2],
    url: "mercredi",
    id: [2],
    svgIcon: "playground",
    homeIcon: true,
    order: 2,
  },
});

export const prestationTypeParser = () => ({
  1: "Périscolaire",
  2: "Mercredi",
  3: "Extrascolaire",
  4: "Samedi",
  5: "Restauration",
  6: "Séjours",
  7: "Activités Spéciales",
  8: "Évènements",
  9: "Adolescents",
  10: "Ateliers",
});

export const serviceTypeParser = () => ({
  1: { Label: prestationTypeParser()[1] },    // Périsco
  2: { Label: prestationTypeParser()[2] },    // Mercredi
  3: { Label: "PetitesVac" },
  4: { Label: "GrandesVac" },
  7: { Label: prestationTypeParser()[5] },    // Cantine
  8: { Label: prestationTypeParser()[4] },    // Samedis (non utilisé)
  9: { Label: prestationTypeParser()[9] },    // Ados
});

export const momentParser = () => ({
  1: {
    label: "Journée entière"
  },
  2: {
    label: "Matin",
    operand: (today) => today.getHours() <= 11
  },
  3: {
    label: "Pause méridienne",
    operand: (today) => today.getHours() > 11 && today.getHours() < 14,
  },
  4: {
    label: "Après-midi",
    operand: (today) => today.getHours() > 14 && today.getHours() < 16,
  },
  5: {
    label: "Soir",
    operand: (today) => today.getHours() >= 16
  },
});

export const hasSession = () =>
  localStorage.getItem(SESSIONKEY) !== null &&
  localStorage.getItem(SESSIONKEY) !== "";
export const SessionHandler = {
  getSession,
  setSession,
  ResetSession,
  setLogoCentre,
  getLogoCentre,
  getNomCentre,
  setNomCentre,
  getNameUser,
  setNameUser,
  getUserSurname,
  setUserSurname,
  hasSession,
  setIdUser,
  getIdUser,
  setPermissionLevel,
  getPermissionLevel,
  setUserName,
  getUserName,
  getPrestations,
  setPrestations,
  prestationsParser,
  prestationTypeParser,
  getPrestationsMap,
  setPrestationsMap,
  momentParser,
  setRootPeriodAfter,
  getRootPeriodAfter,
  getRootPeriodBefore,
  setRootPeriodBefore,
  getRootRecalculPresta,
  setRootRecalculPresta,
};
