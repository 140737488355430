import React from "react";
import { Image } from "react-bootstrap";
import { connect } from "react-redux";
import { slide as Menu } from "react-burger-menu";
import BurgerItem from "./burger/burgerMenuItem";
import {
  showInfo,
  showSynchro,
  showReset,
  showChangePin,
  showLock,
  showClearData,
  hideBurger,
} from "../../actions/showModal";

var menuItem = (dispatch) => [
  { Label: "Pointage", to: "/pointage", icon: "list-check", link: true },
  //  {
  //    Label: "Pointages manquants",
  //    to: "/",
  //    icon: "category",
  //    link: true,
  //  },
  { Label: "Dossiers famille", to: "/dossier", icon: "child", link: true },
  { Label: "Anniversaires", to: "/birthday", icon: "cake", link: true },
  {
    Label: "Synchroniser",
    to: "/",
    icon: "cloud-upload",
    link: false,
    action: () => dispatch(showSynchro()),
  },
  //  { Label: "Statistiques", to: "/stat", icon: "bar-chart", link: true },
  {
    Label: "Informations",
    to: "/",
    icon: "info-circle",
    link: false,
    action: () => dispatch(showInfo()),
  },
  {
    Label: "Verrouillage",
    to: "/",
    icon: "log-out",
    link: false,
    action: () => dispatch(showLock()),
  },
  {
    Label: "Changer le code PIN",
    to: "/",
    icon: "dialpad",
    link: false,
    action: () => dispatch(showChangePin()),
    separator: "true",
  },
  {
    Label: "Remise à zéro",
    to: "/",
    icon: "reset",
    link: false,
    action: () => dispatch(showReset()),
  },
  {
    Label: "Effacer toutes les données",
    to: "/",
    icon: "bin2",
    link: false,
    action: () => dispatch(showClearData()),
  }
];

const Burger = ({ menuVisible, SessionData, dispatch }) => {
  const menuHide = () => dispatch(hideBurger());
  return (
    <Menu
      right
      isOpen={menuVisible}
      onClose={menuHide}
      customBurgerIcon={false}
    >
      <div className="bm__header">
        <div className="bm__header-wrapper">
          Connecté en tant que :{" "}
          <span>
            {SessionData.Name} {SessionData.usersurname}
          </span>
          <Image
            src={
              SessionData.CentreImage
                ? SessionData.CentreImage
                : process.env.PUBLIC_URL + "/pirouette.png"
            }
          />
        </div>
      </div>
      <div className="bm__links">
        {menuItem(dispatch).map((value) => {
          return (
            <BurgerItem
              menuHide={menuHide}
              {...value}
              className={`burgerItem ${value.separator && "burgerItem--separator"}`}
              key={value.Label}
            />
          );
        })}
      </div>
    </Menu>
  );
};

export default connect(({ Auth, ShowModal }) => ({
  SessionData: Auth.SessionData,
  menuVisible: ShowModal.burger,
}))(
  React.memo(Burger, (oldProps, newProps) => {
    if (oldProps.menuVisible !== newProps.menuVisible || newProps.menuVisible)
      return false;
    return true;
  })
);
