import React from "react";
import { connect } from "react-redux";

const PointageNonPointe = () => {
  return (
    <div>
      <p>à venir.</p>
    </div>
  );
};

export default connect()(PointageNonPointe);
