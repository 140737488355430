import React from "react";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import { HideNotification } from "../actions/FillTheDatabase";
const TaskNotification = ({ Progress, dispatch, Synchro }) => {
  return (
    <div className="DivWhoContainTheNotificationPart">
      <Modal
        show={Progress.showActionInProgress}
        animation={false}
        onHide={() =>
          console.warn(
            "Vous ne pouvez pas quitter cette fenêtre de cette manière"
          )
        }
        dialogClassName="ChildModalContainer"
      >
        <Modal.Header>
          <Modal.Title>Synchronisation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {Object.keys(Progress.Status).length === 0 && (
            <div>
              <div>
                <strong>
                  Veuillez patienter, l'application envoie vos modifications et
                  récupère les informations du serveur.
                </strong>
              </div>
            </div>
          )}
          <div
            className={`progress ${Progress.error ? "progress--error" : ""} ${!Progress.error && (!Synchro.Pending && !Progress.pending ? "progress--success" : "")}`}
          >
            <div className="progress__container">
              <label>
                {Progress.error && "Erreur lors de la synchronisation"}
                {!Progress.error &&
                  (!Synchro.Pending && !Progress.pending
                    ? "Synchronisation effectuée"
                    : "Synchronisation en cours...")}
              </label>
            </div>
            <div
              className="progress__bar"
              style={{
                width: `${
                  (Progress.finished / Progress.nbrAction) * 100
                }%`,
              }}
            ></div>
          </div>

          {!Synchro.Pending && !Progress.pending && (
            <div className="progress__info">
              Durée : {Synchro.duration / 1000}s
            </div>
          )}

          {!Progress.pending && !Synchro.Pending && (
            <>
              <div className="form__btns">
                <button
                  className="btn btn--success btn--large"
                  onClick={() => dispatch(HideNotification())}
                >
                  Continuer
                </button>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default connect(({ Progress, Synchro }) => ({ Progress, Synchro }))(
  TaskNotification
);
