import AES from "crypto-js/aes";
import enc from "crypto-js/enc-utf8";
export const CRYPTKEY = "crypTKey";
export const decryptData = (data, key) =>
  AES.decrypt(data.toString(), key.toString()).toString(enc);
export const cryptData = (data, key) =>
  AES.encrypt(data.toString(), key.toString()).toString();
export const setCryptKey = (data, pin) =>
  localStorage.setItem(CRYPTKEY, cryptData(data, pin));
export const getCryptKey = (pin) =>
  decryptData(localStorage.getItem(CRYPTKEY), pin);
export const resetCryptKey = () => localStorage.setItem(CRYPTKEY, "");
export const PinCorrect = (pin) => {
  try {
    return getCryptKey(pin) !== "";
  } catch (e) {
    return false;
  }
};
export const cryptKeyExist = () =>
  localStorage.getItem(CRYPTKEY) !== null &&
  localStorage.getItem(CRYPTKEY) !== "";
export const StoreDecryptedKey = (pin) =>
  sessionStorage.setItem(CRYPTKEY, getCryptKey(pin));
export const getSessionDecryptedKey = () => sessionStorage.getItem(CRYPTKEY);
export const hasSessionDecryptedKey = () => getSessionDecryptedKey() !== "";
export const resetSessionDecryptedKey = () =>
  sessionStorage.setItem(CRYPTKEY, "");
export const hasCryptedKey = () =>
  localStorage.getItem(CRYPTKEY) !== null &&
  localStorage.getItem(CRYPTKEY) !== "";
export const crypt = {
  decryptData,
  cryptData,
  setCryptKey,
  getCryptKey,
  StoreDecryptedKey,
  getSessionDecryptedKey,
  PinCorrect,
  hasSessionDecryptedKey,
  hasCryptedKey,
  resetSessionDecryptedKey,
  resetCryptKey,
};
