import React from "react";

const resaButton = ({
  id,
  text,
  color,
  colorCondition,
  changePresta,
  classNameAlt,
  className,
}) => {
  return (
    <button
      id={id}
      onClick={changePresta}
      className={`btn ${
        colorCondition ? ` btn--${color}` : classNameAlt || ""
      } ${className || ""} `}
    >
      {text}
    </button>
  );
};

export default resaButton;
