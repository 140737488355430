import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router";
import { setDate, setIdDate, setPrestationsId } from "../../../actions/Database/Pointage";
import {
  howMuchDayBetween,
  incrementDate,
  selectDate,
  setDateToHourZero,
} from "../../../helper/date";
import { useLocationId } from "../../../helper/useLocationId";
import PrestaSelector from "./PrestaSelector";
import _, { split } from "lodash";




const SelectMultiDate = ({ Prestations, dispatch, date, idDate }) => {
  const location = useLocation();
  const [locationId, locationTag] = useLocationId();
  useEffect(() => {
    if ([6, 10].some((value) => locationId.includes(value)) && idDate === -1) {
      new Promise((resolve, reject) => {
        if (Prestations.length === 0)
          dispatch(
            setPrestationsId({
              location: locationTag,
              init: true,
            })
          ).then((result) => {
            return resolve(result.payload.payload.prestas);
          });
        else return resolve(Prestations);
      }).then((prestations) => {
        var presta = prestations.find(
          ({ id }) => String(id) === String(location.pathname.split("/")[3])
        );
        if (presta !== undefined) {
          var selectDateRes = selectDate(presta);
          dispatch(
            setIdDate(
                (selectDateRes.idDate !== null)
                  ? selectDateRes.idDate
                  : (presta.possible_dates.length !== 0
                    ? presta.possible_dates.findIndex(
                        (possible) =>
                          setDateToHourZero(possible.date).getTime() ===
                          setDateToHourZero(selectDateRes.date).getTime()
                      )
                    : howMuchDayBetween(
                        presta.start_date,
                        setDateToHourZero(selectDateRes.date)
                      ) !== 0
                    ? Math.round(
                        howMuchDayBetween(
                          presta.start_date,
                          setDateToHourZero(selectDateRes.date)
                        )
                      )
                    : 0)
          ))
          dispatch(
            setDate({
              date: selectDateRes.date,
              pending: false,
              dateId:
                (selectDateRes.idDate !== null)
                ? selectDateRes.idDate
                : (presta.possible_dates.length !== 0
                    ? presta.possible_dates.findIndex(
                        (possible) =>
                          setDateToHourZero(possible.date).getTime() ===
                          setDateToHourZero(selectDateRes.date).getTime()
                      )
                    : howMuchDayBetween(
                        presta.start_date,
                        setDateToHourZero(selectDateRes.date)
                      ) !== 0
                    ? Math.round(
                        howMuchDayBetween(
                          presta.start_date,
                          setDateToHourZero(selectDateRes.date)
                        )
                      )
                    : 0),
                prestaDateId: selectDateRes.date.id,
            })
          );
        }
      });
    }
    // eslint-disable-next-line
  }, [idDate, locationId]);
  if (
    ![6, 10].some((value) => locationId.includes(value)) ||
    location.pathname.split("/").length !== 4
  )
    return <></>;
  var PrestaActuelle = Prestations.find(
    (prestaToTreat) =>
      String(prestaToTreat.id) === String(location.pathname.split("/")[3])
  );

  function sortByDateAndTime(dateTimeA, dateTimeB) {
    let temp_return = 0;
    if (dateTimeA.end_time < dateTimeB.end_time) {
      temp_return = -1;
    } else {
      temp_return = 1;
    }
    if (dateTimeA.start_time !== dateTimeB.start_time) {
      if (dateTimeA.start_time < dateTimeB.start_time) {
        temp_return = -1;
      } else {
        temp_return = 1;
      }
    }
    if (dateTimeA.date !== dateTimeB.date) {
      if (dateTimeA.date < dateTimeB.date) {
        temp_return = -1;
      } else {
        temp_return = 1;
      }
    }
    return temp_return;
  }

  function displaySchedule(firstHour, lastHour) {
    let txt = " de ";
    let hour_temp = null;
    // eslint-disable-next-line
    if (new Date(firstHour) == "Invalid Date") {
      hour_temp = split(firstHour, ':');
      firstHour = hour_temp[0] + "h" + hour_temp[1];
    } else {
      firstHour = new Date(firstHour).getHours() - 2 + "h" +
        (new Date(firstHour).getMinutes() < 10 ? "0" : "") +
        new Date(firstHour).getMinutes();
    }
    txt += " " + firstHour + " à ";
    // eslint-disable-next-line
    if (new Date(lastHour) == "Invalid Date") {
      hour_temp = split(lastHour, ':');
      lastHour = hour_temp[0] + "h" + hour_temp[1];
    } else {
      lastHour = new Date(lastHour).getHours() - 2 + "h" +
        (new Date(lastHour).getMinutes() < 10 ? "0" : "") +
        new Date(lastHour).getMinutes();
    }
    if (lastHour === "00h00" && firstHour === "00h00") {
      return ""
    }
    return txt + lastHour;
  }

  if (PrestaActuelle === undefined) return <> </>;
  let possible_dates = _.cloneDeep(PrestaActuelle.possible_dates);
  if ([10].some((value) => locationId.includes(value))) {
    possible_dates = possible_dates
      .map(date => {
        if (date.start_time == null || date.end_time == null) {
          date.start_time = PrestaActuelle.start_time;
          date.end_time = PrestaActuelle.end_time;
        }
        return date;
      })
      .sort(function (dateTimeA, dateTimeB) {
      return sortByDateAndTime(dateTimeA, dateTimeB)
    })
  }
  return (
    <>
      <PrestaSelector />
      <div className="dateSelector">
        <button
          className="btn btn--primary"
          disabled={idDate === 0}
          onClick={() => {
            dispatch(
              setDate({
                date:
                  idDate >= 0
                    ? possible_dates.length !== 0
                      ? possible_dates[idDate - 1].date
                      : incrementDate(
                          new Date(PrestaActuelle.start_date),
                          idDate - 1
                        )
                    : date,
                dateId: idDate - 1 >= 0 ? idDate - 1 : idDate,
              })
            );
          }}
        >
          &#8592;
        </button>
        <select
          value={idDate}
          onChange={(e) => {
            if (
              setDateToHourZero(
                possible_dates.length !== 0
                  ? possible_dates[e.currentTarget.value].date
                  : incrementDate(
                      new Date(PrestaActuelle.start_date),
                      Number(e.currentTarget.value)
                    )
              ).getTime() !== setDateToHourZero(date).getTime() ||
                [10].some((value) => locationId.includes(value))
            ) {
              dispatch(
                setDate({
                  date: possible_dates.length > 0 ?
                    possible_dates[e.currentTarget.value].date :
                    incrementDate(
                      new Date(PrestaActuelle.start_date),
                      Number(e.currentTarget.value)
                    ),
                  dateId: e.currentTarget.value,
                  prestaDateId: possible_dates.length > 0 ?
                    possible_dates[e.currentTarget.value].id :
                    null,
                })
              );
            }
          }}
        >
          {possible_dates.length > 0 &&
            possible_dates.map((prestaToTreat, index) => (
              <option key={prestaToTreat.id} value={index}>
                {
                  [10].some((value) => locationId.includes(value)) ?
                  (
                    new Date(prestaToTreat.date).toLocaleDateString() +
                    displaySchedule(prestaToTreat.start_time, prestaToTreat.end_time)
                  ) : new Date(prestaToTreat.date).toLocaleDateString()
                }
              </option>
            ))}
          {possible_dates.length === 0 &&
            [
              ...Array(
                Math.floor(
                  howMuchDayBetween(
                    PrestaActuelle.start_date,
                    PrestaActuelle.end_date
                  ) + 1
                )
              ).keys(),
            ].map((increment) => (
              <option key={increment} value={increment}>
                {incrementDate(
                  new Date(PrestaActuelle.start_date),
                  increment
                ).toLocaleDateString()}
              </option>
            ))}
        </select>
        <button
          className="btn btn--primary"
          disabled={
            idDate ===
            (possible_dates.length !== 0
              ? possible_dates.length - 1
              : howMuchDayBetween(
                  PrestaActuelle.start_date,
                  PrestaActuelle.end_date
                ))
          }
          onClick={() => {
            dispatch(
              setDate({
                date:
                  idDate + 1 <=
                  (possible_dates.length !== 0
                    ? possible_dates.length - 1
                    : howMuchDayBetween(
                        PrestaActuelle.start_date,
                        PrestaActuelle.end_date
                      ))
                    ? possible_dates.length !== 0
                      ? possible_dates[idDate + 1].date
                      : incrementDate(
                          new Date(PrestaActuelle.start_date),
                          idDate + 1
                        )
                    : date,
                dateId:
                  idDate + 1 <=
                  (possible_dates.length !== 0
                    ? possible_dates.length - 1
                    : howMuchDayBetween(
                        PrestaActuelle.start_date,
                        PrestaActuelle.end_date
                      ))
                    ? idDate + 1
                    : idDate,
              })
            );
          }}
        >
          &#8594;
        </button>
      </div>
    </>
  );
};

export default connect(({ Pointage }) => ({
  prestationsType: Pointage.prestationsType,
  Prestations: Pointage.Prestations,
  date: Pointage.date,
  idDate: Pointage.idDate,
}))(SelectMultiDate);
