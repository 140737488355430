import React from "react";
import { connect } from "react-redux";
import { Login } from "../../actions/Auth/Login";
import ChampLogin from "./ChampLogin";
import { hasDeviceName } from "../../helper/uuid";
import { isOnline } from "../../helper";

function LoginForm({ toDispatch, Auth, Action, ...props }) {
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [deviceName, setDeviceName] = React.useState("");

  const [usernameError, setUsernameError] = React.useState(false);
  const [passwordError, setPasswordError] = React.useState(false);
  const [deviceNameError, setDeviceNameError] = React.useState(false);

  const [error, setError] = React.useState("");

  const HandleSubmit = async (e) => {
    e.preventDefault();
    if (
      username === "" ||
      password === "" ||
      (!hasDeviceName() && (deviceName === "" || deviceName === " "))
    ) {
      setError(`Un ou plusieurs champs sont vides.`);
    } else {
      setError("");
      var action = toDispatch === undefined ? Login : toDispatch;
      props.dispatch(action({ username, password, deviceName }));
    }
  };
  return (
    <>
      <div className="grid__col6">
        <img
          src={process.env.PUBLIC_URL + "/pirouette.png"}
          alt="Logo Pirouette"
          className="login__logo"
        />
        <p className="login__version">v{process.env.REACT_APP_VERSION}</p>
      </div>

      <form onSubmit={HandleSubmit} className="grid__col6">

        {!isOnline() && <p className="color--error">Attention, vous êtes hors-ligne, vous ne pouvez pas vous connecter.</p>}

        <ChampLogin
          id="username"
          name="Nom d'utilisateur"
          type="text"
          value={username}
          setValue={setUsername}
          error={usernameError}
          setError={setUsernameError}
          classN=""
          required
        />

        <ChampLogin
          id="password"
          name="Mot de passe"
          type="password"
          value={password}
          setValue={setPassword}
          error={passwordError}
          setError={setPasswordError}
          classN=""
          required
        />

        {!hasDeviceName() && (
          <ChampLogin
            id="devicename"
            name="Nom de l'appareil"
            type="text"
            value={deviceName}
            setValue={setDeviceName}
            error={deviceNameError}
            setError={setDeviceNameError}
            classN=""
            required
          />
        )}

        <span className="form--errormsg">
          {error}
          {Auth.Error === 401
            ? "Les identifiants sont incorrects"
            : Auth.Error === 0
            ? Auth.ErrorContent !== null &&
              Auth.ErrorContent !== undefined &&
              Auth.ErrorContent.hasOwnProperty("message")
              ? Auth.ErrorContent.message
              : Auth.ErrorContent
            : ""}
        </span>

        <div className="form__btns">
          <button className="btn btn--secondary" onClick={HandleSubmit}>
            Connexion
          </button>
          {Action === "forgot_pin" && (
            <button
              className="btn btn--light"
              onClick={(e) => {
                e.preventDefault();
                window.location.href = "/login";
              }}
            >
              Annuler
            </button>
          )}
        </div>

        <div className="form__grp form__grp--inline login__sublinks">
          <a
            href={`${
              process.env.NODE_ENV === "development"
                ? "https://admin.pirouette.urlprovisoire.net"
                : process.env.REACT_APP_URL
            }/auth/identifiant-oubli`}
          >
            Identifiant oublié
          </a>

          <a
            href={`${
              process.env.NODE_ENV === "development"
                ? "https://admin.pirouette.urlprovisoire.net"
                : process.env.REACT_APP_URL
            }/auth/mot-de-passe-oubli`}
          >
            Mot de passe oublié
          </a>
        </div>
      </form>
    </>
  );
}

const MapStateToProps = (state) => state;

export default connect(MapStateToProps)(LoginForm);
