import React from "react";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { Lock as lockAction } from "../../actions/Auth/Lock";
import { addQuery } from "../../actions/Auth/Auth";
import Modal from "./Modal";

const Lock = ({ lock, hideLock, dispatch }) => {
  var location = useLocation();
  const dispatchDisconnect = () => {
    dispatch(lockAction()).then(() =>
      dispatch(addQuery({ param: "location", value: location.pathname }))
    );
    hideLock();
  };
  return (
    <Modal show={lock} hide={hideLock} label="Verrouillage">
      <p>
        <strong>Souhaitez-vous vraiment verrouiller l'application ?</strong>
      </p>

      <div className="form__btns">
        <button
          className="btn btn--secondary loginButton"
          onClick={dispatchDisconnect}
        >
          Verrouiller l'application
        </button>
        <button className="btn btn--light loginButton" onClick={hideLock}>
          Annuler
        </button>
      </div>
    </Modal>
  );
};

export default connect(({ ShowModal }) => ({ lock: ShowModal.lock }))(
  React.memo(Lock, (oldProps, newProps) => {
    if (oldProps.lock !== newProps.lock || newProps.lock) return false;
    return true;
  })
);
