/**
 * * Function that return the Sync table
 * @param {DexieDb} db
 */
export const getHistoriqueTable = (db) =>
  db.getDB().table(db.getTableName().HistoSync);

/**
 *  * Function that clear the Sync table
 * @param {DexieDb} db
 */
export const clearHistorique = (db) =>
  db.clearTable(db.getTableName().HistoSync);

/**
 * * Function that return all the sync
 * @param {DexieDb} db
 */
export const getAllSync = (db) => getHistoriqueTable(db).toArray();

export const getLastSync = (db) =>
  getHistoriqueTable(db)
    .filter((e) => e.success)
    .last()
    .then((lastSync) => ({ ...lastSync, day: lastSync.day.toString() }));

export const insertSync = (db, { accountId, status, why }, syncContent) =>
  db.insertOrUpdate(
    null,
    parseSynchro(new Date(), accountId, status, why, syncContent),
    db.getTableName().HistoSync
  );

const parseSynchro = (date, accountId, status, why, sync) => ({
  day: date,
  accountId,
  success: status,
  why,
  syncContent: sync,
});

export const HistoSyncHandler = {
  ClearHistorique: clearHistorique,
  InsertSync: insertSync,
  GetLastSync: getLastSync,
};
