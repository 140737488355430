import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { showInfo } from "../../actions/showModal";
import ChampLogin from "../Login/ChampLogin";
import Modal from "./Modal";

const ModalDeviceName = ({
    deviceName,
    hideDeviceName,
    dispatch,
}) => {
    const [name, setName] = useState("");
    const [nameError, setNameError] = useState(false);
    const [error, setError] = useState("");
    const [finalName, setFinalName] = useState("");

    useEffect(() => {
        setName(localStorage.getItem('NamedDeviceKey'));
        setFinalName(localStorage.getItem('NamedDeviceKey'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localStorage ]);

    const handleConfirm = () => {
        if (localStorage.getItem('NamedDeviceKey') === name) {
            setError(name + " est l'ancien nom du périphérique");
        } else {
            setFinalName(name);
            localStorage.setItem('NamedDeviceKey', name);
        }
    }

    return (
        <Modal
            show={deviceName}
            hide={hideDeviceName}
            label={"Nom du périphérique : " + finalName}
        >
            <span className="flex center">{error}</span>
            <ChampLogin
                id="deviceName"
                name="Nouveau nom de périphérique"
                type="text"
                inputmode="string"
                value={name}
                setValue={setName}
                classN=""
                required
                error={nameError}
                setError={setNameError}
            />

            <div className="form__btns">
                <button
                    className="btn btn--secondary"
                    onClick={() => {
                        handleConfirm()
                        dispatch(hideDeviceName())
                    }}
                >Mettre à jour</button>
                <button
                    className="btn btn--light btn--link"
                    onClick={() => {
                        dispatch(hideDeviceName())
                        dispatch(showInfo())
                    }}
                >
                    Retourner aux informations
                </button>
            </div>
        </Modal>
    )
}

export default connect(({ShowModal}) => ({
    deviceName: ShowModal.deviceName,
}))(
    React.memo(ModalDeviceName, (oldProps, newProps) => {
        if (oldProps.deviceName !== newProps.deviceName || newProps.deviceName) return false;
        return true;
    })
)