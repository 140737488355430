export const protoFilterContent = () => ({ enabled: false, filter: [] });
export const protoFilter = () => ({
  1: protoFilterContent(),
  2: protoFilterContent(),
  3: protoFilterContent(),
  4: protoFilterContent(),
  5: protoFilterContent(),
});

export const protoOperatorContent = () => ({
  enabled: false,
  value: 0,
  operator: 0,
});

export const protoOperator = () => ({
  1: protoFilterContent(),
  2: protoFilterContent(),
  3: protoFilterContent(),
  4: protoFilterContent(),
  5: protoFilterContent(),
});

export const filter = () => ({
  active: {
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
  },
  Groupe: protoFilter(),
  School: protoFilter(),
  Teacher: protoFilter(),
  Level: protoFilter(),
  Bus: protoFilter(),
  Special: protoFilter(),
  Presta: protoFilter(),
  Stop: protoFilter(),
  Age: protoOperator(),
  BirthYear: protoOperator(),
  Service: protoFilter(),
  Location: protoFilter(),
});

export const Operator = {
  0: {
    name: "disabled",
    operator: "Off",
  },
  1: {
    name: "greater",
    operator: ">",
    action: (from, than) => from > than,
  },
  2: {
    name: "lower",
    operator: "<",
    action: (from, than) => from < than,
  },
  3: {
    name: "Equal",
    operator: "=",
    action: (from, than) => from === than,
  },
  4: {
    name: "GreaterOrEqual",
    operator: ">=",
    action: (from, than) => from >= than,
  },
  5: {
    name: "LowerOrEqual",
    operator: "<=",
    action: (from, than) => from <= than,
  },
};
