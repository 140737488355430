import React from 'react'

export default function VigilanceIcon({ vigilance, showLabels }) {
  return (
    <div className='child__vigilance'>
      <svg
        className={`icon icon__${vigilance.picto}`}
        style={{
          color: `${vigilance.color}`,
        }}
      >
        <use
          href={
            process.env.PUBLIC_URL +
            '/vigilance/symbol-defs.svg?v=' +
            process.env.REACT_APP_VERSION +
            '#vigilance-' +
            vigilance.picto
          }
        ></use>
      </svg>
      {showLabels && <span className='child__vigilance-label'>{vigilance.name}</span>}
    </div>
  )
}
