import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  emptyFilteredChild,
} from "../../actions/Database/Child";
import {
  LoadFromStorageFilter,
  resetScreen,
  setDate,
  setPending,
  startupGetData,
} from "../../actions/Database/Pointage";
import ActionBottomPointage from "../Menu/ActionBottomPointage";
import ChildResa from "./childContent/resaDisp";
import { prestationTypeParser } from "../../helper/user";
import ListChildren from "../Children/ListChildren";
import { useLocationId } from "../../helper/useLocationId";
import { checkIfDateIsInAllowed, incrementDate } from "../../helper/date";
const Pointage = ({ Child, pointage, dispatch, isAllowed }) => {
  const [locationId] = useLocationId();
  useEffect(() => {
    dispatch(setPending(true));
    dispatch(LoadFromStorageFilter());
    Promise.all([
      pointage.date === 0 &&
        ![10, 8, 6].some((type) => locationId.includes(type)) &&
        dispatch(setDate({ date: new Date(), pending: false, isStarting: true})),
      dispatch(startupGetData()),
    ]);
    return () => {
      dispatch(resetScreen());
      dispatch(emptyFilteredChild());
    };
    // eslint-disable-next-line
  }, []);
  if (
    (pointage.SchoolHoliday === false &&
      pointage.prestationsType.includes(3) &&
      pointage.dayID !== 6) ||
    (pointage.prestationsType.includes(5) &&
      (pointage.SchoolHoliday === true ||
      pointage.dayID === 6 ||
      pointage.dayID === 3)) ||
      (pointage.SchoolHoliday === true && pointage.prestationsType.includes(1))
  )
    return (
      <div>
        <h1>
          Aucune prestation{" "}
          {pointage.prestationsType.includes(1) &&
            pointage.dayID !== 3 &&
            prestationTypeParser()[1]}
          {pointage.prestationsType.includes(2) &&
            pointage.dayID === 3 &&
            prestationTypeParser()[2]}
          {pointage.prestationsType.includes(5) &&
            pointage.dayID === 3 &&
            prestationTypeParser()[5]}
          {(pointage.prestationsType.includes(3) && prestationTypeParser()[3])}{" "}
          pour le {new Date(pointage.date).toLocaleDateString()}
        </h1>
      </div>
    );
  if (
    pointage.dayID === 6 &&
    !Object.keys(pointage.arrayPresta).includes("4") &&
    !pointage.Prestations.some((presta) => presta.possible_days.includes(6)) &&
    ![6, 8, 10].some((id) => locationId.includes(id))
  )
    return (
      <div>
        <h1>
          Aucune prestation {prestationTypeParser()[4]} pour le{" "}
          {new Date(pointage.date).toLocaleDateString()}
        </h1>
      </div>
    );
  if (pointage.closed)
    return (
      <div>
        <h1>{pointage.why}</h1>
      </div>
    );
  if (pointage.PrestationsLength === 0) {
    return (
      <div>
        <h1>Aucune prestation pour le {new Date(pointage.date).toLocaleDateString()}</h1>
      </div>
    );
  }
  if (pointage.momentID === -1) {
    return (
      <div>
        <h1>Veuillez choisir un moment</h1>
      </div>
    );
  }
  return (
    <div className="ChildTable">
      {pointage.PrestationsLength > 0 &&
        pointage.ReservationLength === 0 &&
        !Child.showAllChild &&
        Child.filteredChild.length === 0 &&
        !Child.Pending &&
        pointage.momentID !== -1 &&
        (locationId.includes(10) &&
          !isAllowed(incrementDate(new Date(pointage.date), -1)) &&
          !isAllowed(incrementDate(new Date(pointage.date), 1)) ?
          <h1>Les réservations de cet atelier ne sont pas couvertes par la plage de date configurée</h1> :
          <h1>Aucune réservation</h1>)
        }
      {pointage.PrestationsLength > 0 &&
        pointage.momentID !== -1 &&
        ((pointage.ReservationLength === 0 && Child.showAllChild) ||
          pointage.ReservationLength > 0) && (
          <ListChildren date={pointage.date} SpecificContent={ChildResa} />
        )}

      <ActionBottomPointage />
    </div>
  );
};

export default connect(({ Child, Pointage: pointage, Auth, Synchro, }) => ({
  Child: {
    showAllChild: Child.showAllChild,
    filteredChild: Child.filteredChild,
    Pending: Child.Pending,
  },
  pointage: {
    Pending: pointage.Pending,
    momentID: pointage.momentID,
    PrestationsLength: pointage.Prestations.length,
    ReservationLength: pointage.Reservation.filter(
      (resa) => !pointage.Prestations.find((presta) => presta.id === resa.prestation_id)
          ?.secondary
    ).length,
    Prestations: pointage.Prestations,
    Reservations: pointage.Reservation,
    date: pointage.date,
    dayID: pointage.dayID,
    SchoolHoliday: pointage.SchoolHoliday,
    prestationsType: pointage.prestationsType,
    arrayPresta: pointage.arrayPresta,
  },
  isAllowed: checkIfDateIsInAllowed(Auth, Synchro)
}))(Pointage);
