import { TokenHandler, GetAssets, ChildHandler } from "../helper";

/**
 * * Function that return the Contact table
 * @param {DexieDb} db
 * @returns {ContactTable}
 */
export const getContactTable = (db) =>
  db.getDB().table(db.getTableName().Contact);

/**
 * * Function that return if the table has Contact
 * @param {DexieDb} db
 * @returns {Promise/Boolean}
 */
export const hasContact = (db) => {
  return getContactTable(db)
    .toCollection()
    .count()
    .then((nbr) => (nbr > 0 ? true : false));
};

/**
 * * Allow the user to get a Contact by his id
 * @param {DexieDb} db
 * @param {Integer} ContactId
 */
export const getOneContact = (db, ContactID) => {
  return getContactTable(db).where("id").equals(ContactID).first();
};

/**
 * * Allow the user to get a parsed contact with picture from the links object
 * @param {Contact} contact
 */
export const contactParse = (contact, db) => {
  return getOneContact(db, contact.id).then((contactFromDb) => {
    return new Promise((resolve, reject) => {
      var ParsedContact = contact;
      ParsedContact.photo = contactFromDb?.photo;
      /**
       * childFromDb !== undefined &&
        childFromDb.updated_photo_at !== undefined &&
        childFromDb.updated_photo_at !== null &&
        childFromDb.updated_photo_at > child.updated_photo_at
       */
      if (
        ((ParsedContact.has_photo) ||
        (ParsedContact.has_photo &&
          contactFromDb !== undefined &&
          !contactFromDb.has_photo) ||
        (ParsedContact.has_photo &&
          contactFromDb === undefined)
      ) &&
      (
        contactFromDb === undefined ||
        (contactFromDb.updated_photo_at !== undefined &&
        contactFromDb.updated_photo_at !== null &&
        contactFromDb.updated_photo_at < contact.updated_photo_at)
      )) {
        GetAssets(TokenHandler.getToken())
          .then((client) =>
            client.App_Center_API_Controllers_AssetsController__getContactPicture(
              { contact: ParsedContact.id }
            )
          )
          .then((res) => {
            var reader = new FileReader();
            reader.readAsDataURL(res.data);
            reader.onloadend = () => {
              ParsedContact.photo = reader.result;
              resolve(ParsedContact);
            };
          })
          .catch((err) => reject(err));
      } else resolve(ParsedContact);
    });
  });
};

/**
 * * Function that fill the ContactTable fetching them from the database
 * @param {DexieDb} db
 * @param {ReduxDispatch} dispatch
 * @param {CallBack} StepDone
 */
export const tableContactFiller = (db, dispatch, StepDone) => {
  return new Promise((resolve, reject) => {
    var promiseAll = [];
    ChildHandler.GetChildTable(db)
      .toArray()
      .then((values) => {
        values
          .reduce((contacts, child) => {
            child.links.forEach((contact) => {
              if (
                !contacts.some(
                  (contactFilter) => contactFilter.id === contact.contact.id
                )
              ) {
                contacts.push(contact.contact);
              }
            });
            return contacts;
          }, [])
          .forEach((value) => {
            promiseAll.push(contactParse(value, db));
          });
        Promise.all(promiseAll)
          .then((parsedContact) =>
            db
              .insertLotsOfData(parsedContact, db.getTableName().Contact)
              .then(() => {
                if (parsedContact.length > 0) {
                  dispatch(
                    StepDone({
                      key: "Contact",
                      action: "Récupération des contacts",
                      size: parsedContact.length,
                      end: true,
                    })
                  );
                }
              })
          )
          .then(() => resolve());
      });
  });
};

export const clearContact = (db) => db.clearTable(db.getTableName().Contact);

export const ContactHandler = {
  GetContactTable: getContactTable,
  HasContact: hasContact,
  GetOneContact: getOneContact,
  ContactParse: contactParse,
  TableContactFiller: tableContactFiller,
  ClearContact: clearContact,
};
