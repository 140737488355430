import { createAsyncThunk } from "@reduxjs/toolkit";
import { getToken } from "../helper/token";
import { SessionHandler, isOnline, Db, crypt } from "../helper";
import { GetApi } from "../helper/Api";
export const GetUser = createAsyncThunk(
  "Auth/GetUser",
  async (value, { dispatch, rejectWithValue }) => {
    if (isOnline)
      return GetApi(getToken())
        .then((client) => client.apis)
        .then((client) => {
          return Promise.all([
            client.Auth.App_Center_API_Controllers_AuthController__getUser()
              .then((res) => res.body.data)
              .then((res) => {
                if (
                  SessionHandler.hasSession() &&
                  SessionHandler.getIdUser() !== res.id &&
                  SessionHandler.getUserSurname() !== res.username
                )
                  new Db(crypt.getSessionDecryptedKey()).wipeDatabase();
                SessionHandler.setNameUser(res.first_name);
                SessionHandler.setUserSurname(res.last_name);
                SessionHandler.setIdUser(res.id);
                SessionHandler.setPermissionLevel(res.permission_level);
                SessionHandler.setUserName(res.username);
              }),
            client.Auth.App_Center_API_Controllers_AuthController__getStructure()
              .then((parse) => parse.body.data)
              .then((res) => {
                SessionHandler.setNomCentre(res.name);
                SessionHandler.setPrestations(res.prestations);
                SessionHandler.setRootPeriodAfter(res.sync_period_after);
                SessionHandler.setRootPeriodBefore(res.sync_period_before);
                SessionHandler.setRootRecalculPresta(res.recalcul_presta);
              }),
            client.Assets.App_Center_API_Controllers_AssetsController__getStructureLogo()
              .then((res) => {
                if (!res.ok) {
                  throw new Error(res.status);
                }
                var reader = new FileReader();
                reader.readAsDataURL(res.data);
                reader.onloadend = () => {
                  if (isOnline()) SessionHandler.setLogoCentre(reader.result);
                };
              })
              .catch((res) => {
                if (isOnline()) SessionHandler.setLogoCentre(undefined);
              }),
            client.Assets.App_Center_API_Controllers_PrestaMapController____invoke().then(
              (res) => {
                SessionHandler.setPrestationsMap(res.body);
              }
            ),
          ])
            .then(() => ({}))
            .catch((err) => {
              rejectWithValue({ code: err.status });
            });
        });
    else rejectWithValue({ code: 418 });
  }
);
