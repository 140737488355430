import React, { useEffect } from "react";
import { connect } from "react-redux";
import ItemPrestationHome from "./PointageHome/ItemPrestationHome";
import { getArrayPresta } from "../../actions/Database/Pointage";
import { Redirect } from "react-router-dom";
import { SessionHandler } from "../../helper";

const formatDate = (date) => {
  // Options de formatage pour afficher le jour de la semaine, le jour du mois, le mois et l'année
  const options = { weekday: "long", day: "numeric", month: "long", year: "numeric" };
  return new Intl.DateTimeFormat("fr-FR", options).format(date);
};

const PointageHome = ({ SessionData, ProgressPending, Pointage, dispatch }) => {
  useEffect(() => {
    dispatch(getArrayPresta());
    // eslint-disable-next-line
  }, [ProgressPending]);
  if (Object.values(Pointage.arrayPresta).length === 0 && !Pointage.Pending)
    return (
      <div>
        <h1>Veuillez synchroniser, aucune prestation n'a été récupérée</h1>
      </div>
    );
  if (Pointage.Pending) return <div></div>;
  if (Object.values(SessionHandler.getPrestations()).length === 1)
    return (
      <Redirect
        to={
          "/pointage/" +
          Object.values(SessionHandler.prestationsParser()).find((element) =>
            element.id.includes(Object.values(SessionHandler.getPrestations())[0])
          ).url
        }
      />
    );
  const today = new Date();
  return (
    <>
      <h1>
        Bienvenue {SessionData ? SessionData.Name : ""}{" "}
        {SessionData ? SessionData.usersurname : ""}
        <div className="ttl__sub">{formatDate(today)}</div>
      </h1>
      <div className="cards__container">
        {SessionData &&
          SessionData.PrestationsMap.filter(
            (presta) =>
              Object.values(SessionHandler.getPrestations()).some((prestas) =>
                presta.prestation_types.includes(prestas)
              ) &&
              SessionHandler.prestationsParser()[presta.contract_option]
                .homeIcon
          ).map((Key) => (
            <ItemPrestationHome
              {...Key}
              key={Key.contract_option}
              dispatch={dispatch}
            />
          ))}
      </div>
    </>
  );
};

export default connect(({ Auth, Pointage, Progress }) => ({
  SessionData: Auth.SessionData,
  ProgressPending: Progress.Pending,
  Pointage: {
    arrayPresta: Pointage.arrayPresta,
    Pending: Pointage.Pending,
  },
}))(PointageHome);
