import React from "react";
import { serviceTypeParser } from "../../../helper/user";
import ModalChildBodyBusItem from "./modalChildBodyBusItem";

const ModalChildBodyBus = ({ signup: { affectations } }) => {
  var filteredAffectation = affectations.filter(
    (affectation) =>
      affectation.bus_arriving_route_id !== null ||
      affectation.bus_departing_route_id !== null
  );
  return (
    <div>
      {filteredAffectation.map((affectation) => (
        <div key={affectation.id}>
          <h3>{serviceTypeParser()[affectation.type].Label}</h3>
          <ModalChildBodyBusItem affectation={affectation} />
        </div>
      ))}
      {filteredAffectation.length === 0 && <p>Aucun bus disponible</p>}
    </div>
  );
};

export default ModalChildBodyBus;
