import React from "react";
import { Card } from "react-bootstrap";
import { SessionHandler } from "../../../helper";
import { Link } from "react-router-dom";
import { setPrestationsId } from "../../../actions/Database/Pointage";
import IcomoonSVG from "../../IcomoonSVG";

const ItemPrestationHome = ({
  prestation_types,
  contract_option,
  dispatch,
}) => {
  const icon = SessionHandler.prestationsParser()[contract_option].svgIcon;
  // On rajoute en dur les mercredis si le périscolaire est activé
  return (
    <React.Fragment>
      <Card className="card--presta">
        <Link
          to={
            "/pointage/" + SessionHandler.prestationsParser()[contract_option].url
          }
          onClick={() =>
            dispatch(
              setPrestationsId({
                location: SessionHandler.prestationsParser()[contract_option].url,
                init: true,
              })
            )
          }
        >
          <div className="card__img">
            <IcomoonSVG icon={icon} />
          </div>

          <Card.Body>
            <Card.Title>
              {SessionHandler.prestationsParser()[contract_option].Label}
            </Card.Title>
          </Card.Body>
        </Link>
      </Card>
      {contract_option === 1 && (
        <Card className="card--presta">
          <Link
            to={
              "/pointage/" + SessionHandler.prestationsParser()[9].url
            }
            onClick={() =>
              dispatch(
                setPrestationsId({
                  location: SessionHandler.prestationsParser()[9].url,
                  init: true,
                })
              )
            }
          >
            <div className="card__img">
              <IcomoonSVG icon={SessionHandler.prestationsParser()[9].svgIcon} />
            </div>

            <Card.Body>
              <Card.Title>
                {SessionHandler.prestationsParser()[9].Label}
              </Card.Title>
            </Card.Body>
          </Link>
        </Card>
      )}
    </React.Fragment>
  );
};

export default ItemPrestationHome;
