import React from "react";
import { connect } from "react-redux";
import { DateHandler } from "../../../helper";
import { showSynchro } from "../../../actions/showModal";
const TopNavSynchro = ({ lastSync, dispatch }) => {
  if (lastSync) {
    var noRecentSync = DateHandler.HowMuchDaySince(new Date(lastSync)) > 2;
    var lastParsedSync = new Date(lastSync);
  } else {
    noRecentSync = true;
  }

  let btnClass = noRecentSync ? "btn--error" : "btn--secondary";
  let btnIcon = noRecentSync ? "icon__warning" : "icon__cloud-upload";

  return (
    <button
      className={"sync-btn btn " + btnClass}
      onClick={() => dispatch(showSynchro())}
    >
      <svg className={"icon " + btnIcon} focusable="false">
        <use
          href={process.env.PUBLIC_URL + "/picto/symbol-defs.svg#" + btnIcon}
        ></use>
      </svg>
      <div>
        Synchroniser
        {(lastSync && (
          <span className="small">
            dernière synchro le{" "}
            {lastParsedSync.toLocaleDateString("fr-fr", {
              day: "numeric",
              month: "numeric",
              year: "numeric",
            })}
            {" à "}
            {DateHandler.FormatWithLeadingZero(lastParsedSync.getHours())}:
            {DateHandler.FormatWithLeadingZero(lastParsedSync.getMinutes())}:
            {DateHandler.FormatWithLeadingZero(lastParsedSync.getSeconds())}
          </span>
        )) ||          
          <span className="small">jamais synchronisé</span>}
      </div>
    </button>
  );
};

export default connect(({ Synchro }) => ({ lastSync: Synchro.lastSync }))(
  TopNavSynchro
);
