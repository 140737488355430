import React from "react";
import { connect } from "react-redux";
import TimePickle from "./TimePicker";
import { updateHorairePresence } from "../../../../actions/Database/Resa";
import {
  checkTime,
  dateToHourString,
  timeIsLower,
} from "../../../../helper/date";
export const checkUnNullEmpty = (value) =>
  value === undefined || value === null || value === "";

const setPresenceHour = ({ childId, resaId, dispatch, resa, prestaLimit, Pointage }) => {
  var timePresence = [
    {
      inputTime: resa.arrived_at,
      other: resa.departed_at,
      isArrivedAt: true,
      offLimit:
        checkTime(prestaLimit.startTime) &&
        prestaLimit.startTime !== "00:00" &&
        prestaLimit.startTime !== "00:00:00" &&
        checkTime(resa.arrived_at) &&
        timeIsLower(resa.arrived_at, prestaLimit.startTime),
    },
    {
      inputTime: resa.departed_at,
      other: resa.arrived_at,
      isArrivedAt: false,
      offLimit:
        checkTime(prestaLimit.endTime) &&
        prestaLimit.endTime !== "00:00" &&
        prestaLimit.endTime !== "00:00:00" &&
        checkTime(resa.departed_at) &&
        timeIsLower(prestaLimit.endTime, resa.departed_at),
    },
  ];
  return (
    <>
      {timePresence.map((time) => (
        <div key={`${resaId}${time.isArrivedAt ? "A" : "D"}`}>
          {(!checkUnNullEmpty(time.inputTime) && (
            <TimePickle
              inputTime={time.inputTime}
              resaId={resaId}
              isArrivedAt={time.isArrivedAt}
              otherValue={time.other}
              dispatch={dispatch}
              addClass={time.offLimit ? "wrongTime" : ""}
              childId={childId}
            />
          )) || (
            <button
              className="btn btn--base"
              onClick={() =>
                dispatch(
                  updateHorairePresence({
                    resaId,
                    arrivedAt: time.isArrivedAt
                      ? dateToHourString(new Date())
                      : time.other,
                    departedAt: time.isArrivedAt
                      ? time.other
                      : dateToHourString(new Date()),
                  })
                )
              }
            >
              {time.isArrivedAt ? "Arrivée" : "Départ"}
            </button>
          )}
        </div>
      ))}
    </>
  );
};

export default connect(({ Pointage }, { resaId }) => ({
  resa: Pointage.Reservation.find((reservation) => reservation.id === resaId),
  prestaLimit: Pointage.prestaLimit,
  Pointage: Pointage
}))(
  React.memo(setPresenceHour, (oldProps, newProps) => {
    if (oldProps.resa === newProps.resa) return true;
  })
);
