import React, { useEffect } from "react";
import { connect } from "react-redux";
import { showAskPin, ChangePinAction } from "../../actions/showModal";
import ChampLogin from "../Login/ChampLogin";
import Modal from "./Modal";
const ModalChangePin = ({ ShowModal, hideChangePin, dispatch }) => {
  const [pin, setPin] = React.useState("");
  const [pinError, setPinError] = React.useState(false);
  const [confirmPin, setConfirmPin] = React.useState("");
  const [confirmPinError, setConfirmPinError] = React.useState(false);
  const [error, setError] = React.useState("");
  useEffect(() => {
    if (ShowModal.changePin && ShowModal.askPinResult === true) {
      dispatch(ChangePinAction({ pin }));
      hideChangePin();
    } // eslint-disable-next-line
  }, [ShowModal.askPinResult]);
  const handleConfirm = () => {
    if (pin !== confirmPin) {
      setConfirmPinError(true);
      setError("Les deux codes PIN doivent être similaires.");
    } else dispatch(showAskPin("Ancien code PIN"));
  };

  return (
    <Modal
      show={ShowModal.changePin}
      hide={hideChangePin}
      label="Changement du code PIN"
    >
      <span className="flex center">{error}</span>
      <ChampLogin
        id="pin"
        name="Nouveau code PIN"
        type="password"
        inputmode="numeric"
        value={pin}
        setValue={setPin}
        error={pinError}
        setError={setPinError}
        classN=""
        max="99999999"
        min="0000"
        required
      />
      <ChampLogin
        id="pin"
        name="Confirmer le nouveau code PIN"
        type="password"
        inputmode="numeric"
        value={confirmPin}
        setValue={setConfirmPin}
        error={confirmPinError}
        setError={setConfirmPinError}
        classN=""
        max="99999999"
        min="0000"
        required
      />
      <div className="form__btns">
        <button
          className="btn btn--secondary"
          disabled={
            pin.length < 4 ||
            pin.length > 8 ||
            confirmPin.length < 4 ||
            confirmPin.length > 8
          }
          onClick={handleConfirm}
        >
          Confirmer
        </button>
        <button className="btn btn--light btn--link" onClick={() => hideChangePin()}>
          Annuler
        </button>
      </div>
    </Modal>
  );
};

export default connect(({ ShowModal }) => ({
  ShowModal: {
    askPinResult: ShowModal.askPinResult,
    changePin: ShowModal.changePin,
  },
}))(
  React.memo(ModalChangePin, (oldProps, newProps) => {
    if (
      oldProps.ShowModal.changePin !== newProps.ShowModal.changePin ||
      newProps.ShowModal.changePin
    )
      return false;
    return true;
  })
);
