import { TokenHandler, GetMealTypes } from "../helper";

export const getMealTypesTable = (db) => db.getDB().table(db.getTableName().MealTypes);

export const cleanMealTypes = (db) => db.clearTable(db.getTableName().MealTypes);

export const TableMealTypesFiller = (db, dispatch, StepDone) => {
  return GetMealTypes(TokenHandler.getToken()).then((client) =>
    client
      .App_Center_API_Controllers_MealTypesController__index()
      .then((res) => res.body.data)
      .then((data) => {
        return db.insertLotsOfData(data, db.getTableName().MealTypes).then(() => {
          dispatch(
            StepDone({
              key: "Meal Types",
              action: "Récupération des types de repas",
              end: true,
              size: data.length,
            })
          );
        });
      })
  );
};

export const getAllMealTypes = (db) => getMealTypesTable(db).toArray();

export const MealTypesHandler = {
  getMealTypesTable,
  cleanMealTypes,
  TableMealTypesFiller,
  getAllMealTypes,
};
