import React, { useEffect, useState } from "react";
import {
  useHistory,
  useLocation,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import { connect } from "react-redux";
import { Startup } from "../actions/onStartUp";
import { addQuery, resetQuery, resetShow } from "../actions/Auth/Auth";
import Birthday from "./Children/Birthday";
import Dossier from "./Children/Dossier";
import ModalHandler from "./modal";
import NotificationTask from "./NotificationTaskInProgress";
import Burger from "./Menu/burger";
import TopBar from "./Menu/topBar";
import PointageHome from "./Pointage/PointageHome";
import Pointage from "./Pointage/Pointage";
import PointageNonPointe from "./Pointage/PointageNonPointe";
import { initModalSlicer } from "../actions/showModal";
import { SessionHandler, useQuery } from "../helper";
import {
  setPrestationsId,
  getArrayPresta,
  getPrestation,
} from "../actions/Database/Pointage";
import Login from "./Login";
import ServiceWorker from "./ServiceWorker";
import pwa from "./pwa";
import Spinner from "./spinner";
import PointageMiddle from "./Pointage/PointageMiddle";

const onStartup = (dispatch) => (location) => {
  dispatch(initModalSlicer())
    .then(() => dispatch(Startup()))
    .then(() =>
      dispatch(addQuery({ param: "location", value: location.pathname }))
    );
};

const onAccessChangeAndRedirect =
  (dispatch) => (setRedirect, redirect, Auth, redirected, location) => {
    if (Auth.show.Active && Auth.show.to === window.location.pathname) {
      dispatch(resetShow());
    }
    if (
      redirected ||
      Auth.show.Active ||
      (!Auth.Access &&
        !["/login", "/pwainstall"].includes(window.location.pathname))
    ) {
      setRedirect(true);
    } else if (redirect !== false) {
      setRedirect(false);
      dispatch(resetShow());
    }
    if ([3, 4].includes(location.pathname.split("/").length) && Auth.Access) {
      dispatch(
        setPrestationsId({
          location: location.pathname.split("/")[2],
          init: true,
        })
      );
      dispatch(getArrayPresta());
      if (Object.values(SessionHandler.prestationsParser()).find(
        (value) => value.url === location.pathname.split("/")[2]
      ).id[0] !== 5) {
        dispatch(getPrestation());
      }
    }
  };

const App = ({ dispatch, Access, show, redirect: redirected, showActionInProgress }) => {
  var location = useLocation();
  var history = useHistory();
  var query = useQuery(location);
  var [redirect, setRedirect] = useState(false);
  useEffect(() => {
    onStartup(dispatch)(location);
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (query.get("from") === "login") {
      history.push(location.pathname);
      dispatch(resetQuery());
    }
    // eslint-disable-next-line
  }, [query]);
  useEffect(() => {
    onAccessChangeAndRedirect(dispatch)(
      setRedirect,
      redirect,
      { Access, show },
      redirected,
      location
    );
    // eslint-disable-next-line
  }, [Access, redirect, show]);
  return (
    <div>
      {redirect && <Redirect to={show.redirect ? show.to : "/login"} />}
      {Access && <Burger />}
      {Access && <TopBar />}
      <Spinner />
      <Login />
      <Route path="/pwainstall" component={pwa} />
      {Access && (
        <div>
          <Route path={["/", "/pointage"]} exact component={PointageHome} />
          <Switch>
            <Route
              path={Object.values(SessionHandler.prestationsParser()).reduce(
                (all, presta) => {
                  if (presta.middleScreen) {
                    all.push(`/pointage/${presta.url}`);
                  }
                  return all;
                },
                []
              )}
              exact
              component={PointageMiddle}
            />
            <Route path="/pointage/:type" component={Pointage} />
          </Switch>
          <Route path="/nonPointe" component={PointageNonPointe} />
          <Route path="/birthday" component={Birthday} />
          <Route path="/dossier" component={Dossier} />
          <Route path="/stat" component={() => <p>Coming in 2.1</p>} />
          <ModalHandler />
          {showActionInProgress && <NotificationTask />}
        </div>
      )}
      <ServiceWorker />
    </div>
  );
};

export default connect(({ Auth, Progress }) => ({
  Access: Auth.Access,
  show: Auth.show,
  showActionInProgress: Progress.showActionInProgress
}))(App);
