import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { formatWithLeadingZero } from "../../../../helper/date";
import Modal from "../../../modal/Modal";

const TimePickerModal = ({ coming, time, onClick, show, hide }) => {
  const [minute, setMinute] = useState(0);
  const [hour, setHour] = useState(0);
  const handleFocus = (event) => event.target.select();

  useEffect(() => {
    if (!time) return;
    setMinute(Number(time.split(":")[1]));
    setHour(Number(time.split(":")[0]));
  }, [time]);
  useEffect(() => {
    sessionStorage.setItem("dontReRender", show);
    return () => sessionStorage.setItem("dontReRender", false);
  }, [show]);

  return (
    <Modal
      show={show}
      hide={() => {
        hide();
        setMinute(Number(time.split(":")[1]));
        setHour(Number(time.split(":")[0]));
      }}
      label={`Heure ${coming ? "d'arrivée" : "de départ"}`}
      modalClassName={"TimeModal"}
    >
      <form id={"formHoraire"}>
        <div className="timeDiv">
          <div className="timeComponent">
            <button
              type="button"
              className="btn"
              onClick={(e) => {
                e.preventDefault();
                setHour((hour + 1) % 24);
              }}
            >
              +1 h
            </button>
            <input
              id="Hour"
              type="number"
              autoFocus
              value={formatWithLeadingZero(hour)}
              onChange={(e) =>
                setHour((Number(e.currentTarget.value) % 100) % 24)
              }
              onFocus={handleFocus}
              max={24}
              min={0}
              tabIndex="1"
            />
            <button
              type="button"
              className="btn"
              onClick={(e) => {
                e.preventDefault();
                setHour(hour - 1 >= 0 ? hour - 1 : 23);
              }}
            >
              -1 h
            </button>
          </div>
          <div>
            <span className="big">:</span>
          </div>
          <div className="timeComponent">
            <button
              type="button"
              className="btn"
              onClick={(e) => {
                e.preventDefault();
                setMinute((minute + 1) % 60);
              }}
            >
              +1 min
            </button>
            <input
              id="Minute"
              type="number"
              value={formatWithLeadingZero(minute)}
              onChange={(e) =>
                setMinute((Number(e.currentTarget.value) % 100) % 60)
              }
              onFocus={handleFocus}
              max={60}
              min={0}
              tabIndex="2"
            />
            <button
              type="button"
              className="btn"
              onClick={(e) => {
                e.preventDefault();
                setMinute(minute - 1 >= 0 ? minute - 1 : 59);
              }}
            >
              -1 min
            </button>
          </div>
        </div>

        <div>
          <div className="form__grp">
            <button
              className="btn btn--secondary"
              type="submit"
              onClick={(e) => {
                onClick(
                  `${formatWithLeadingZero(hour)}:${formatWithLeadingZero(
                    minute
                  )}`
                );
              }}
            >
              Valider
            </button>
            <button
              type="button"
              className="btn btn--error btn--link"
              onClick={() => {
                onClick(null);
              }}
            >
              Effacer l'heure
            </button>{" "}
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default connect()(TimePickerModal);
