import React from "react";
import { Modal } from "react-bootstrap";

const ModalPaterne = ({
  label = "",
  ComponentHeader,
  show = true,
  hide = () => console.log("hide"),
  modalClassName = "",
  modalHeaderClassName = "",
  modalBodyClassName = "",
  backClassName = "",
  closeButton = true,
  size = "",
  children = () => <div></div>,
}) => {
  return (
    <Modal
      show={show}
      onHide={hide}
      animation={false}
      dialogClassName={`${modalClassName}`}
      size={size}
      backdropClassName={backClassName}
    >
      <Modal.Header
        closeButton={closeButton}
        className={`${modalHeaderClassName}`}
      >
        {(ComponentHeader && <ComponentHeader />) || <p>{label}</p>}
      </Modal.Header>
      <Modal.Body className={`${modalBodyClassName}`}>{children}</Modal.Body>
    </Modal>
  );
};

export default ModalPaterne;
