import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  GetAuth,
  isOnline,
  DeviceIdentityHandler,
  TokenHandler,
} from "../../helper";
import { Login } from "./Login";

export const YouAreNotShow = createAsyncThunk(
  "Auth/YouAreNotShow",
  async (Avalue, { dispatch, rejectWithValue }) => {
    if (isOnline()) return { show: true, to: "YouAreNot" };
    return rejectWithValue();
  }
);

export const YouAreNotHide = createAsyncThunk(
  "Auth/YouAreNotHide",
  async (nothing) => ({
    show: true,
    to: TokenHandler.hasCryptedToken() ? "LogWithPin" : "Login",
  })
);

export const YouAreNotAction = createAsyncThunk(
  "Auth/YouAreNotAction",
  async ({ username, password }, { dispatch, rejectWithValue }) => {
    if (isOnline()) {
      return GetAuth().then((client) =>
        client
          .App_Center_API_Controllers_AuthController__getToken(
            {},
            {
              requestBody: {
                username: username,
                password: password,
                device_name: DeviceIdentityHandler.getDeviceName(),
                device_id: DeviceIdentityHandler.getUuid(),
              },
            }
          )
          .then((res) => {
            dispatch(Login({ username, password }));
            return {};
          })
          .catch((err) => {
            console.log(err);
            return rejectWithValue({
              code: err.status,
              errorContent: err.response.body,
            });
          })
      );
    } else {
      return rejectWithValue({ code: 0 });
    }
  }
);
