import { v4 as uuidv4 } from "uuid";
const UUIDKEY = "UuidKey";
const DEVICENAMEKEY = "NamedDeviceKey";
export const getUuid = () => {
  var uid = localStorage.getItem(UUIDKEY);
  if (uid === null || uid === "") uid = uuidv4();
  localStorage.setItem(UUIDKEY, uid);
  return uid;
};
export const setDeviceName = (name) =>
  name !== null && name !== "" ? localStorage.setItem(DEVICENAMEKEY, name) : "";
export const getDeviceName = () => localStorage.getItem(DEVICENAMEKEY);
export const hasDeviceName = () =>
  localStorage.getItem(DEVICENAMEKEY) !== null &&
  localStorage.getItem(DEVICENAMEKEY) !== "";

export const DeviceIdentityHandler = {
  getUuid,
  setDeviceName,
  getDeviceName,
  hasDeviceName,
};
