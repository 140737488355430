import { createAsyncThunk } from "@reduxjs/toolkit";
import { crypt, GetAuth, isOnline, TokenHandler } from "../../helper";
import { GeneraleSync, setSyncDuration } from "../Database/Synchro";

export const SetPin = createAsyncThunk(
  "Auth/SetPin",
  async (Pin, { dispatch, rejectWithValue, getState }) => {
    if (isOnline()) {
      return GetAuth(TokenHandler.getToken()).then((client) =>
        client
          .App_Center_API_Controllers_AuthController__getDeviceKey({}, {})
          .catch((err) => {
            rejectWithValue({ code: err.status });
          })
          .then((res) => {
            crypt.setCryptKey(res.body.key, Pin);
            crypt.StoreDecryptedKey(Pin);
            TokenHandler.setCryptedToken(TokenHandler.getToken(), Pin);
            if (getState().Auth.syncAfterPinSet) {
              const startTime = Date.now();
              dispatch(
                GeneraleSync({ WipeDatabase: false, wipeEverything: false })
              ).then(() => {
                dispatch(setSyncDuration(Date.now() - startTime));
              });
            }
            return { show: true, to: "Main", Access: true, sync: true };
          })
      );
    } else rejectWithValue({});
  }
);
