import { TokenHandler, GetSignupYears, DateHandler } from "../helper";
/**
 * * Function that return the Signup Table
 * @param {DexieDb} db
 * @returns {SignupTable}
 */
export const getSignupTable = (db) =>
  db.getDB().table(db.getTableName().SignupYears);

/**
 * * Function that return if the table has signup
 * @param {DexieDb} db
 * @returns {Promise/Boolean}
 */
export const hasSignup = (db) => {
  return getSignupTable(db)
    .toCollection()
    .count()
    .then((nbr) => (nbr > 0 ? true : false));
};

/**
 *  * Function that clear the Signup table
 * @param {DexieDb} db
 */
export const clearSignup = (db) => db.clearTable(db.getTableName().SignupYears);

/**
 * * Allow the user to get a SignupYear by it's id
 * @param {DexieDb} db
 * @param {Integer} signupId
 */
export const getOneSignup = async (db, signupId) => {
  return getSignupTable(db).where("id").equals(signupId).first();
};

/**
 * * Allow the user to get first signup
 * @param {DexieDb} db
 */
export const getFirstSignup = async (db) => {
  return getSignupTable(db).toCollection().first();
};
/**
 * * Allow the user to all first signup
 * @param {DexieDb} db
 */
export const getAllSignup = async (db) => {
  return getSignupTable(db).toCollection().toArray();
};

/**
 * * Fonction that allow to fill the Signup Table
 * @param {DexieDb} db
 * @param {ReduxDispatch} dispatch
 * @param {ReduxAction} StepDone
 */
export const tableSignupFiller = (db, dispatch, StepDone) => {
  return GetSignupYears(TokenHandler.getToken()).then((client) =>
    client
      .App_Center_API_Controllers_SignupYearsController__index()
      .then((res) => {
        var signupID = [];
        var AllPromise = [];
        return new Promise((resolveFiller, reject) => {
          res.body.data.forEach((element, index) => {
            signupID.push(element.id);
            AllPromise.push(
              db
                .insertOrUpdate(
                  element.id,
                  element,
                  db.getTableName().SignupYears
                )
                .then(() =>
                  dispatch(
                    StepDone({
                      key: "SignupYear",
                      action: "Récupération des années d'inscription",
                      index: index,
                      size: res.body.data.length,
                    })
                  )
                )
            );
          });
          Promise.all(AllPromise).then(() => resolveFiller(signupID));
        });
      })
  );
};

/**
 * * Permet a partir d'une db ainsi que d'un tableau contenant les id d'enregistrement de recevoir l'id de celle active
 * @param {DexieDb} db
 * @param {Array<Integer>} SignupArray
 */
export const getActiveSignup = async (db, SignupArray) => {
  return SignupArray.reduce(async (accumulator, currentValue) => {
    if (accumulator === 0) {
      var signup = await getOneSignup(db, currentValue);
      if (
        DateHandler.dateIsBetweenOrEqualDate(
          new Date(),
          DateHandler.DateStringToDate(signup.start_date),
          DateHandler.DateStringToDate(signup.end_date)
        )
      ) {
        return signup;
      }
      return 0;
    } else return accumulator;
  }, 0);
};

export const SchoolLevelEnum = {
  0: "None",
  2: "TPS",
  3: "PS",
  4: "MS",
  5: "GS",
  6: "CP",
  7: "CE1",
  8: "CE2",
  9: "CM1",
  10: "CM2",
  11: "6e",
  12: "5e",
  13: "4e",
  14: "3e",
  15: "2e",
  16: "1re",
  17: "Tle",
};

export const SignupHandler = {
  GetSignupTable: getSignupTable,
  HasSignup: hasSignup,
  ClearSignup: clearSignup,
  GetOneSignup: getOneSignup,
  TableSignupFiller: tableSignupFiller,
  GetActiveSignup: getActiveSignup,
  GetFirstSignup: getFirstSignup,
  SchoolLevelEnum,
  getAllSignup,
};
