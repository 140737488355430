import React from 'react'
import IcomoonSVG from '../../IcomoonSVG'
import VigilanceIcon from '../../VigilanceIcon'

const ModalChildPicto = ({
  isHandicaped,
  splitParents,
  hasPai,
  vigilances,
  showLabels = false,
}) => {
  return (
    <div className='child__tags'>
      {isHandicaped && <IcomoonSVG icon='handicap' />}
      {splitParents && <IcomoonSVG icon='transfert' />}
      {hasPai && <IcomoonSVG icon='pai' />}

      {vigilances?.map((vigilance, i) => (
        <VigilanceIcon key={i} vigilance={vigilance} showLabels={showLabels} />
      ))}
    </div>
  )
}

export default ModalChildPicto
