import React from "react";
import { connect } from "react-redux";
import ChampLogin from "./ChampLogin";
import LoginForm from "./LoginForm";
import { YouAreNotAction } from "../../actions/Auth/YouAreNot";
import { cleanString } from "../../helper";

export const YouAreNot = ({ Auth, ...props }) => {
  const [showPopUp, setShowPopUp] = React.useState(true);
  const [username, setUsername] = React.useState("");
  const [usernameError, setUsernameError] = React.useState(false);
  const [wrongname, setwrongname] = React.useState("");

  const hidePopUp = () => {
    if (cleanString(username) === cleanString(Auth.SessionData.username))
      setShowPopUp(false);
    else setwrongname("Le nom est incorrect, veuillez vérifier l'orthographe.");
  };
  const Action = (authValue) => {
    return YouAreNotAction(authValue);
  };

  return (
    <>
      {showPopUp && (
        <div className="grid__col12">
          <p>
            Êtes-vous sûr de ne pas être{" "}
            <strong>{Auth.SessionData ? Auth.SessionData.username : ""}</strong>{" "}
            ?
          </p>

          <p>
            Veuillez entrer le nom du précédent utilisateur ci-dessous.
          </p>

          <br />

          <ChampLogin
            id="username"
            name="Nom du précédent utilisateur"
            type="text"
            value={username}
            setValue={setUsername}
            error={usernameError}
            setError={setUsernameError}
            classN=""
            required
            placeholder={Auth.SessionData ? Auth.SessionData.username : ""}
          />

          <span className="form--errormsg">{wrongname ? wrongname : ""}</span>

          <div className="form__btns">
            <button className="btn btn--success" onClick={hidePopUp}>
              Oui, je suis sûr de ne pas être{" "}
              {Auth.SessionData ? Auth.SessionData.username : ""}
            </button>
            <button
              className="btn btn--error"
              onClick={() => (window.location.href = "/login")}
            >
              Non, je suis {Auth.SessionData ? Auth.SessionData.username : ""}
            </button>
          </div>
        </div>
      )}
      {!showPopUp && (
        <>
          <LoginForm Action="You are not" toDispatch={Action} />
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => state;

export default connect(mapStateToProps)(YouAreNot);
