import React from "react";
import ModalChildBodyGeneralDispParent from "./modalChildBodyDispParent";

const ModalChildBodyResponsables = ({ child, signup }) => {
  var Contacts = child.links.filter((contact) => contact.responsible);
  return (
    <div className="grid">
      <div className="grid__row">
        {Contacts.length > 0 ? (
          Contacts.map((contact) => (
            <ModalChildBodyGeneralDispParent key={contact.id} {...contact} />
          ))
        ) : (
          <p className="grid__col12">Aucun contact disponible</p>
        )}
      </div>
    </div>
  );
};

export default ModalChildBodyResponsables;
