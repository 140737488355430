import React from "react";

const IcomoonSVG = ({
    icon = "",
}) => {
    return(
        <svg className={`icon icon__${icon}`} focusable="false">
            <use href={process.env.PUBLIC_URL + "/picto/symbol-defs.svg?v=" + process.env.REACT_APP_VERSION + "#icon__" + icon}></use>
        </svg>
    );
};

export default IcomoonSVG;