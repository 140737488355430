import { configureStore } from "@reduxjs/toolkit";
import Auth from "../actions/Auth/Auth";
import Child from "../actions/Database/Child";
import Synchro from "../actions/Database/Synchro";
import Pointage from "../actions/Database/Pointage";
import Progress from "../actions/FillTheDatabase";
import serviceWorker from "../actions/serviceWorker";
import ShowModal from "../actions/showModal";
export default configureStore({
  reducer: {
    Auth,
    Child,
    Progress,
    ShowModal,
    Synchro,
    Pointage,
    serviceWorker,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
  devTools:
    process.env.NODE_ENV === "development" ||
    document.location.origin.includes("urlprovisoire")
      ? {
          shouldHotReload: false,
        }
      : false,
});
