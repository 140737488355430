import React, { useRef, useCallback } from "react";
import { connect } from "react-redux";
import { VariableSizeList as List } from "react-window";
import { useWindowResize } from "../../helper/useWindowResize";
import ChildrenDisplay from "./ChildrenDisplay";
import IcomoonSVG from "../IcomoonSVG";
import { filterChildSearch } from "../../helper/filterChild";

const ListChildren = ({ date, SpecificContent, Child, Pointage }) => {
  const listRef = useRef();
  const sizeMap = useRef({});
  const setSize = useCallback(
    (index, size) => {
      sizeMap.current = { ...sizeMap.current, [index]: size };
      listRef.current.resetAfterIndex(
        Child.findIndex((child) => child.id === index)
      );
    },
    [Child]
  );

  const getSize = (index) => sizeMap.current[Child[index]?.id] || 95;
  const [windowWidth, windowHeight] = useWindowResize();
  function isSortByFirstName() {
    var ii = 1;
    var verif = true;
    while (ii < Child.length && verif) {
      if ((Child[ii].first_name.toLowerCase() >= Child[ii - 1].first_name.toLowerCase()) === false) {
        verif = false;
      }
      ii+=1;
    }
    return verif;
  }
  return (
    <div>
      <List
        overscanCount={3}
        ref={listRef}
        height={
          windowHeight -
          (document.getElementById("navBottomPointage")?.offsetHeight || 0) -
          (document.getElementById("navTopBar")?.offsetHeight || 0)
        }
        width={windowWidth}
        itemCount={
          windowHeight / 95 > Child.length ? Child.length : Child.length + 1
        }
        itemSize={getSize}
      >
        {({ index, style }) => (
          <>
            {(index === Child.length && (
              <div className="child__line child__line--last" style={style}>
                <IcomoonSVG icon="hand-peace-o" />
                <br />
                Vous avez atteint la fin de la liste
              </div>
            )) || (
              <ChildrenDisplay
                style={style}
                key={`Child${Child[index].id}`}
                date={date}
                SpecificContent={SpecificContent}
                setSize={setSize}
                windowWidth={windowWidth}
                index={Child[index].id}
                even={index % 2}
                isSortByFirstName={isSortByFirstName()}
              />
            )}
          </>
        )}
      </List>
    </div>
  );
};

export default connect(({ Child, Pointage }) => ({
  Child: Child.filteredChild.filter(filterChildSearch(Pointage.search)),
  Pointage: Pointage
}))(ListChildren);
