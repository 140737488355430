import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAvailablePrestations,
  filterPrestation,
} from "../../helper/filterChild";
import { Db, ReservationHandler, crypt, DateHandler } from "../../helper";
import { setShowAddResa, setShowUpdateResa } from "../showModal";
import {
  dateIsBetweenOrEqualDate,
  dateStringToDate,
  dateToHourString,
  equalOne,
  formatTime,
  lowerOne,
  ordoTime,
  reduceTime,
  timeHasToBeBetween,
  timeIsBetweenOrEqualTime,
  timeIsLower,
  transformInputTime,
  upperOne,
} from "../../helper/date";
import _ from "lodash";
import { getLocationID } from "../../helper/useLocationId";

export const addReservation = createAsyncThunk(
  "Resa/Ajout",
  async ({ childId, prestaId = null, mainPresta = null, }, { dispatch, getState }) => {
    var { Pointage, Child } = getState();
    if (mainPresta === null && prestaId !== null) {
      // eslint-disable-next-line
      mainPresta = Pointage.Prestations.find(presta => presta.id == prestaId);
    }
    if (
      Pointage.Reservation.find(
        (resa) =>
          resa.child_id === childId &&
          !resa.is_deleted &&
          prestaId !== undefined &&
          resa.prestation_id === prestaId
      ) !== undefined
    )
      return;
    var child = Child.child.find((e) => e.id === childId);
    var availablePrestation = getAvailablePrestations(
      Pointage.Prestations,
      Pointage.momentID
    ).filter((value) =>
      prestaId
        ? Number(prestaId) === Number(value.id)
        : filterPrestation(
            value,
            child,
            true,
            Pointage.Signup,
            Pointage.date,
            Pointage.holidays
          )
      );
    var signup = child.signups.find(
      (value) =>
        value.year_id ===
        Pointage.Signup.find((signupG) =>
          dateIsBetweenOrEqualDate(
            new Date(Pointage.date),
            dateStringToDate(signupG.start_date),
            dateStringToDate(signupG.end_date)
          )
        ).id
    );
    if (availablePrestation.length === 0) return;
    if (availablePrestation.length === 1) {
      var dateId = null;
      if (Pointage.idDate !== -1 && mainPresta !== null && mainPresta !== undefined && mainPresta.possible_dates.length > 0) {
        dateId = mainPresta.possible_dates[Pointage.idDate].id;
      }
      var reservation = {
        childId: child.id,
        child_signup_id: signup.id,
        prestation_id: availablePrestation[0].id,
        prestation_type: availablePrestation[0].type,
        moment: availablePrestation[0].moment,
        date: DateHandler.FormatDate(new Date(Pointage.date)),
        location_id: (signup.affectations.length === 0
          ? signup.affectations[0]
          : signup.affectations.find(
              (affectation) => affectation.type === availablePrestation[0].type
            )
        )?.location_id,
        is_present: 1,
        arrived_at: availablePrestation[0] &&
                availablePrestation[0].autofill_hours ?
                  availablePrestation[0].start_time : undefined,
        departed_at: availablePrestation[0] &&
                availablePrestation[0].autofill_hours ?
                  availablePrestation[0].end_time : undefined,
        time_update_d: true,
        time_update_a: true,
        date_id: dateId,
      };
      if (!availablePrestation[0].secondary)
        gestionTempsArriverDepart(reservation, Pointage);
      return ReservationHandler.insertReservation(
        new Db(crypt.getSessionDecryptedKey()),
        reservation
      );
    } else {
      dispatch(
        setShowAddResa({
          show: true,
          resaData: {
            childId: child.id,
            childSignupId: signup.id,
            prestaAvailable: availablePrestation.filter(
              (presta) => !presta.secondary
            ),
          },
        })
      );
    }
  },
  {
    condition: (undef, { getState }) => {
      const { Pointage } = getState();
      return !Pointage.Pending;
    },
  }
);

export const delReservation = createAsyncThunk(
  "Resa/Suppression",
  async ({ resaId }, { dispatch, getState }) => {
    var { Pointage, Child } = getState();
    var resa = Pointage.Reservation.find(
      (reservation) => reservation.id === resaId
    );
    if (!resa) return;
    if (resa.is_unexpected && resa.is_add)
      ReservationHandler.delResaNotPushed(
        new Db(crypt.getSessionDecryptedKey()),
        resaId
      );
    else
      ReservationHandler.delResaPushed(new Db(crypt.getSessionDecryptedKey()), {
        id: resaId,
        location_id: resa.location_id,
        prestation_id: resa.prestation_id,
        prestation_date: resa.prestation_date,
        child_signup_id: Child.filteredChild
          .find((child) => child.id === resa.child_id)
          .signups.find(
            (value) =>
              value.year_id ===
              Pointage.Signup.find((signupG) =>
                dateIsBetweenOrEqualDate(
                  new Date(Pointage.date),
                  dateStringToDate(signupG.start_date),
                  dateStringToDate(signupG.end_date)
                )
              ).id
          ).id,
      });
    return {
      resaId,
      childId: resa.child_id,
      secondary: Pointage.Prestations.find(
        (presta) => Number(presta.id) === Number(resa.prestation_id)
      ).secondary,
    };
  },
  {
    condition: ({ force }, { getState }) => {
      const { Pointage } = getState();
      if (force) return true;
      return !Pointage.Pending;
    },
  }
);

export const updateReservation = createAsyncThunk(
  "Resa/Update",
  async ({ resaId, newPrestaId, resa }, { dispatch, getState }) => {
    var { Pointage, Child } = getState();
    var newPresta = Pointage.Prestations.find(
      (presta) => Number(presta.id) === Number(newPrestaId)
    );
    var oldResa = resa
      ? resa
      : Pointage.Reservation.find((resa) => resa.id === resaId);
    var newResa = {
      ...oldResa,
      prestation_id_old:
        oldResa.prestation_id_old === undefined
          ? oldResa.prestation_id
          : oldResa.prestation_id_old,
      prestation_type_old:
        oldResa.prestation_type_old === undefined
          ? oldResa.prestation_type
          : oldResa.prestation_type_old,
      prestation_moment_old:
        oldResa.prestation_moment_old === undefined
          ? oldResa.prestation_moment
          : oldResa.prestation_moment_old,
      prestation_id: newPresta.id,
      prestation_type: newPresta.type,
      prestation_moment: newPresta.moment,
      child_signup_id:
        oldResa.child_signup_id ||
        Child.filteredChild
          .find((child) => child.id === oldResa.child_id)
          .signups.find(
            (value) =>
              value.year_id ===
              Pointage.Signup.find((signupG) =>
                dateIsBetweenOrEqualDate(
                  new Date(Pointage.date),
                  dateStringToDate(signupG.start_date),
                  dateStringToDate(signupG.end_date)
                )
              ).id
          ).id,
      presta_change: true,
    };
    if (newResa.prestation_id === newResa.prestation_id_old) {
      newResa = {
        ...newResa,
        prestation_id_old: undefined,
        prestation_type_old: undefined,
        prestation_moment_old: undefined,
        presta_change: undefined,
      };
    }
    ReservationHandler.UpdateResa(
      new Db(crypt.getSessionDecryptedKey()),
      newResa
    );
    return {
      resa: newResa,
    };
  }
);

const processUpdatePresence = (
  Reservation,
  presenceId,
  resaId,
  dispatch,
  arrivedAt,
  departedAt,
  Prestations = null,
  Pointage = null,
  child = null,
  action = null,
  numRecalcul = null,
  momentID = null,
  modifAuto = false,
) => {
  var resa = Reservation.find((resaFind) => resaFind.id === resaId);
  var newPresence = presenceId === "2" ? null : Number(presenceId);
  var presta = resa.prestation_id && Prestations && Prestations.find(prestation => prestation.id === resa.prestation_id);
  if (newPresence === resa.is_present) return;
  if (resa.is_unexpected && (newPresence === null || newPresence === 0)) {
    dispatch(delReservation({ resaId: resaId, force: true }));
    return [{ del: true }, 0];
  }
  var oldPresence = resa.is_present;

  // Pour les horaires :
  // Si la presta est une restauration ou a le remplissage automatique des heures, l'heure d'arrivée et de départ sont les horaires limites des prestas
  // Sinon, si arrivedAt est undefined, on garde l'ancienne valeur de la resa, sinon, on met à jour l'heure
  var arrived_at = presta &&
    presta.autofill_hours ?
    (
      (presta.possible_dates.length > 0 &&
      presta.possible_dates[Pointage?.idDate].start_time !== null) ?
        presta.possible_dates[Pointage?.idDate].start_time :
        presta.start_time
    ) :
    (arrivedAt === undefined || arrivedAt === "") ?
        resa.arrived_at :
        arrivedAt;

  var departed_at = presta &&
    presta.autofill_hours ?
      (
        (presta.possible_dates.length > 0 &&
        presta.possible_dates[Pointage?.idDate].end_time !== null) ?
          presta.possible_dates[Pointage?.idDate].end_time :
          presta.end_time
      ) :
      (departedAt === undefined || departedAt === "") ?
          resa.departed_at :
          departedAt;
  resa = {
    ...resa,
    is_present: newPresence,
    old_presence: resa.is_updated ? resa.old_presence : oldPresence,
    is_updated: resa.is_updated || oldPresence !== newPresence,
    arrived_at: arrived_at,
    departed_at: departed_at,
    time_update_d: true,
    time_update_a: true,
  };

  // Mise à jour de la prestation en fonction de la présence
  if (presenceId === 1 && action === "global" && modifAuto) {
    var prestaActive = getPrestaActive(Prestations, resa);
    // On recherche toutes les prestations disponibles
    var allPrestaAvailable = getAvailablePrestations(
      Prestations,
      momentID
    ).filter((value) =>
      filterPrestation(
        value,
        child.find((child) => child.id === resa.child_id),
        false,
        Pointage.Signup,
        Pointage.date,
        Pointage.holidays
      )
    );
    // Suivant le choix du centre, effectué différents cas
    var prestas = getReducePrestaAvailable(resa, prestaActive, numRecalcul, Pointage, allPrestaAvailable);
    // Si aucune presta n'a été trouvé (les plages horaires ne correspondent pas aux presta, on modifie l'horaire des resas et on refait un test)
    if (prestas === undefined) {
      var falseHourResa = getPrestaAutherAvailable(allPrestaAvailable, resa);
      prestas = getReducePrestaAvailable(falseHourResa, prestaActive, numRecalcul, Pointage, allPrestaAvailable);
    }
    if (prestas !== undefined) {
      resa = {
        ...resa,
        prestation_id: prestas[1][0].id,
        presta_change: resa.prestation_id !== prestas[1][0].id
      };
    }
  }

  return [resa, oldPresence];
};

const getReducePrestaAvailable = (hourResa, prestaActive, numRecalcul, Pointage, allPrestaAvailable) => {
  var prestas;
  // Suivant le choix du centre, effectué différents cas
  if ([null, 1].includes(numRecalcul)) {
    if (resaIsSupToPresta(hourResa, prestaActive)) {
      prestas = reducePrestaAvailable(allPrestaAvailable, hourResa, lowerOne, Pointage);
    }
  }
  if ([2].includes(numRecalcul)) {
    if (resaIsInfToPresta(hourResa, prestaActive)) {
      prestas = reducePrestaAvailable(allPrestaAvailable, hourResa, upperOne, Pointage);
    }
  }
  if ([3].includes(numRecalcul)) {
    if (resaIsSupToPresta(hourResa, prestaActive) || resaIsInfToPresta(hourResa, prestaActive)) {
      if (resaIsInfToPresta(hourResa, prestaActive)) {
        prestas = reducePrestaAvailable(allPrestaAvailable, hourResa, lowerOne, Pointage);
      } else {
        prestas = reducePrestaAvailable(allPrestaAvailable, hourResa, upperOne, Pointage);
      }
    } else {
      prestas = reducePrestaAvailable(allPrestaAvailable, hourResa, equalOne, Pointage);
    }
  }
  return prestas;
}

const processUpdateAbsence = (
  Reservation,
  Pointage,
  presenceId,
  resaId,
  dispatch,
  motif,
) => {
  var resa = Reservation.find((resaFind) => resaFind.id === resaId);
  var newPresence = presenceId === "2" ? null : Number(presenceId);
  if (newPresence === resa.is_present) return;
  if (resa.is_unexpected && (newPresence === null || newPresence === 0)) {
    dispatch(delReservation({ resaId: resaId, force: true }));
    return [{ del: true }, 0];
  }
  resa = {
    ...resa,
    is_present: newPresence,
    absence:
        motif === "reset"
          ? null
          : {
              absence_type_id: Pointage.absence.find(
                (absence) => absence.id === Number(motif)
              ).id,
            },
  };
  return resa;
};

const processUpdatePresenceSecondary = (
  { Reservation, Prestations },
  oldPresence,
  presenceId,
  dispatch,
  childId
) => {
  var secondary = [];
  Reservation.filter(
    (reservation) =>
      reservation.child_id === childId &&
      Prestations.find((presta) => presta.id === reservation.prestation_id)
        .secondary &&
      (reservation.is_present === null ||
        reservation.is_present === oldPresence)
  ).forEach((reservation) => {
    var treatedREsa = processUpdatePresence(
      Reservation,
      presenceId,
      reservation.id,
      dispatch
    );
    if (treatedREsa === undefined || treatedREsa[0].del) return;
    secondary.push({ ...treatedREsa[0], is_modified: 1 });
  });
  return secondary;
};
export const updatePresence = createAsyncThunk(
  "Resa/UpdatePresence",
  async (
    { presenceId, resaId, arrivedAt, departedAt, db, ActionGlobal },
    { dispatch, getState }
  ) => {
    var { Pointage } = getState();
    var [resa, oldPresence] = processUpdatePresence(
      Pointage.Reservation,
      presenceId,
      resaId,
      dispatch,
      arrivedAt,
      departedAt,
      Pointage.Prestations,
      Pointage,
    );
    var prestation = Pointage.Prestations.find(presta => presta.id === resa.prestation_id);
    if (prestation.autofill_hours && Pointage.idDate !== -1) {
      var start = prestation.possible_dates.length > 0 ?
        prestation.possible_dates[Pointage.idDate].start_time :
        prestation.start_time;
      var end = prestation.possible_dates.length > 0 ?
        prestation.possible_dates[Pointage.idDate].end_time :
        prestation.end_time;
      resa.arrived_at = start ?
      (new Date(start) === 'Invalid Date' ?
        (new Date(start).getHours() - 2 + ":" +
        (new Date(start).getMinutes() < 10 ? "0" : "") +
        new Date(start).getMinutes() + ":" +
        (new Date(start).getSeconds() < 10 ? "0" : "") +
        new Date(start).getSeconds()) : start) :
      prestation.start_time;
      resa.departed_at = end ?
      (new Date(end) === 'Invalid Date' ?
        (new Date(end).getHours() - 2 + ":" +
        (new Date(end).getMinutes() < 10 ? "0" : "") +
        new Date(end).getMinutes() + ":" +
        (new Date(end).getSeconds() < 10 ? "0" : "") +
        new Date(end).getSeconds()) : end) :
        prestation.end_time;
      resa.time_update_d = true;
      resa.time_update_a = true;
    }
    if (resa.del === true) return;
    var dataBase = db ? db : new Db(crypt.getSessionDecryptedKey());
    var secondary = [];

    if (
      !Pointage.Prestations.find((presta) => presta.id === resa.prestation_id)
        .secondary
    ) {
      gestionTempsArriverDepart(resa, Pointage);
      secondary = processUpdatePresenceSecondary(
        Pointage,
        oldPresence,
        presenceId,
        dispatch,
        resa.child_id
      );
    }

    if (secondary.length > 0 && !ActionGlobal) {
      secondary.forEach((treatedResa) => {
        ReservationHandler.UpdateResa(dataBase, treatedResa);
      });
    }
    if (!ActionGlobal) ReservationHandler.UpdateResa(dataBase, resa);

    return {
      resa: resa,
      multipleResa: secondary,
    };
  }
);

const isHasHourly = (resa, arrivedAt, departedAt) => {
  // Définition du temps grace aux inputs
  resa.arrived_at = arrivedAt === null ? null : resa.arrived_at;
  resa.departed_at = departedAt === null ? null : resa.departed_at;
  resa.time_update = resa.time_update_a || resa.time_update_d;

  return (
    resa.arrived_at !== undefined &&
    resa.arrived_at !== null &&
    resa.arrived_at !== "" &&
    resa.departed_at !== undefined &&
    resa.departed_at !== null &&
    resa.departed_at !== "" &&
    timeIsLower(resa.arrived_at, resa.departed_at) &&
    ![6, 8, 10].some((id) => getLocationID(document.location).includes(id))
  );
}
const getPrestaActive = (Prestations, resa) => {
  return Prestations.find(
    (presta) => presta.id === resa.prestation_id
  );
}
const resaIsSupToPresta = (resa, presta) => {
  // Si le temps pointé est supérieur à la presta Active
  return (timeIsLower(
    formatTime(resa.arrived_at),
    formatTime(presta.start_time)
  ) || timeIsLower(
    formatTime(presta.end_time),
    formatTime(resa.departed_at)
  ))
}
const resaIsInfToPresta = (resa, presta) => {
  // Si le temps pointé est inférieur à la presta Active
  return (timeIsLower(
    formatTime(presta.start_time),
    formatTime(resa.arrived_at)
  ) || timeIsLower(
    formatTime(resa.departed_at),
    formatTime(presta.end_time)
  ))
}
const getPrestaAvailable = (allPrestaAvailable, resa, Pointage) => {
   // On filtre les prestas qui nous intéressent
   return allPrestaAvailable.filter(
    (value) =>
      // Le temps doit être compris dans la plage horaire de la presta
      timeIsBetweenOrEqualTime(
        // Le temps doit être entre les horaires du centre
        timeHasToBeBetween(
          // Réservation de l'enfant mise à jour - Heure d'arrivée (16:30)
          formatTime(resa.arrived_at),
          // Heure du début possible de la prestation (6:00)
          formatTime(Pointage.prestaLimit.startTime),
          // Heure de fin possible de la prestation (19:00)
          formatTime(Pointage.prestaLimit.endTime)
        ),
        formatTime(value.start_time),
        formatTime(value.end_time)
      ) &&
      // Si l'heure de départ est comprise dans la plage horaire de la presta, il faut que l'heure de départ soit également comprise dans celle ci
      timeIsBetweenOrEqualTime(
        timeHasToBeBetween(
          formatTime(resa.departed_at),
          formatTime(Pointage.prestaLimit.startTime),
          formatTime(Pointage.prestaLimit.endTime)
        ),
        formatTime(value.start_time),
        formatTime(value.end_time)
      )
  );
}
const getPrestaAutherAvailable = (allPrestaAvailable, resa) => {
  var hour_presta_big = allPrestaAvailable[0].end_time;
  var hour_presta_small = allPrestaAvailable[0].start_time;
  var resa_departed_at = resa.departed_at;
  var resa_arrived_at = resa.arrived_at;

  for(let ii = 1; ii<allPrestaAvailable.length; ii += 1) {
    // Obtenir la presta avec l'heure de fin la plus haute, ainsi que l'heure
    if (timeIsLower(hour_presta_big, allPrestaAvailable[ii].end_time))  {
      hour_presta_big = allPrestaAvailable[ii].end_time;
    }
    // Obtenir la presta avec l'heure de fin la plus basse, ainsi que l'heure
    if (timeIsLower(allPrestaAvailable[ii].start_time, hour_presta_small))  {
      hour_presta_small = allPrestaAvailable[ii].start_time;
    }
  }

  // Si l'heure de resa est supérieur à l'heure la plus haute, on la modifie
  if (timeIsLower(hour_presta_big, resa_departed_at)) {
    resa_departed_at = hour_presta_big;
  }
  // Si l'heure de resa est inférieur à l'heure la plus basse, on la modifie
  if (timeIsLower(resa_arrived_at, hour_presta_small)) {
    resa_arrived_at = hour_presta_small;
  }
  // On ne garde que prestas qui sont comprises dans le lot
  return {arrived_at: resa_arrived_at, departed_at: resa_departed_at};
}
const reducePrestaAvailable = (allPrestaAvailable, resa, fctn, Pointage) => {
  var availablePresta = getPrestaAvailable(allPrestaAvailable, resa, Pointage);
  if (availablePresta.length > 0) {
    return Object.entries(
      Object.entries(availablePresta.reduce(ordoTime, {})).reduce(
        fctn
      )[1]
    ).reduce(reduceTime());
  }
}

export const updateHorairePresence = createAsyncThunk(
  "Resa/UpdateHoraireDePresence",
  async ({ resaId, arrivedAt, departedAt }, { dispatch, getState }) => {
    var { Auth, Pointage, Child } = getState();
    var resa = Pointage.Reservation.find((resaFind) => resaFind.id === resaId);
    resa = {
      ...resa,
      arrived_at:
        arrivedAt !== undefined && arrivedAt !== null
          ? transformInputTime(arrivedAt)
          : undefined,
      departed_at:
        departedAt !== undefined && departedAt !== null
          ? transformInputTime(departedAt)
          : undefined,
      time_update_d: resa.time_update_d
        ? true
        : departedAt === null ||
          (departedAt !== undefined &&
            transformInputTime(departedAt) !== resa.departed_at),
      time_update_a: resa.time_update_a
        ? true
        : arrivedAt === null ||
          (arrivedAt !== undefined &&
            transformInputTime(arrivedAt) !== resa.arrivedAt),
    };

    // Si aucune valeurs de la réservation est nulle
    if (isHasHourly(resa, arrivedAt, departedAt)) {
      // Définition de la valeur de prestation prédéfinitivement inscrit
      var prestaActive = getPrestaActive(Pointage.Prestations, resa);

      // On recherche toutes les prestations disponibles
      var allPrestaAvailable = getAvailablePrestations(
        Pointage.Prestations,
        Pointage.momentID
      ).filter((value) =>
        filterPrestation(
          value,
          Child.child.find((child) => child.id === resa.child_id),
          false,
          Pointage.Signup,
          Pointage.date,
          Pointage.holidays
        )
      );

      var prestas = getReducePrestaAvailable(resa, prestaActive, Auth.SessionData.RootRecalculPresta, Pointage, allPrestaAvailable);

      if (
        prestas &&
        !(prestas[1].length === 1 && prestas[1][0].id === prestaActive.id)
      ) {
        dispatch(
          setShowUpdateResa({
            show: true,
            childId: resa.child_id,
            serverAsked: true,
            isLinkHoraire: true,
            resa: resa,
            prestaSuggest: prestas[1][0],
            ResaId: allPrestaAvailable.map((value) => value.id)
          })
        );
      }
    }
    ReservationHandler.UpdateResa(new Db(crypt.getSessionDecryptedKey()), resa);
    return {
      resa: resa,
    };
  }
);

export const updateAbsence = createAsyncThunk(
  "Resa/UpdateAbsence",
  async ({ resaId, motif }, { dispatch, getState }) => {
    var { Pointage } = getState();
    var resa = Pointage.Reservation.find((resaFind) => resaFind.id === resaId);
    resa = {
      ...resa,
      is_updated: 1,
      absence:
        motif === "reset"
          ? null
          : {
              absence_type_id: Pointage.absence.find(
                (absence) => absence.id === Number(motif)
              ).id,
            },
    };
    ReservationHandler.UpdateResa(new Db(crypt.getSessionDecryptedKey()), resa);
    return {
      resa: resa,
    };
  }
);
export const filterResaForWorkGlobal =
  (PointageGlobalAction, filteredChild, Prestations) => (resa) => (PointageGlobalAction.resetPointage
      ? resa.is_present !== null
      : resa.is_present === null) &&
    filteredChild.findIndex((value) => value.id === resa.child_id) !== -1 &&
    !(
      (PointageGlobalAction.allPresent && resa.is_present === 1) ||
      (PointageGlobalAction.allAbsent && resa.is_present === 0) ||
      (PointageGlobalAction.resetPointage && resa.is_present === null)
    ) &&
    !Prestations.find((presta) => presta.id === resa.prestation_id).secondary &&
    resaMatch(resa);

export const resaMatch = (resa) => ([6, 8, 10].some((id) => getLocationID(document.location).includes(id)) &&
  String(document.location.pathname.split("/")[3]) ===
    String(resa.prestation_id)) ||
![6, 8, 10].some((id) => getLocationID(document.location).includes(id))

export const actionGlobal = createAsyncThunk(
  "Resa/ActionGlobal",
  async ({ action, arrivedAt, departedAt, motifAbs, modifAuto, numRecalcul }, { getState, dispatch }) => {
    dispatch(setShowUpdateResa(false));
    var {
      Pointage: { Reservation, Prestations, momentID, ...Pointage },
      Child: { filteredChild, child },
    } = getState();
    var presenceId =
      (action.allAbsent && 0) ||
      (action.allPresent && 1) ||
      (action.resetPointage && "2");
    var allResa = [];
    var dataBase = new Db(crypt.getSessionDecryptedKey());
    Reservation.filter(
        filterResaForWorkGlobal(action, filteredChild, Prestations)
      ).forEach((resa) => {
      resa = _.cloneDeep(resa);
      var secondary = [];

      var [reservation, oldPresence] = processUpdatePresence(
        Reservation,
        presenceId,
        resa.id,
        dispatch,
        arrivedAt,
        departedAt,
        Prestations,
        Pointage,
        child,
        action="global",
        numRecalcul,
        momentID,
        modifAuto
      );
      if (motifAbs && motifAbs !== "none") {
        reservation = processUpdateAbsence(
          Reservation,
          Pointage,
          presenceId,
          resa.id,
          dispatch,
          motifAbs,
        );
      }

      if (
        !Prestations.find((presta) => presta.id === resa.prestation_id)
          .secondary
      ) {
        gestionTempsArriverDepart(resa, {
          Reservation,
          Prestations,
          ...Pointage,
        });
        secondary = processUpdatePresenceSecondary(
          { Reservation, Prestations },
          oldPresence,
          presenceId,
          dispatch,
          resa.child_id
        );
      }

      secondary.push({
        ...reservation,
        is_modified: 1,
        time_update_a: arrivedAt !== undefined,
        time_update_d: departedAt !== undefined,
        time_update: arrivedAt !== undefined || departedAt !== undefined,
      });
      allResa = allResa.concat(secondary);
    });
    dataBase.insertLotsOfData(allResa, dataBase.getTableName().Reservations);
    return {
      allResa: allResa,
    };
  }
);

export const timeCallBack = async (callback, indicator) => {
  var start = window.performance.now();
  console.log(start);
  var retour = await callback();
  var end = window.performance.now();
  console.log(end)
  return retour;
};

export const gestionTempsArriverDepart = (resa, Pointage) => {
  if (resa.is_present === 1 && !getLocationID(document.location).includes(6)) {
    var date = new Date();
    // Uniquement sur le périscolaire on met les heures automatiquement
    if (
      Pointage.prestationsType.includes(1) &&
      Pointage.momentID !== 1
    ) {
      var prestaActive = Pointage.Prestations.find(
        (presta) => presta.id === resa.prestation_id
      );
      if (Pointage.momentID === 2) {
        resa.time_update_a = !resa.time_update_a
          ? resa.arrived_at === undefined || resa.arrived_at === ""
          : resa.time_update_a;
        resa.time_update_d = !resa.time_update_d
          ? resa.departed_at === undefined || resa.departed_at === ""
          : resa.time_update_d;
        resa.arrived_at =
          resa.arrived_at === undefined || resa.arrived_at === ""
            ? dateToHourString(date)
            : resa.arrived_at;
        resa.departed_at =
          resa.departed_at === undefined || resa.departed_at === ""
            ? prestaActive.end_time
            : resa.departed_at;
      } else if (Pointage.momentID === 3) {
        resa.time_update_a = !resa.time_update_a
          ? resa.arrived_at === undefined || resa.arrived_at === ""
          : resa.time_update_a;
        resa.time_update_d = !resa.time_update_d
          ? resa.departed_at === undefined || resa.departed_at === ""
          : resa.time_update_d;
        resa.arrived_at =
          resa.arrived_at === undefined || resa.arrived_at === ""
            ? prestaActive.start_time
            : resa.arrived_at;
        resa.arrived_at =
          resa.departed_at === undefined || resa.departed_at === ""
            ? prestaActive.end_time
            : resa.departed_at;
      } else if (Pointage.momentID === 4) {
        resa.time_update_d = !resa.time_update_d
          ? resa.departed_at === undefined || resa.departed_at === ""
          : resa.time_update_d;
        resa.arrived_at =
          resa.arrived_at === undefined || resa.arrived_at === ""
            ? prestaActive.start_time
            : resa.arrived_at;
      } else if (Pointage.momentID === 5) {
        resa.time_update_d = !resa.time_update_d
          ? resa.departed_at === undefined || resa.departed_at === ""
          : resa.time_update_d;
        resa.arrived_at =
          resa.arrived_at === undefined || resa.arrived_at === ""
            ? prestaActive.start_time
            : resa.arrived_at;
      }
    } else if (
      new Date(Pointage.date).getDay() !== 3 ||
      new Date(Pointage.date).getDay() !== 6 ||
      Pointage.prestationsType.includes(3)
    ) {
      if (Pointage.prestationsType.includes(5) && Pointage.momentID === 3) {
        resa.arrived_at = Pointage.prestaLimit.startTime;
        resa.departed_at = Pointage.prestaLimit.endTime;
      } else {
        resa.time_update_a = !resa.time_update_a
          ? resa.arrived_at === undefined || resa.arrived_at === ""
          : resa.time_update_a;
        resa.arrived_at =
          resa.arrived_at === undefined || resa.arrived_at === ""
            ? dateToHourString(date)
            : resa.arrived_at;
      }
    }
    resa.time_update = true;
  }
};
