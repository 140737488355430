import { createAsyncThunk } from "@reduxjs/toolkit";
import { crypt, TokenHandler } from "../../helper";

export const Lock = createAsyncThunk(
  "Auth/Disconnect",
  async (Avalue, { dispatch }) => {
    TokenHandler.ResetToken();
    crypt.resetSessionDecryptedKey();
    sessionStorage.clear();
    window.location.reload();
    return { Access: false, show: true, to: "LogWithPin" };
  }
);
