import { TokenHandler, GetClosingDays } from "../helper";

/**
 * * Function that return the ClosingDay table
 * @param {DexieDb} db
 */
export const getClosingTable = (db) =>
  db.getDB().table(db.getTableName().ClosingDay);

/**
 *  * Function that clear the ClosingDay table
 * @param {DexieDb} db
 */
export const clearClosingDay = (db) =>
  db.clearTable(db.getTableName().ClosingDay);

/**
 * * Function that get the ClosingDAy
 * @param {DexieDb} db
 * @param {DispatchAction} action
 * @param {SwaggerApi} client
 */
const closingDayFiller = (db, action, client) => {
  return client
    .App_Center_API_Controllers_ClosingDaysController__index()
    .then((res) => res.body)
    .then(({ data }) => {
      return data.forEach((date, Index) => {
        return parseAndPush(
          db,
          date,
          {
            isPHolidays: 0,
            isSHolidays: 0,
          },
          action,
          Index,
          data.length
        );
      });
    });
};

/**
 * * Function that get the public holiday
 * @param {DexieDb} db
 * @param {DispatchAction} action
 * @param {SwaggerApi} client
 */
const closingDayPublicHolidayFiller = (db, action, client) => {
  return client
    .App_Center_API_Controllers_HolidaysController__getPublicHolidays()
    .then((res) => res.body)
    .then(({ data }) => {
      return data.forEach((date, Index) => {
        return parseAndPush(
          db,
          date,
          {
            isPHolidays: 1,
            isSHolidays: 0,
          },
          action,
          Index,
          data.length
        );
      });
    });
};

/**
 * * Function that get the school holiday
 * @param {DexieDb} db
 * @param {DispatchAction} action
 * @param {SwaggerApi} client
 */
const closingDaySchoolHolidayFiller = (db, action, client) => {
  return client
    .App_Center_API_Controllers_HolidaysController__getSchoolHolidays()
    .then((res) => res.body)
    .then(({ data }) => {
      return data.forEach((date, Index) => {
        return parseAndPush(
          db,
          date,
          {
            isPHolidays: 0,
            isSHolidays: 1,
          },
          action,
          Index,
          data.length
        );
      });
    });
};

/**
 * * Insert the Day in database
 * @param {DexieDb} db
 * @param {ClosingDay} data
 * @param {TypeHoliday} baseObject
 * @param {DispatchAction} action
 * @param {Number} Index
 * @param {Number} length
 */
const parseAndPush = (db, data, baseObject, action, Index, length) => {
  return db
    .insertOrUpdate(
      null,
      { ...baseObject, ...data },
      db.getTableName().ClosingDay
    )
    .then(() => {
      action(Index, length);
    });
};

/**
 * * Function that fill the closing day database
 * @param {DexieDb} db
 * @param {Dispatch} dispatch
 * @param {Action} StepDone
 */
export const tableClosingDayFiller = (db, dispatch, StepDone) => {
  const throwingDispatch = (index, size) => {
    dispatch(
      StepDone({
        key: "closingDay",
        action: "Récupération des jours de fermeture",
        index: index,
        size: size,
      })
    );
  };
  return GetClosingDays(TokenHandler.getToken()).then((client) => {
    return Promise.all([
      closingDayFiller(db, throwingDispatch, client),
      closingDaySchoolHolidayFiller(db, throwingDispatch, client),
      closingDayPublicHolidayFiller(db, throwingDispatch, client),
    ]);
  });
};

export const getHolidays = (db, Public = false) => {
  return getClosingTable(db)
    .where(Public ? "isPHolidays" : "isSHolidays")
    .equals(1)
    .toArray();
};

export const ClosingDayHandler = {
  ClearClosingDay: clearClosingDay,
  TableClosingDayFiller: tableClosingDayFiller,
  GetHolidays: getHolidays,
};
