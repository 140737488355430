export const isIos = () =>
  /ipad|iphone|ipod/.test(navigator.userAgent.toLowerCase()) &&
  !window.MSStream;
export const isAndroid = () =>
  /android/.test(navigator.userAgent.toLowerCase());
export const isNotAndroidOrIOS = () => !isIos() && !isAndroid();

export const DeviceHandler = {
  isIos,
  isAndroid,
  isNotAndroidOrIOS,
};
