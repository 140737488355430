import React, { useEffect } from "react";
import { connect } from "react-redux";
import { FetchAllChild } from "../../actions/Database/Child";
import { getBuses, getSignup } from "../../actions/Database/Pointage";
import ListDossier from "./ListDossier";
const Dossier = ({ dispatch, Child, Auth }) => {
  useEffect(() => {
    if (Auth.Access) {
      dispatch(getSignup()).then(() =>
        dispatch(FetchAllChild())
      );
      dispatch(getBuses());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Child.child, Auth.Access]);
  return (
    <div className="ChildContainer">
      {Child.Pending === false && Child.child.length === 0 && (
        <h3>Aucun enfant n'a été trouvé</h3>
      )}
      <div className={Child.Pending ? "ChildTable Loading" : "ChildTable"}>
        <ListDossier
          date={new Date().toString()}
        />
      </div>
    </div>
  );
};

export default connect((state) => ({ Child: state.Child, Auth: state.Auth }))(
  Dossier
);
