import React, { useState } from "react";
import { connect } from "react-redux";
import { forceUpgrade, validateInstall } from "../../actions/serviceWorker";
import { showDeviceName } from "../../actions/showModal";
import { DeviceHandler } from "../../helper";
import { getDeviceName, hasDeviceName } from "../../helper/uuid";
import Modal from "./Modal";

const ModalInfo = ({
  info,
  hideInfo,
  canBeInstalled,
  SessionData,
  dispatch,
  error,
}) => {
  const [state, setState] = useState(false);
  return (
    <Modal show={info} hide={hideInfo} label="Informations">
      {(error.show || (canBeInstalled === true && state)) && (
        <div className="subBar subBar--error">
          {error.show && <p>{error.why}</p>}
          {state && (
            <p>Une erreur s'est produite, veuillez recharger la page.</p>
          )}
        </div>
      )}

      <div>
        <h4>Votre installation : </h4>
        {SessionData.NomCentre !== undefined && (
          <>
            <span className="tag tag--primary tag--small">
              {SessionData.NomCentre}
            </span>
            &nbsp;
          </>
        )}
        {hasDeviceName() && (
          <>
            <span className="tag tag--primary tag--small">
              Nom du périphérique : {getDeviceName()}
            </span>
            &nbsp;
          </>
        )}
        <span className="tag tag--primary tag--small">
          version {process.env.REACT_APP_VERSION}
        </span>
      </div>

      <br />
      <h4>Actions possibles : </h4>
      <div className="form__btns form__btns--lowmargin">
        {"serviceWorker" in navigator && (
          <button
            onClick={() => dispatch(forceUpgrade())}
            className="btn btn--secondary btn--small"
          >
            <svg className="icon icon__update" focusable="false">
              <use
                href={
                  process.env.PUBLIC_URL + "/picto/symbol-defs.svg#icon__update"
                }
              ></use>
            </svg>
            Rechercher une mise à jour
          </button>
        )}

        {canBeInstalled === true && (
          <>
            <button
              className="btn btn--secondary btn--small"
              onClick={() => {
                dispatch(validateInstall()).then((status) => {
                  if (status === false) {
                    setState(true);
                  }
                });
              }}
            >
              <svg className="icon icon__download" focusable="false">
                <use
                  href={
                    process.env.PUBLIC_URL +
                    "/picto/symbol-defs.svg#icon__download"
                  }
                ></use>
              </svg>
              Installer l'application
            </button>
          </>
        )}

        <button
          className="btn btn--secondary btn--small"
          onClick={() => {
            dispatch(showDeviceName())
            dispatch(hideInfo())
          }}
        >
          <svg className="icon icon__pen" focusable="false">
            <use
              href={
                process.env.PUBLIC_URL +
                "/picto/symbol-defs.svg#icon__pen"
              }
            ></use>
          </svg>
          Modifier le nom du périphérique
        </button>
      </div>
      {canBeInstalled === false && (
        <p className="small color--error">
          <br />
          L'installation de l'application n'est pas disponible sur votre
          périphérique soit parce qu'elle est déjà installée, soit parce que
          votre périphérique n'est pas compatible.
          {!("serviceWorker" in navigator) &&
            " De plus le périphérique que vous utilisez ne vous ne permet pas travailler en mode déconnecté."}
        </p>
      )}
      <br />
      <h4>Plus : </h4>
      <ul>
        {(DeviceHandler.isAndroid() || DeviceHandler.isNotAndroidOrIOS()) && (
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              className="ModalLink"
              href={process.env.REACT_APP_PLAYSTORE}
            >
              Pirouette sur le PlayStore pour Android
            </a>
          </li>
        )}

        {/* {(DeviceHandler.isIos() || DeviceHandler.isNotAndroidOrIOS()) && (
        <li>
          <a
            target="_blank"
            rel="noreferrer"
            className="ModalLink"
            href={process.env.REACT_APP_APPSTORE}
          >
            Pirouette sur l'App Store pour iOS
          </a>
        </li>
      )} */}

        <li>
          <a
            target="_blank"
            rel="noreferrer"
            className="ModalLink"
            href={process.env.REACT_APP_URL}
          >
            Site web de présentation de la solution Pirouette<sup>&reg;</sup>
          </a>
        </li>
      </ul>
    </Modal>
  );
};

export default connect(({ ShowModal, Auth, serviceWorker }) => ({
  info: ShowModal.info && !serviceWorker.showGoToPWa,
  SessionData: Auth.SessionData,
  canBeInstalled: serviceWorker.canBeInstalled,
  error: {
    show: serviceWorker.error.show || serviceWorker.showNeedUpdate,
    why: serviceWorker.error.why,
  },
}))(
  React.memo(ModalInfo, (oldProps, newProps) => {
    if (oldProps.info !== newProps.info || newProps.info) return false;
    return true;
  })
);
