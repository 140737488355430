import { createAsyncThunk } from "@reduxjs/toolkit";
import { GetAuth, isOnline } from "../../helper";
import { setToken, ResetToken } from "../../helper/token";
import {
  getUuid,
  getDeviceName,
  setDeviceName,
  hasDeviceName,
} from "../../helper/uuid";
import { GetUser } from "../GetUser";

export const Login = createAsyncThunk(
  "Auth/Login",
  async (
    { username, password, deviceName, sync = true },
    { dispatch, rejectWithValue }
  ) => {
    var deviceNamed =
      hasDeviceName() && !deviceName ? getDeviceName() : deviceName;
    if (!hasDeviceName()) setDeviceName(deviceName);
    ResetToken();
    if (isOnline())
      return GetAuth().then((client) =>
        client
          .App_Center_API_Controllers_AuthController__getToken(
            {},
            {
              requestBody: {
                username: username,
                password: password,
                device_name: deviceNamed,
                device_id: getUuid(),
              },
            }
          )
          .then((res) => res.body)
          .then((res) => {
            setToken(res.token);
            dispatch(GetUser());
            return { token: res.token, show: true, to: "SetPin", sync };
          })
          .catch((err) => {
            return rejectWithValue({
              error: err.status,
              errorContent: err.response.body,
            });
          })
      );
    else
      return rejectWithValue({
        error: 0,
        errorContent:
          "Connexion internet indisponible veuillez réessayer plus tard",
      });
  }
);
