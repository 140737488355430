import React from "react";
import { Form } from "react-bootstrap";
export default function ChampLogin(props) {
  return (
    <div id={`div_${props.id}`} className="form__grp">
      <label htmlFor={props.id} className="form__label">
        {props.name}
        {
          props.required && (
            <span className="form--required"> *</span>
          )
        }
      </label>

      <Form.Control
        type={props.type}
        id={props.id}
        value={props.value}
        autoComplete={"off"}
        onChange={(e) => {
          if (e.target.value.length < 1) props.setError(true);
          else props.setError(false);
          props.setValue(e.target.value);
        }}
        style={props.error ? { borderColor: "red" } : {}}
        inputMode={props.inputmode}
        max={props.max}
        min={props.min}
        className="form__input"
        placeholder={props.placeholder}
      />
    </div>
  );
}
