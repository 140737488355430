import React from 'react';

export const formatPhoneNumber = (number, replaceString = "") =>
  number !== undefined && number !== null
    ? Array.from(String(number).replace("+33", "0")).map((value, index) =>
        index % 2 === 1 ? `${value} ` : value
      )
    : replaceString;

export const nl2br = (text) => {
      return text.split('\n').map((line, index) => (
        <React.Fragment key={index}>
          {line}
          <br />
        </React.Fragment>
      ));
    };