import { createAsyncThunk } from "@reduxjs/toolkit";
import { GetAuth, isOnline } from "../../helper";
import { getToken, ResetToken, ResetCryptedToken } from "../../helper/token";
import { resetSessionDecryptedKey, resetCryptKey } from "../../helper/crypt";

/**
 * * Function that allow the user to logout with it's access token, Once loged out the user need to log in again with it's username and password
 */
export const Logout = createAsyncThunk(
  "Auth/Login",
  async (something, { dispatch, rejectWithValue }) => {
    if (isOnline()) {
      return GetAuth(getToken())
        .then((client) =>
          client
            .App_Center_API_Controllers_AuthController__revokeCurrentToken()
            .then((res) => {
              ResetToken();
              ResetCryptedToken();
              resetSessionDecryptedKey();
              resetCryptKey();
              return { Access: false, show: true, to: "Login" };
            })
        )
        .catch((err) => rejectWithValue({ code: 0 }));
    } else {
      ResetToken();
      ResetCryptedToken();
      resetSessionDecryptedKey();
      resetCryptKey();
      rejectWithValue({ code: 418 });
    }
  }
);
