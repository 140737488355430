import { TokenHandler, GetVigilances  } from "../helper";
/**
 * Function that return the Vigilances Table
 * @param {DexieDb} db
 * @returns {VigilancesTable}
 */
export const getVigilancesTable = (db) =>
    db.getDB().table(db.getTableName().Vigilances);

export const clearVigilances = (db) => db.clearTable(db.getTableName().Vigilances);

export const getAllVigilances = (db) => getVigilancesTable(db).toArray();

export const tableVigilancesFiller = (db, dispatch, StepDone) => {
    return GetVigilances(TokenHandler.getToken()).then((client) =>
        client
            .App_Center_API_Controllers_VigilancesController__index()
            .then((res) => res.body.data)
            .then((res) => {
                return db.insertLotsOfData(res, db.getTableName().Vigilances).then(() =>
                    dispatch(
                        StepDone({
                            key: "Vigilances",
                            action: "Récupération des vigilances",
                            end: true,
                            size: res.length
                        })
                    )
                );
            })
    );
};

export const VigilancesHandler = {
    getAllVigilances,
    tableVigilancesFiller,
    clearVigilances,
};