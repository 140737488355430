import React, { useEffect } from "react";
import { connect } from "react-redux";
import { ChildHandler } from "../../helper";
import IcomoonSVG from "../IcomoonSVG";

const BirthDaySpecificContent = React.forwardRef(
  ({ child, index, setNeedSmall }, ref) => {
    useEffect(() => {
      setNeedSmall(true);
      // eslint-disable-next-line
    }, []);
    return (
      <div ref={ref} className="child__birthdate">
        {new Date(
          child.find((childs) => childs.id === index).birth_date
        ).toLocaleDateString()}
        {ChildHandler.IsBirthDay(
          child.find((childs) => childs.id === index).birth_date
        ) && <IcomoonSVG icon="cake" />}
      </div>
    );
  }
);

export default connect(
  (state) => ({ child: state.Child.filteredChild }),
  null,
  null,
  { forwardRef: true }
)(BirthDaySpecificContent);
