import { useState, useEffect } from "react";
import { prestationsParser } from "./user";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
export const useLocationId = () => {
  const [locationId, setLocationId] = useState(
    getLocationID(document.location)
  );
  const locationRouter = useLocation();
  const history = useHistory();
  useEffect(() => {
    setLocationId(getLocationID(document.location));
    return history.listen((location) => {
      if (locationId !== getLocationID(location))
        setLocationId(getLocationID(location));
    });
    // eslint-disable-next-line
  }, [history]);

  return [locationId, getLocationTag(locationRouter)];
};

export const getLocationID = (location) => {
  var splitLocation = location.pathname.split("/");
  return (
    Object.values(prestationsParser()).find(
      (value) => value.url === splitLocation[2]
    )?.id || [-1]
  );
};

export const getLocationTag = (location) => location.pathname.split("/")[2];
