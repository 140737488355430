import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { isOnline, TokenHandler, crypt, OnlineHandler } from "../helper";
import {
  filterPrestation,
  getAvailablePrestations,
} from "../helper/filterChild";

export const ChangePinAction = createAsyncThunk(
  "showModal/changePin",
  async ({ pin }, { dispatch }) => {
    crypt.setCryptKey(crypt.getSessionDecryptedKey(), pin);
    TokenHandler.setCryptedToken(TokenHandler.getToken(), pin);
    document.location.reload();
  }
);

export const initModalSlicer = createAsyncThunk("showModal/Init", async () =>
  OnlineHandler.isServerReachable()
);

export const setShowUpdateResa = createAsyncThunk(
  "showModal/showUpdateResa",
  async (
    { show = false, childId = -1, resa = [], isLinkHoraire = false, ResaId = [], prestaSuggest = [], serverAsked = false } = {},
    { getState }
  ) => {
    var { Pointage, Child } = getState();
    return {
      show: show || false,
      serverAsked: serverAsked,
      childId: childId || -1,
      resa: resa || [],
      isLinkHoraire: isLinkHoraire || false,
      prestaSuggest: prestaSuggest || [],
      prestaAvailable:
        childId !== -1
          ? getAvailablePrestations(
              Pointage.Prestations,
              Pointage.momentID
            ).filter((value) =>
              ResaId.length !== 0
                ? ResaId.includes(value.id)
                : filterPrestation(
                    value,
                    Child.child.find((e) => e.id === childId),
                    false,
                    Pointage.Signup,
                    Pointage.date,
                    Pointage.holidays,
                    Pointage.Bus,
                  )
            )
          : [],
    };
  }
);

const showModalSlicer = createSlice({
  name: "showModal",
  initialState: {
    info: false,
    deviceName: false,
    burger: false,
    synchro: false,
    reset: false,
    clearData: false,
    askPin: false,
    askPinFor: "",
    askPinResult: "",
    changePin: false,
    lock: false,
    online: isOnline(),
    ServerAvailable: true,
    filterPointage: false,
    showStats: false,
    addResa: false,
    crop: {
      show: false,
      picture: "",
      childId: "",
    },
    resaData: {
      childId: -1,
      childSignupId: -1,
      prestaAvailable: [],
    },
    updateResa: {
      show: false,
      serverAsked: false,
      childId: -1,
      isLinkHoraire: false,
      prestaSuggest: [],
      resa: [],
      prestaAvailable: [],
    },
    PointageGlobalAction: {
      allPresent: false,
      allAbsent: false,
      resetPointage: false,
    },
    ErrorSync: {
      why: "",
      userID: "",
      show: false,
    },
  },
  reducers: {
    setShowErrorSync: (state, { payload }) => {
      state.ErrorSync.show = payload.show;
      state.ErrorSync.userID = payload.accountId;
      state.ErrorSync.why = payload.why;
      state.synchro = false;
    },
    setShowAllPresent: (state, { payload }) => {
      state.PointageGlobalAction.allPresent = payload;
      state.PointageGlobalAction.resetPointage = false;
      state.PointageGlobalAction.allAbsent = false;
    },
    setShowAllAbsent: (state, { payload }) => {
      state.PointageGlobalAction.allPresent = false;
      state.PointageGlobalAction.resetPointage = false;
      state.PointageGlobalAction.allAbsent = payload;
    },
    setShowReset: (state, { payload }) => {
      state.PointageGlobalAction.resetPointage = payload;
      state.PointageGlobalAction.allPresent = false;
      state.PointageGlobalAction.allAbsent = false;
    },
    showInfo: (state) => {
      state.info = true;
      state.burger = false;
    },
    hideInfo: (state) => {
      state.info = false;
    },
    showDeviceName: (state) => {
      state.deviceName = true;
    },
    hideDeviceName: (state) => {
      state.deviceName = false;
    },
    showSynchro: (state) => {
      state.synchro = true;
      state.burger = false;
    },
    hideSynchro: (state) => {
      state.synchro = false;
    },
    showBurger: (state) => {
      state.burger = true;
    },
    hideBurger: (state) => {
      state.burger = false;
    },
    showReset: (state) => {
      state.reset = true;
      state.burger = false;
    },
    hideReset: (state) => {
      state.reset = false;
    },
    showChangePin: (state) => {
      state.changePin = true;
      state.burger = false;
    },
    hideChangePin: (state) => {
      state.changePin = false;
    },
    showLock: (state) => {
      state.lock = true;
      state.burger = false;
    },
    hideLock: (state) => {
      state.lock = false;
    },
    showClearData: (state) => {
      state.clearData = true;
      state.burger = false;
    },
    hideClearData: (state) => {
      state.clearData = false;
    },
    setShowFilterPointage: (state, { payload }) => {
      state.filterPointage = payload;
    },
    setShowStats: (state, { payload }) => {
      state.showStats = payload;
    },
    showAskPin: (state, { payload }) => {
      state.askPin = true;
      state.askPinFor = payload.for;
      state.burger = false;
      state.askPinResult = "";
    },
    hideAskPin: (state, { payload = { status: false } }) => {
      state.askPin = false;
      state.askPinResult = payload.status;
    },
    online: (state, { payload }) => {
      state.online = true;
      state.ServerAvailable = payload;
    },
    offline: (state) => {
      state.online = false;
      state.ServerAvailable = false;
    },
    setShowAddResa: (state, { payload }) => {
      state.addResa = payload.show;
      if (payload.show === true) state.resaData = payload.resaData;
    },
    setShowCrop: (state, { payload }) => {
      state.crop.show = payload;
    },
    setCropData: (state, { payload }) => {
      state.crop.childId = payload.childId;
      state.crop.picture = payload.picture;
      state.crop.show = true;
    },
  },
  extraReducers: {
    [initModalSlicer.fulfilled]: (state, { payload }) => {
      state.ServerAvailable = payload;
    },
    [setShowUpdateResa.fulfilled]: (state, { payload }) => {
      state.updateResa.show = payload.show || false;
      state.updateResa.childId = payload.childId || -1;
      state.updateResa.isLinkHoraire = payload.isLinkHoraire || false;
      state.updateResa.prestaSuggest = payload.prestaSuggest || [];
      state.updateResa.resa = payload.resa || [];
      state.updateResa.prestaAvailable = payload?.prestaAvailable || [];
      state.updateResa.serverAsked = payload.serverAsked;
    },
  },
});

export const {
  showInfo,
  hideInfo,
  showDeviceName,
  hideDeviceName,
  showBurger,
  hideBurger,
  showSynchro,
  hideReset,
  showReset,
  hideSynchro,
  showAskPin,
  hideAskPin,
  showChangePin,
  hideChangePin,
  showLock,
  hideLock,
  showClearData,
  hideClearData,
  online,
  offline,
  setShowFilterPointage,
  setShowStats,
  setShowAddResa,
  setShowAllAbsent,
  setShowAllPresent,
  setShowReset,
  setShowErrorSync,
  setShowCrop,
  setCropData,
} = showModalSlicer.actions;
export default showModalSlicer.reducer;
