import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import ArianePointage from "./ariane/arianePointage";
import { setTittle } from "../../helper/gestionTitle";
const Ariane = ({ location, Pointage }) => {
  let history = useHistory();
  var Page = [
    {
      Label: "Pointage",
      url: "/pointage",
      value: ["pointage", ""],
      Component: ArianePointage,
    },
    {
      Label: "Dossiers famille",
      url: "/dossier",
      value: ["dossier"],
      only: true,
      Component: () => <div></div>,
    },
    {
      Label: "Anniversaires",
      url: "/birthday",
      value: ["birthday"],
      only: true,
      Component: () => <div></div>,
    },
    //    {
    //      Label: "Statistiques",
    //      url: "/stat",
    //      value: ["stat"],
    //      disabled: true,
    //      Component: () => <div></div>,
    //    },
    //    {
    //      Label: "Pointages manquants",
    //      url: "/nonpointe",
    //      value: ["nonpointe"],
    //      disabled: true,
    //      Component: () => <div></div>,
    //    },
  ];
  var Where = Page.find((page) =>
    page.value.includes(location.pathname.split("/")[1])
  );
  setTittle(location, Where, Pointage);
  return (
    <div className="breadcrumb">
      <select
        className=""
        name="Page"
        id="Page"
        onChange={(event) => history.push(`${event.target.value}`)}
        value={Where ? Where.url : "/pointage"}
      >
        {Page.map((value) => (
          <option disabled={value.disabled} value={value.url} key={value.Label}>
            {value.Label}
          </option>
        ))}
      </select>
      {Where && (
        <Where.Component
          where={location.pathname.split("/")[2]}
          history={history}
        />
      )}
    </div>
  );
};

export default connect(({ Pointage }) => ({
  Pointage: {
    momentID: Pointage.momentID,
    date: Pointage.date,
    prestationsType: Pointage.prestationsType,
  },
}))(
  React.memo(Ariane, (oldProps, newProps) => {
    if (
      oldProps.location.pathname === newProps.location.pathname &&
      oldProps.Pointage === newProps.Pointage
    ) {
      return true;
    }
  })
);
