import React from "react";
import { connect } from "react-redux";
import { ChildHandler } from "../../../helper";
import ModalChildPicto from "./modalChildHeaderPicto";

const ModalChildHeaderInfo = ({ signup, child, Signup, vigilances }) => {
  var childAge = ChildHandler.GetChildAge(child.birth_date, true);
  var signupGlobal = Signup.find((signupG) => signupG.id === signup.year_id);
  if (!signup.no_school) {
    var school = signupGlobal.schools?.filter(item => item.id === signup.school_id);
  }
  if (!signup.no_teacher) {
    var teacher = signupGlobal.teacher?.filter(item => item.id === signup.teacher_id);
  }

  return (
    <>
      <div className="child__title">
        <h2>
          {child.first_name} {child.last_name}
          {child.alias ? " - " + child.alias : ""}
        </h2>

        <ModalChildPicto
          isHandicaped={signup.is_handicapped}
          splitParents={false} // signup.split_parents
          hasPai={signup.has_pai !== 0}
          vigilances={vigilances}
          showLabels
        />
      </div>
      <p>
        {ChildHandler.GetStringLevel()[signup.level]} - {childAge} an
        {childAge > 1 ? "s" : ""} (né le{" "}
        {new Date(child.birth_date).toLocaleDateString("fr-FR")})
      </p>

      <p className="child__school">
        {(school && school.length !== 0) && (
          <span>École : {school[0].name}</span>
        )}
        {(teacher && teacher.length !== 0) && (
          <span>
            Enseignant : {teacher[0].name}
          </span>
        )}
      </p>
      <p>Rattaché à la famille : {child.family.family_name}</p>
      {signup.banned &&
        <div className="badge">
          <p>
            {signup.banned}
          </p>
        </div>
      }
    </>
  );
};

export default connect(({ Pointage }) => ({ Signup: Pointage.Signup }))(
  ModalChildHeaderInfo
);
