import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import { connect } from "react-redux";
import ModalChildBodyGeneral from "./modalChildBodyGeneral";
import ModalChildBodyAutorisations from "./modalChildBodyAutorisations";
import ModalChildBodySanitaire from "./modalChildBodySanitaire";
import ModalChildBodyUrgence from "./modalChildBodyUrgence";
import ModalChildBodyResponsables from "./modalChildBodyResponsables";
import ModalChildBodyBus from "./modalChildBodyBus";

const ModalChildBody = ({ child, signup, hasBus }) => {
  return (
    <>
      <Tabs defaultActiveKey="general" id="ChildModalTab" transition={false}>
        <Tab
          eventKey="general"
          title="Général"
          tabClassName="ChildModalTabHead"
        >
          <ModalChildBodyGeneral child={child} signup={signup} />
        </Tab>
        <Tab
          eventKey="autorisations"
          title="Personnes autorisées"
          tabClassName="ChildModalTabHead"
        >
          <ModalChildBodyAutorisations child={child} signup={signup} />
        </Tab>

        <Tab
          eventKey="sanitaire"
          title="Fiche sanitaire"
          tabClassName="ChildModalTabHead"
        >
          <ModalChildBodySanitaire child={child} signup={signup} />
        </Tab>
        <Tab
          eventKey="urgence"
          title="Contacts d'urgence"
          tabClassName="ChildModalTabHead"
        >
          <ModalChildBodyUrgence child={child} signup={signup} />
        </Tab>
        <Tab
          eventKey="responsables"
          title="Responsables légaux"
          tabClassName="ChildModalTabHead"
        >
          <ModalChildBodyResponsables child={child} signup={signup} />
        </Tab>
        {hasBus && (
          <Tab eventKey="Bus" title="Bus" tabClassName="ChildModalTabHead">
            <ModalChildBodyBus child={child} signup={signup} />
          </Tab>
        )}
      </Tabs>
    </>
  );
};

export default connect(({ Pointage }) => ({
  hasBus: Pointage.Bus.length > 0,
}))(ModalChildBody);
