import {
  TokenHandler,
  GetChildren,
  GetAssets,
  ContactHandler,
} from "../helper";
/**
 * * Function that return the Child table
 * @param {DexieDb} db
 * @returns {ChildTable}
 */
export const getChildTable = (db) => db.getDB().table(db.getTableName().Child);

export const getAllChild = (db) => getChildTable(db).toArray();

/**
 * * Function that parse a ChildObject into a storable and cryptable object, it also fetch the picture of a child if he has one
 * @param {ChildObject} child
 * @returns {Promise} Child
 */
export const childParser = (child, db) => {
  return getOneChild(db, child.id).then((childFromDb) => {
    return new Promise((resolve, reject) => {
      var ParsedChild = { ...child, PictureChange: 0, CommentChange: 0 };
      ParsedChild.photo = childFromDb?.photo;
      if (
        ((child.has_photo) ||
        (child.has_photo &&
          childFromDb !== undefined &&
          !childFromDb.has_photo) ||
        (child.has_photo && childFromDb === undefined)
      ) &&
      (
        childFromDb === undefined ||
        (childFromDb.updated_photo_at !== undefined &&
        childFromDb.updated_photo_at !== null &&
        childFromDb.updated_photo_at < child.updated_photo_at)
      )) {
        GetAssets(TokenHandler.getToken()).then((client) =>
          client
            .App_Center_API_Controllers_AssetsController__getChildPicture({
              child: child.id,
            })
            .then((res) => {
              var reader = new FileReader();
              reader.readAsDataURL(res.data);
              reader.onloadend = () => {
                ParsedChild.photo = reader.result;
                resolve(ParsedChild);
              };
            })
        );
      } else resolve(ParsedChild);
    });
  });
};
/**
 * * Function that return if the table has child
 * @param {DexieDb} db
 * @returns {Promise/Boolean}
 */
export const hasChild = (db) => {
  return getChildTable(db)
    .toCollection()
    .count()
    .then((nbr) => (nbr > 0 ? true : false));
};

/**
 *  * Function that clear the child table
 * @param {DexieDb} db
 */
export const clearChild = (db) => db.clearTable(db.getTableName().Child);

/**
 * * Function that fill the ChildTable fetching them from the database
 * @param {DexieDb} db
 * @param {ReduxDispatch} dispatch
 * @param {CallBack} StepDone
 */
export const tableChildFiller = (
  db,
  dispatch,
  StepDone,
  YearID,
) => {
  return GetChildren(TokenHandler.getToken()).then((client) =>
    client
      .get_center_children({
        "years[]": YearID,
      })
      .then((res) => res.body.data)
      .then((res) => {
        var AllPromise = [];
        return new Promise((resolveFiller, reject) => {
          res.forEach((element, Index) => {
            AllPromise.push(childParser(element, db))
          }
          );
          Promise.all(AllPromise)
            .then((child) =>
              db.insertLotsOfData(child, db.getTableName().Child)
            )
            .then(() => {
              dispatch(
                StepDone({
                  key: "Enfant",
                  action: "Récupération des enfants",
                  index: res.length,
                  size: res.length,
                  end: true,
                })
              );
            })
            .then(() => resolveFiller());
        });
      })
  );
};

/**
 * * Function that filter the child with callback
 * @param {DexieDb} db
 * @param {Callback(Child)=>Boolean} filter
 */
export const getChildByFilter = (db, filter) =>
  getChildTable(db).toCollection().filter(filter);

/**
 * * Function that filter the child by it's month birthday
 * * PS: Be wary js Date object month start at 0
 * @param {DexieDb} db
 * @param {Integer} nbrOfTheMonth
 */
export const getChildByAnniversaryMonth = (db, nbrOfTheMonth) =>
  getChildByFilter(
    db,
    (value) => new Date(value.birth_date).toArray().getMonth() === nbrOfTheMonth
  );

/**
 * * Function that return the age of the child or the age he's gonna get
 * @param {String date} birthDate
 * @param {boolean} currentAge
 */
export const getChildAge = (
  birthDate,
  currentAge = true,
  ageReference = new Date()
) => {
  var castBirthDate = new Date(birthDate);
  var dateReference = new Date(ageReference);
  var Age = dateReference.getFullYear() - castBirthDate.getFullYear();
  if (!currentAge) return Age;
  return (
    Age -
    (castBirthDate.setFullYear(dateReference.getFullYear()) <= dateReference
      ? 0
      : 1)
  );
};

/**
 * * Function that return if today is the birthday of the passed string
 * @param {String date} birthDate
 */
export const isBirthDay = (birthDate) => {
  var birthday = new Date(birthDate);
  var Today = new Date();
  return (
    birthday.getDate() === Today.getDate() &&
    birthday.getMonth() === Today.getMonth()
  );
};
/**
 * * Allow the user to get a child by his id
 * @param {DexieDb} db
 * @param {Integer} childId
 */
export const getOneChild = (db, childId) => {
  return getChildTable(db).where("id").equals(childId).first();
};

/**
 * * Allow the user to get the child activeSignup and Contact Picture
 * @param {DexieDb} db
 * @param {ChildObject} Child
 */
export const getFullChild = (db, Child) => {
  return new Promise((resolve, reject) => {
    var AllPromise = [];
    Child.links.forEach((contact, index) => {
      AllPromise.push(
        ContactHandler.GetOneContact(db, contact.contact.id).then((value) => {
          Child.links[index].contact = value;
        })
      );
    });
    Promise.all(AllPromise).then(() => resolve(Child));
  });
};

/**
 *
 * @param {DexieDb} db
 * @param {Integer} childID
 * @param {String<Base64>} Picture
 */
export const changeChildComment = (db, childID, Comment) => {
  return getOneChild(db, childID).then((child) => {
    child.structure_comment = Comment;
    child.structure_lastcomment_by = "toto";
    child.CommentChange = 1;
    return db
      .insertOrUpdate(childID, child, db.getTableName().Child)
      .then(() => child);
  });
};

/**
 *
 * @param {DexieDb} db
 * @param {Integer} childID
 * @param {String<Base64>} Picture
 */
export const changeChildPicture = (db, childID, Picture) => {
  return getOneChild(db, childID).then((child) => {
    child.has_photo = true;
    child.photo = Picture;
    child.PictureChange = 1;
    return db
      .insertOrUpdate(childID, child, db.getTableName().Child)
      .then(() => child);
  });
};

export const base64PicToBlob = (pic) => {
  return fetch(pic).then((res) => res.blob());
};

/**
 * * Send all the changed picture of the child to the serveur
 * @param {DexieDb} db
 * @param {Callback<NotificationModal>} Step
 * @param {Redux<Dispatch>} Dispatch
 */
export const sendPictureToServeur = (db, Step, Dispatch) => {
  return getChildByFilter(db, (child) => child.PictureChange === 1)
    .toArray()
    .then((Childs) => {
      if (Childs.length === 0)
        return Dispatch(
          Step({
            key: "EnfantPic",
            action: "Aucune photo n'est à mettre à jour",
            index: 0,
            size: 0,
          })
        );
      Childs.forEach((Child, index) => {
        base64PicToBlob(Child.photo).then((parsedPic) => {
          GetAssets(TokenHandler.getToken(), "multipart/form-data").then(
            (client) => {
              return client
                .App_Center_API_Controllers_AssetsController__setChildPicture(
                  { child: Child.id, photo: parsedPic },
                  {
                    requestBody: {
                      photo: parsedPic,
                    },
                  }
                )
                .then((res) => {
                  Dispatch(
                    Step({
                      key: "EnfantPic",
                      action: "Envoi des photos d'enfant",
                      index: index,
                      size: Childs.length,
                    })
                  );
                });
            }
          );
        });
      });
    });
};

/**
 * * Retourne une promise qui contient le nombre d'enfant qui on leur photo changer
 * @param {DexieDb} db
 */
export const getCountPictureChanged = (db) => {
  return getChildTable(db)
    .where("PictureChange")
    .equals(1)
    .toArray()
    .then((value) => value.length);
};

/**
 * * HadCoded Guard type
 */
export const getStringGuardMode = () => {
  return {
    1: "Conjointe",
    2: "Exclusive (Uniquement un seul des parents)",
    3: "Alternée (Un parent puis l'autre)",
  };
};

/**
 * * HardCoded level of the children
 */
export const getStringLevel = () => {
  return {
    0: "None",
    2: "TPS",
    3: "PS",
    4: "MS",
    5: "GS",
    6: "CP",
    7: "CE1",
    8: "CE2",
    9: "CM1",
    10: "CM2",
    11: "6e",
    12: "5e",
    13: "4e",
    14: "3e",
    15: "2e",
    16: "1re",
    17: "Tle",
  };
};

/**
 * * Hard coded Link with the children
 */
export const getStringLinkType = () => {
  return {
    0: "Autre",
    1: "Père",
    2: "Mère",
    3: "Beaux-père",
    4: "Belle-mère",
    5: "Tante",
    6: "Oncle",
    7: "Grand-Mère",
    8: "Grand-Père",
    9: "Sœur",
    10: "Frére",
    11: "Nourrice",
    12: "Voisin (M)",
    13: "Voisin (F)",
    14: "Maison d'accueil",
  };
};

export const ChildHandler = {
  ChildParser: childParser,
  HasChild: hasChild,
  ClearChild: clearChild,
  TableChildFiller: tableChildFiller,
  GetChildTable: getChildTable,
  GetChildAge: getChildAge,
  IsBirthDay: isBirthDay,
  GetChildByAnniversaryMonth: getChildByAnniversaryMonth,
  GetChildByFilter: getChildByFilter,
  GetOneChild: getOneChild,
  GetStringLevel: getStringLevel,
  GetFullChild: getFullChild,
  GetStringGuardMode: getStringGuardMode,
  GetStringLinkType: getStringLinkType,
  ChangeChildPicture: changeChildPicture,
  ChangeChildComment: changeChildComment,
  SendPictureToServeur: sendPictureToServeur,
  GetCountPictureChanged: getCountPictureChanged,
  getAllChild: getAllChild
};
