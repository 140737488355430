import React, { useEffect } from "react";
import { connect } from "react-redux";
import { findReservationPrimaryByChildId } from "../../../helper";
import NoResa from "./noResa";
import ResaPointage from "./resaPointage";

const ChildResa = React.forwardRef(({ child, resa, setNeedSmall }, ref) => {
  useEffect(() => {
    setNeedSmall(resa === undefined);
    // eslint-disable-next-line
  }, [resa]);
  return (
    <div className="child__pointage" ref={ref}>
      {resa === undefined && <NoResa child={child} />}
      {resa !== undefined && (
        <ResaPointage
          resa={resa}
          childId={child}
          prestation_id={resa.prestation_id}
          resaId={resa.id}
        />
      )}
    </div>
  );
});

export default connect(
  (state, ownProps) => ({
    resa: findReservationPrimaryByChildId(
      state.Pointage.Reservation,
      state.Pointage.Prestations,
      ownProps.child
    ),
  }),
  null,
  null,
  { forwardRef: true }
)(ChildResa);
