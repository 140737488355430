import { setFilter } from "../../../actions/Database/Pointage";
import IcomoonSVG from "../../IcomoonSVG";

export function FilterTag({
    children,
    dispatch,
    category,
    id,
    notAction,
}) {

    return (
        <div className="filter-tag">
            {!notAction &&
                <button className="icon-container" onClick={() => {
                    dispatch(
                        setFilter({
                            categorie: category,
                            checked: false,
                            id: id,
                        })
                    )
                }}>
                    <IcomoonSVG icon="cross"></IcomoonSVG>
                </button>
            }
            <span>
                {children}
            </span>
        </div>
    );
}
