import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { updateAbsence } from "../../../../actions/Database/Resa";

const MotifPicker = ({
    inputMotif,
    resaId,
    resa,
    action = undefined,
    dispatch,
    addClass = "",
    allowedMotif,
    allResa = false,
}) => {
    const [motif, setMotif] = useState(null);

    useEffect(() => {
        setMotif(inputMotif)
    }, [inputMotif]);

    const handleChange = (el) => {
        setMotif(el.currentTarget.value);

        if (action === undefined) {
            dispatch(
                updateAbsence({
                    resaId,
                    motif: el.currentTarget.value,
                })
            )
        } else {
            action(el.currentTarget.value);
        }
    }
    if (allowedMotif.length !== 0) {
        return (
            <>
                <label
                    className="form__label"
                    htmlFor="motif">
                        Motif de l'absence :
                </label>
                <select
                    id="motif"
                    className={addClass ? addClass : undefined}
                    value={
                        (motif !== null &&
                            motif !== "none")
                            ? motif
                            : (!allResa &&
                            resa.absence &&
                            resa.absence !== null &&
                            resa.absence.absence_type_id !== null
                            ? String(resa.absence.absence_type_id)
                            : "none")
                    }
                    onChange={(e) => handleChange(e)}

                >
                    <option value="none" key={"none"}  disabled>
                        Sélectionner un motif
                    </option>
                    <option value="reset" key="reset">
                        Supprimer le motif
                    </option>
                    {allowedMotif.map((absence) => (
                        <option value={absence.id} key={absence.id}>
                            {absence.name}
                        </option>
                        ))}
                </select>
            </>
        )
    } else {
        return null;
    }
};
// export default connect()(MotifPicker);
export default connect(({ Auth, Pointage }, { resaId }) => ({
    resa: Pointage.Reservation.find((reservation) => reservation.id === resaId),
    allowedMotif: Pointage.absence.filter((motif) =>
      motif.allow_l1_users ? true : Auth.SessionData.permissionLevel > 1
    ),
  }))(
    React.memo(MotifPicker, (oldProps, newProps) => {
      if (oldProps.resa === newProps.resa) return true;
    })
  );