import React from "react";
import { Link } from "react-router-dom";
import IcomoonSVG from "../../IcomoonSVG";

const Item = ({ isLink, children, to, action, menuHide, ...props }) =>
  isLink ? (
    <Link onClick={menuHide} to={to} {...props}>
      {children}
    </Link>
  ) : (
    <div {...props} onClick={action}>
      {children}
    </div>
  );

const BurgerItem = ({ Label, to, icon, link, action, ...props }) => {
  return (
    <Item isLink={link} to={to} action={action} {...props}>
      <IcomoonSVG icon={icon} />
      <p>{Label}</p>
    </Item>
  );
};

export default BurgerItem;
