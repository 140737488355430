import React from "react";
import { connect } from "react-redux";
import ChampLogin from "./ChampLogin";
import { SetPin } from "../../actions/Auth/SetPin";

function PinSetForm({ dispatch, Auth, ...props }) {
  const [pin, setPin] = React.useState("");
  const [pinError, setPinError] = React.useState(false);
  const [error, setError] = React.useState("");

  const HandleSubmit = async (e) => {
    e.preventDefault();
    if (pin.length < 4 || pin.length > 8) {
      setError("Le code PIN doit être constitué de 4 à 8 chiffres.");
      setPinError(true);
    } else {
      setPinError(false);
      setError("");
      dispatch(SetPin(pin));
    }
  };
  return (
    <>
      <div className="grid__col6">
        <img
          src={
            Auth.SessionData.CentreImage
              ? Auth.SessionData.CentreImage
              : process.env.PUBLIC_URL + "/pirouette.png"
          }
          alt="Logo Pirouette"
          className="login__logo"
        />
      </div>

      <form onSubmit={HandleSubmit} className="grid__col6">
        <div className="form__grp">
          <ChampLogin
            id="pin"
            name="Choix du code PIN pour l'application"
            type="number"
            value={pin}
            setValue={setPin}
            error={pinError}
            setError={setPinError}
            classN=""
            max="99999999"
            min="0"
            required
            placeholder="4 à 8 chiffres"
            />
        </div>

        <span className="form--errormsg">
          {error}{" "}
          {Auth.Error === 0
            ? "Attention, vous n'êtes plus connecté à Internet"
            : ""}
        </span>

        <div className="form__btns">
          <button className="btn btn--secondary" onClick={HandleSubmit}>
            Confirmer
          </button>
        </div>
      </form>
    </>
  );
}

const MapStateToProps = (state) => state;

export default connect(MapStateToProps)(PinSetForm);
