import React from "react";
import { connect } from "react-redux";
import ModalChildBodyBusItemDetails from "./modalChildBodyBusItemDetails";

const ModalChildBodyBusItem = ({ Bus, affectation }) => {
  if (affectation.bus_arriving_route_id === affectation.bus_departing_route_id)
    return (
      <>
        <ModalChildBodyBusItemDetails twoWay={true} affectation={affectation} />
      </>
    );
  return (
    <>
      {affectation.bus_arriving_route_id !== null && (
        <ModalChildBodyBusItemDetails coming={true} affectation={affectation} />
      )}
      {affectation.bus_departing_route_id !== null && (
        <ModalChildBodyBusItemDetails
          coming={false}
          affectation={affectation}
        />
      )}
    </>
  );
};

export default connect(({ Pointage }) => ({ Bus: Pointage.Bus }))(
  ModalChildBodyBusItem
);
