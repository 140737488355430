import React, { useEffect } from 'react';

import { connect } from "react-redux";
import { showAskPin } from '../../actions/showModal';
import Modal from "./Modal";

const ModalClearData = ({ ShowModal, hideClearData, dispatch, date}) => {
    useEffect(() => {
        if (ShowModal.clearData && ShowModal.askPinResult === true) {
            // Suppression données de sessions
            sessionStorage.clear();

            // Suppression de données locales
            localStorage.clear();

            // Suppression de cache
            caches.keys().then(keys => {
                keys.forEach(key => caches.delete(key));
            });

            // Suppression base de donnée indexée
            indexedDB.databases().then(dbs => {
                dbs.forEach(db => indexedDB.deleteDatabase(db.name));
            });

            // Suppressin cookies
            document.cookie = document.cookie.split(';').reduce((newCookie1, keyVal) => {
                var pair = keyVal.trim().split('=')
                if (pair[0]) {
                    if (pair[0] !== 'path' && pair[0] !== 'expires') {
                        newCookie1 += pair[0] + '=;'
                    }
                }
                return newCookie1;
            }, 'expires=Thu, 01 Jan 1970 00:00:00 UTC; path:/;');

            setTimeout(() => window.location.href = window.location.origin, 1500);
        }
        // eslint-disable-next-line
    }, [ShowModal.askPinResult]);

    return (
        <Modal
            label="Effacer les données de l'application"
            show={ShowModal.clearData && !ShowModal.askPin}
            hide={hideClearData}
            modalBodyClassName="center"
        >
            <p className="color--error withMargin">
                <strong>
                    Attention l'effacement des données de l'application va supprimer toutes les données non synchronisées.
                </strong>
            </p>

            {ShowModal.askPinResult
                ? <p>Les données sont en cours de suppression...</p>
                : <div className="form__btns">
                    <button
                        onClick={() =>
                            dispatch(showAskPin({ for: "Suppression des données de l'application" }))
                        }
                        className="btn btn--secondary"
                    >
                        Effacer les données
                    </button>
                    <button onClick={hideClearData} className="btn btn--light btn--link">
                        Annuler
                    </button>
                </div>
            }
        </Modal>
    )
}

export default connect(({ ShowModal, Pointage }) => ({
    ShowModal: {
      clearData: ShowModal.clearData,
      askPinResult: ShowModal.askPinResult,
      askPin: ShowModal.askPin,
    },
    date: Pointage.date,
  }))(
    React.memo(ModalClearData, (oldProps, newProps) => {
      if (
        oldProps.ShowModal.clearData !== newProps.ShowModal.clearData ||
        newProps.ShowModal.clearData
      )
        return false;
      return true;
    })
  );
  