import React from "react";
import { connect } from "react-redux";

const ModalChildBodyBusItemDetails = ({
  Bus,
  affectation,
  coming = true,
  twoWay = false,
}) => {
  var bus = Bus.find(
    (toTreatBus) =>
      toTreatBus.id ===
      affectation[coming ? "bus_arriving_route_id" : "bus_departing_route_id"]
  );
  return (
    <dl>
      <div className="child__info">
        <dt>Bus {(twoWay && "Aller/retour") || coming ? "Aller" : "Retour"}</dt>
        <dd>{bus.name}</dd>
        <dd>
          {
            bus.stops.find(
              (stop) =>
                stop.id ===
                affectation[
                  coming ? "bus_arriving_stop_id" : "bus_departing_stop_id"
                ]
            ).name
          }
        </dd>
        {twoWay &&
          affectation.bus_arriving_stop_id !==
            affectation.bus_departing_stop_id && (
            <dd>
              {
                bus.stops.find(
                  (stop) => stop.id === affectation["bus_departing_stop_id"]
                ).name
              }
            </dd>
          )}
      </div>
    </dl>
  );
};

export default connect(({ Pointage }) => ({ Bus: Pointage.Bus }))(
  ModalChildBodyBusItemDetails
);
