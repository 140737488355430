import React from "react";
import { connect } from "react-redux";
import LoginForm from "./LoginForm";
import { ActionLostPin, HideLostPin } from "../../actions/Auth/LostPin";
import { Login } from "../../actions/Auth/Login";
import { Modal } from "react-bootstrap";
function CodePinOublierForm({ Auth, dispatch, ...props }) {
  const [AreYouSure, setAreYouSure] = React.useState(false);
  const [AuthValue, setAuthValue] = React.useState("");
  const Action = (authValue) => {
    if (AreYouSure !== false) setAreYouSure(false);
    setAuthValue(authValue);
    return ActionLostPin(authValue);
  };
  const AreYouSureAction = () => {
    setAreYouSure(true);
    dispatch(Login(AuthValue));
  };
  var test = JSON.parse(localStorage.getItem("LostCodePin"));
  return (
    <>
      <LoginForm Action="forgot_pin" toDispatch={Action} />
      <Modal
        show={Auth.Error === 0 && !AreYouSure}
        onHide={() => setAreYouSure(true)}
        animation={false}
      >
        <Modal.Header closeButton>
          Changement de compte
        </Modal.Header>
        <Modal.Body>
          <p className="withMargin">
            <strong>Êtes-vous sûr de vouloir vous connecter avec un autre compte ?</strong>
          </p>

          <p>
            <strong>Ancien compte :{" "}</strong>
            <span>
              {`${Auth.SessionData.Name} ${Auth.SessionData.usersurname} / ${Auth.SessionData.username}`}
            </span>
          </p>
          <p>

          <strong>Nouveau compte :{" "}</strong>
            <span>
              {test &&
                `${test.first_name} ${test.last_name} / ${test.username}`}
            </span>
          </p>

          <div className="form__btns">
            <button
              className="btn btn--primary"
              onClick={AreYouSureAction}
            >
              Oui, je suis sûr
            </button>
            <button
              className="btn btn--light btn--link"
              onClick={() => {
                setAreYouSure(true);
                dispatch(HideLostPin());
              }}
            >
              Annuler
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

const MapStateToProps = (state) => state;

export default connect(MapStateToProps)(CodePinOublierForm);
