import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  ClosingDayHandler,
  crypt,
  Db,
  SessionHandler,
  PrestationHandler,
  ReservationHandler,
  SignupHandler,
  AbsenceHandler,
  BusesHandler,
  MealTypesHandler,
  GroupsHandler,
  MealServicesHandler,
  AutorisationsHandler,
  ChildHandler,
  VigilancesHandler,
} from "../../helper";
import {
  actionGlobal,
  addReservation,
  delReservation,
  updateAbsence,
  updateHorairePresence,
  updatePresence,
  updateReservation,
} from "./Resa";
import { FetchSomeChild } from "./Child";
import {
  lowerTime,
  dateIsTheSame,
  dateStringToDate,
  formatDate,
  checkIfDateIsInAllowed,
  dateIsBetweenOrEqualDate,
  setDateToHourZero,
} from "../../helper/date";
import { getLocationID } from "../../helper/useLocationId";
import { getFilterLocal, setFilterLocal } from "../../helper/filterHandler";
import { filter } from "../../helper/filterDefinition";
export const setDate = createAsyncThunk(
  "pointage/setDate",
  async (
    { date, dateId, forceMoment, pending = true, prestaDateId = null, isStarting = false },
    { dispatch, getState, rejectWithValue }
  ) => {
    var { Auth, Synchro, Pointage } = getState();

    // eslint-disable-next-line
    var send_date = new Date(date) == "Invalid Date" && date.date ? date.date : date;

    if (
      !checkIfDateIsInAllowed(Auth, Synchro)(new Date(send_date)) &&
      (!(
        (getLocationID(document.location).includes(10) &&
          Pointage.Prestations.find(
            ({ id }) =>
              String(id) === String(document.location.pathname.split("/")[3])
          )?.possible_dates.some(
            (dateId) => dateId.id === prestaDateId
          )) ||
        false
      ) ||
        (getLocationID(document.location).includes(6) &&
          Pointage.Prestations.find(
            ({ id, start_date, end_date }) =>
              String(id) === String(document.location.pathname.split("/")[3]) &&
              dateIsBetweenOrEqualDate(send_date, start_date, end_date)
          ) === undefined))
    )
      return rejectWithValue();
    var db = new Db(crypt.getSessionDecryptedKey());
    return Promise.all([
      ClosingDayHandler.GetHolidays(db, true),
      ClosingDayHandler.GetHolidays(db),
    ]).then(async ([pHolidays, sHolidays]) => {
      var result = {
        closed: false,
        why: "",
        date: new Date(send_date).toString(),
        SchoolHoliday: false,
        pending: pending,
        dateId: dateId,
      };
      result.closed = pHolidays.reduce((before, current) => {
        if (before === true) return true;
        if (
          current.date &&
          dateIsTheSame(dateStringToDate(current.date), new Date(send_date))
        ) {
          result.why = `Férie - ${current.name}`;
          return true;
        }
        if (
          current.start_date &&
          dateIsBetweenOrEqualDate(
            new Date(send_date),
            dateStringToDate(current.start_date),
            dateStringToDate(current.end_date)
          )
        ) {
          result.why = `Férié - ${current.name}`;
          return true;
        }
        return false;
      }, false);
      result.SchoolHoliday = sHolidays.reduce((before, current) => {
        if (before === true) return true;
        if (
          current.start_date &&
          dateIsBetweenOrEqualDate(
            new Date(send_date),
            dateStringToDate(current.start_date),
            dateStringToDate(current.end_date)
          )
        ) {
          result.why = `Vacances - ${current.name}`;
          return true;
        }
        return false;
      }, false);
      dispatch(getPrestation({ date: new Date(send_date), forceMoment, isStarting }));
      dispatch(getReservation({
        momentId: Pointage.momentID,
        prestations: Pointage.Prestations,
        date: send_date,
        isStarting,
      }));
      return result;
    });
  }
);

export const startupGetData = createAsyncThunk(
  "pointage/StartupGetData",
  async () => {
    var db = new Db(crypt.getSessionDecryptedKey());
    return db
      .getDB()
      .transaction(
        "r",
        [
          db.getTableName().Absence,
          db.getTableName().SignupYears,
          db.getTableName().Buses,
          db.getTableName().ClosingDay,
          db.getTableName().Groups,
          db.getTableName().MealTypes,
          db.getTableName().MealServices,
          db.getTableName().Autorisations,
          db.getTableName().Child,
          db.getTableName().Vigilances,
        ],
        () => {
          var allQuery = [];
          allQuery.push(AbsenceHandler.getAllAvailableAbsence(db));
          allQuery.push(SignupHandler.getAllSignup(db));
          allQuery.push(BusesHandler.getAllBuses(db));
          allQuery.push(ClosingDayHandler.GetHolidays(db));
          allQuery.push(GroupsHandler.getAllGroups(db));
          allQuery.push(MealTypesHandler.getAllMealTypes(db));
          allQuery.push(MealServicesHandler.getAllMealServices(db));
          allQuery.push(AutorisationsHandler.getAllAutorisations(db));
          allQuery.push(ChildHandler.getAllChild(db));
          allQuery.push(VigilancesHandler.getAllVigilances(db));
          return Promise.all(allQuery);
        }
      )
      .then((result) => ({
        absence: result[0],
        signup: result[1],
        bus: result[2],
        closing: result[3],
        group: result[4],
        meal_type: result[5],
        meal_service: result[6],
        autorisations: result[7],
        child: result[8],
        vigilances: result[9],
      }));
  }
);

export const getPrestation = createAsyncThunk(
  "pointage/GetPrestation",
  async ({ date, forceMoment, isStarting = false } = {}, { dispatch, getState }) => {
    var { Pointage } = getState();
    date = date || new Date(Pointage.date);
    var promise = [];
    if (Pointage.prestationsType === [0]) return;
    var database = new Db(crypt.getSessionDecryptedKey());
    if ([6, 8, 10].some((value) => Pointage.prestationsType.includes(value))) {
      promise.push(
        PrestationHandler.getPrestationByType(
          database,
          Pointage.prestationsType[0]
        ).then((presta) =>
          Promise.all(
            presta.map((prestation) =>
              ReservationHandler.getReservationPrestationsId(database, [
                prestation.id,
              ]).then((resa) => ({
                ...prestation,
                member: resa.reduce((all, resaToTreat) => {
                  if (!all.includes(resaToTreat.child_id)) {
                    all.push(resaToTreat.child_id);
                  }
                  return all;
                }, []).length,
              }))
            )
          )
        )
      );
    } else
      Pointage.prestationsType.forEach((value) => {
          /*if (Pointage.prestationsType.includes(5)) {
            promise.push(
              PrestationHandler.getPrestationByType(
                database,
                value
              )
            )
          } else {*/
            promise.push(
              PrestationHandler.getPrestationByIsInPossibleDayTypeAndDate(
                database,
                date.getDay(),
                value,
                date
              ).toArray()
            )
          /*}*/
        }
      );
    var today = new Date();
    return Promise.all(promise)
      .then((presta) => ({
        presta: presta,
        prestas: presta.reduce((concat, toTreat) => concat.concat(toTreat), []),
        FullDay: presta.reduce(
          (old, prestArray) =>
            old === true
              ? true
              : prestArray.some(
                  (prestation) =>
                    prestation.moment === 1 || prestation.moment === null
                ),
          false
        ),
      }))
      .then((value) => ({
        ...value,
        moment: value.prestas.reduce((trad, { moment }) => {
          if (moment === null) return trad;
          return {
            ...trad,
            [moment]: {
              id: moment,
              code: SessionHandler.momentParser()[moment].label,
            },
          };
        }, {}),
        prestaLimit: {
          startTime:
            value.prestas.length > 0 &&
            value.prestas.reduce(lowerTime(true)).start_time,
          endTime:
            value.prestas.length > 0 &&
            value.prestas.reduce(lowerTime()).end_time,
        },
      }))
      .then(async (value) => {
        if (
          (!(
            setDateToHourZero(Pointage.date).getTime() ===
              setDateToHourZero(date).getTime() &&
            value.prestas === Pointage.Prestations
          ) &&
          Object.values(value.moment).length !== 0) ||
          Pointage.prestationsType.includes(5)
        )
          await dispatch(
            setMoment({
              momentId:
                value.FullDay === true
                  ? 1
                  : (
                    Pointage.prestationsType.includes(5)
                      ? 3
                      :  Object.values(value.moment).reduce(
                        (equal, toTreat) => {
                          if (equal !== -1) return equal;
                          if (
                            SessionHandler.momentParser()[toTreat.id].operand(
                              today
                            )
                          )
                            return toTreat.id;
                          return -1;
                        },
                        value.moment[forceMoment] !== undefined ? forceMoment :
                        value.presta.includes(5) ?
                          3 :
                          -1
                    )),
              prestations: value.prestas,
              date: date,
              isStarting
            })
          );
        return value;
      });
  }
);
export const setPrestationsId = createAsyncThunk(
  "pointage/setPrestationsId",
  async ({ location, init }, { dispatch, getState }) => {
    dispatch(PointageSlice.actions.setPrestationsId(location));
    if (
      init &&
      [6, 8, 10].includes(
        Object.values(SessionHandler.prestationsParser()).find(
          (value) => value.url === location
        ).id[0]
      )
    ) {
      return dispatch(getPrestation());
    }
  }
);

export const getArrayPresta = createAsyncThunk(
  "pointage/getArrayPresta",
  async (nothing) =>
    PrestationHandler.getNbrOfEachTypePresta(
      new Db(crypt.getSessionDecryptedKey())
    )
);

export const getSignup = createAsyncThunk("pointage/GetSignup", async () => {
  return SignupHandler.getAllSignup(new Db(crypt.getSessionDecryptedKey()));
});

export const setMoment = createAsyncThunk(
  "pointage/setMoment",
  async ({ momentId, prestations, date, fetchChild, isStarting = false }, { dispatch }) => {
    if (momentId !== -1) 
      await dispatch(
        getReservation({
          momentId: momentId,
          prestations: prestations,
          date,
          fetchChild,
          isStarting,
        })
      );
    return momentId;
  }
);
export const getReservation = createAsyncThunk(
  "pointage/getReservation",
  async (
    { momentId = null, fetchChild = true, prestations = undefined, date, isStarting = false } = {},
    { dispatch, getState }
  ) => {
    var { Pointage } = getState();
    return ReservationHandler.getReservationByDayTypeAndPrestationId(
      new Db(crypt.getSessionDecryptedKey()),
      (prestations || Pointage.Prestations)
        .filter((value) => {
          if ([6, 8, 10].some((val) => Pointage.prestationsType.includes(val)))
            return (
              String(value.id) ===
              String(document.location.pathname.split("/")[3])
            );
          return (Number(momentId) || Pointage.momentID) === 1
            ? true
            : value.moment === (Number(momentId) || Pointage.momentID);
        })
        .reduce((old, value) => old.concat([value.id]), []),
      Pointage.prestationsType,
      formatDate(new Date(date ? date : Pointage.date))
    )
      .then((reservation) => reservation.filter((resa) => !resa.is_deleted))
      .then((value) => {
        var res = value;
        if (getLocationID(document.location).includes(10)) {
          // eslint-disable-next-line
          var prestation = Pointage.Prestations.find(presta => presta.id == document.location.pathname.split("/")[3]);
          var date_id = null;
          if (Pointage.idDate !== -1) {
            date_id = prestation.possible_dates.length > 0 ? prestation.possible_dates[Pointage.idDate].id : null;
          }
          if (date_id) {
            res = value.filter(resa => resa.date_id === null || resa.date_id === date_id);
           // res = value.filter(date => date.date_id === null || date.date_id === Pointage.Prestations.some(prestat => prestat.possible_dates[Pointage.idDate].id));
          }
        }
      if (fetchChild !== false)
        dispatch(
            FetchSomeChild({
              Resa: res,
              date,
              momentId,
              prestations: prestations || Pointage.Prestations,
              isStarting
            })
          );
        return res;
      });
  }
);

export const getAbsence = createAsyncThunk(
  "pointage/Absence",
  async () => {
    return AbsenceHandler.getAllAvailableAbsence(
      new Db(crypt.getSessionDecryptedKey())
    );
  },
  {
    condition: ({ Force = false } = {}, { getState }) => {
      const { Pointage } = getState();
      if (
        (Pointage === null ||
          !Pointage.absence) &&
        Force === false
      )
        return false;
    },
  }
);
export const getHolidays = createAsyncThunk(
  "pointage/Holidays",
  async () => {
    return ClosingDayHandler.GetHolidays(
      new Db(crypt.getSessionDecryptedKey())
    );
  },
  {
    condition: ({ Force = false } = {}, { getState }) => {
      const { Pointage } = getState();
      if (
        (Pointage === null ||
          !Pointage.holidays ||
          Pointage.holidays.length > 0) &&
        Force === false
      )
        return false;
    },
  }
);

export const getBuses = createAsyncThunk(
  "pointage/Buses",
  async () => BusesHandler.getAllBuses(new Db(crypt.getSessionDecryptedKey())),
  {
    condition: ({ Force = false } = {}, { getState }) => {
      const { Pointage } = getState();
      if (
        (Pointage === null || !Pointage.Bus || Pointage.Bus.length > 0) &&
        Force === false
      )
        return false;
    },
  }
);

export const getStops = (Pointage) => {
  var stops = [];
  Pointage.Bus.forEach(bus => {
    bus.stops.forEach(stop => {
      if (stops.find(el => el.id === stop.id) === undefined) {
        stops.push(stop);
      }
    })
  })

  return stops;
}

export const GetMealTypes = createAsyncThunk(
  "pointage/MealTypes",
  async () => MealTypesHandler.getAllMealTypes(new Db(crypt.getSessionDecryptedKey())),
  {
    condition: ({ Force = false } = {}, { getState }) => {
      const { Pointage } = getState();
      if (
        (Pointage === null ||
          !Pointage.MealTypes ||
          Pointage.MealTypes.length > 0) &&
        Force === false
      )
        return false;
    },
  }
);

var typingTimer;
var doneTypingInterval = 500;

export const searchChild = createAsyncThunk(
  "pointage/search",
  async (search, { dispatch }) => {
    clearTimeout(typingTimer);
    typingTimer = setTimeout(
      () => dispatch(FetchSomeChild({})),
      doneTypingInterval
    );
  }
);

const initialState = {
  date: 0,
  idDate: -1,
  closed: false,
  why: "",
  Pending: false,
  FullDay: false,
  SchoolHoliday: false,
  dayID: new Date().getDay(),
  prestationsType: [0],
  prestation: [],
  Prestations: [],
  Reservation: [],
  absence: [],
  momentID: -1,
  moment: {},
  arrayPresta: {},
  Signup: [],
  tri: "Nom",
  filter: filter(),
  search: "",
  swapFilter: filter(),
  Bus: [],
  prestaLimit: {},
  holidays: [],
  MealTypes: [],
};
const updateReservations = (state, { payload }) => {
  if (payload === undefined) return;
  state.Reservation.splice(
    state.Reservation.findIndex((value) => value.id === payload.id),
    1,
    payload
  );
};
export const PointageSlice = createSlice({
  name: "Pointage",
  initialState,
  reducers: {
    setIdDate: (state, { payload }) => {
      state.idDate = payload || -1;
    },
    setPrestationsId: (state, { payload }) => {
      state.prestationsType = Object.values(
        SessionHandler.prestationsParser()
      ).find((value) => value.url === payload).id;
    },
    resetPointageScreen: (state) => {
      state.momentID = -1;
      state.prestationsType = [0];
      state.Reservation = [];
    },
    setFilter: (
      state,
      { payload: { categorie: categories, checked, id, moment } }
    ) => {
      moment = moment || state.momentID;
      if (!categories || !id || moment < 1 || moment > 5) return;
      if (checked) {
        if (categories === "Special") {
          state.swapFilter[categories][moment].filter = [id];
        } else {
          state.swapFilter[categories][moment].filter.push(id);
        }
        state.swapFilter.active[moment] = true;
        state.swapFilter[categories][moment].enabled = true;
      } else {
        var index = state.swapFilter[categories][moment].filter.indexOf(id);
        if (index > -1) {
          state.swapFilter[categories][moment].filter.splice(index, 1);
          state.swapFilter[categories][moment].enabled =
            state.swapFilter[categories][moment].filter.length > 0;
          state.swapFilter.active[moment] =
            state.swapFilter.active[moment] &&
            Object.values(state.swapFilter).reduce((nbrValue, value) => {
              if (nbrValue || value[moment]?.enabled) {
                return true;
              }
              return false;
            }, false);
        }
      }
    },
    setOperator: (
      state,
      { payload: { categorie: categories, ope, value, moment } }
    ) => {
      moment = moment || state.momentID;
      if (!categories || moment < 1 || moment > 5) return;
      if (value) state.swapFilter[categories][moment].value = Number(value);
      if (ope) state.swapFilter[categories][moment].operator = Number(ope);
      state.swapFilter[categories][moment].enabled =
        state.swapFilter[categories][moment].operator !== 0;
      state.swapFilter.active[moment] =
        state.swapFilter.active[moment] &&
        state.swapFilter[categories][moment].enabled;
    },
    resetFilter: (state) => {
      state.swapFilter = filter();
      setFilterLocal(document.location.pathname.split("/")[2], filter());
    },
    ApplyFilter: (state) => {
      state.filter = state.swapFilter;
      setFilterLocal(document.location.pathname.split("/")[2], state.filter);
    },
    LoadFilter: (state) => {
      state.swapFilter = state.filter;
    },
    setTri: (state, { payload }) => {
      state.tri = payload;
    },
    setSearch: (state, { payload }) => {
      state.search = payload;
    },
    setPending: (state, { payload }) => {
      state.Pending = payload;
    },
    resetScreen: (state) => {
      state.date = 0;
      state.Reservation = [];
      state.filter = filter();
    },
    LoadFromStorageFilter: (state) => {
      state.swapFilter =
        getFilterLocal(document.location.pathname.split("/")[2]) || filter();
      state.filter =
        getFilterLocal(document.location.pathname.split("/")[2]) || filter();
    },
  },
  extraReducers: {
    [getHolidays.fulfilled]: (state, { payload }) => {
      state.holidays = payload;
    },
    [actionGlobal.pending]: (state, { payload }) => {
      state.Pending = true;
    },
    [actionGlobal.rejected]: (state, { payload }) => {
      state.Pending = false;
    },
    [actionGlobal.fulfilled]: (state, { payload: { allResa } }) => {
      state.Pending = false;
      if (allResa) {
        allResa.forEach((resa) => {
          updateReservations(state, { payload: resa });
        });
      }
    },
    [getBuses.fulfilled]: (state, { payload }) => {
      state.Bus = payload;
    },
    [updateAbsence.fulfilled]: (state, { payload }) => {
      updateReservations(state, { payload: payload.resa });
    },
    [updateHorairePresence.fulfilled]: (state, { payload }) => {
      if (payload?.resa !== undefined)
        updateReservations(state, { payload: payload.resa });
    },
    [getAbsence.fulfilled]: (state, { payload }) => {
      state.absence = payload;
    },
    [updatePresence.fulfilled]: (state, { payload }) => {
      if (payload === undefined) return;
      updateReservations(state, { payload: payload.resa });
      payload.multipleResa.forEach((resa) => {
        updateReservations(state, { payload: resa });
      });
    },
    [addReservation.pending]: (state) => {
      state.Pending = true;
    },
    [addReservation.fulfilled]: (state, { payload }) => {
      if (payload) state.Reservation.push(payload);
      state.Pending = false;
    },
    [addReservation.rejected]: (state) => {
      state.Pending = false;
    },
    [delReservation.pending]: (state) => {
      state.Pending = true;
    },
    [delReservation.rejected]: (state) => {
      state.Pending = false;
    },
    [delReservation.fulfilled]: (state, { payload }) => {
      if (payload === undefined) return;
      state.Reservation.splice(
        state.Reservation.findIndex((value) => value.id === payload.resaId),
        1
      );
      state.Pending = false;
    },
    [updateReservation.fulfilled]: (state, { payload }) => {
      updateReservations(state, { payload: payload.resa });
    },
    [setDate.pending]: (state) => {
      state.Pending = true;
      state.search = "";
    },
    [setDate.rejected]: (state, action) => {
      state.Pending = false;
    },
    [setDate.fulfilled]: (state, { payload }) => {
      if (payload.pending === true) state.Pending = false;
      state.date = payload.date;
      state.why = payload.why;
      state.dayID = new Date(payload.date).getDay();
      state.SchoolHoliday = payload.SchoolHoliday;
      state.idDate = Number(payload.dateId === undefined ? 0 : payload.dateId);
    },
    [GetMealTypes.fulfilled]: (state, { payload }) => {
      state.MealTypes = payload
    },
    [getPrestation.fulfilled]: (state, { payload }) => {
      state.prestation = payload.presta || [];
      state.Prestations = payload.prestas || [];
      state.prestaLimit = payload.prestaLimit || [];
      if (
        state.prestation.reduce((old, nex) => {
          return old + nex.length;
        }, 0) === 0
      ) {
        state.closed = true;
        state.why = "Aucune prestation disponible";
      } else {
        state.closed = false;
        state.why = "";
        state.FullDay = payload.FullDay;
        state.moment = payload.moment;
      }
    },
    [getArrayPresta.pending]: (state) => {
      state.Pending = true;
    },
    [getArrayPresta.rejected]: (state) => {
      state.Pending = false;
    },
    [getArrayPresta.fulfilled]: (state, { payload }) => {
      state.Pending = false;
      state.arrayPresta = payload;
    },
    [setMoment.fulfilled]: (state, { payload }) => {
      state.momentID = Number(payload);
      state.Pending = false;
    },
    [setMoment.rejected]: (state, { payload }) => {
      state.Pending = false;
    },
    [setMoment.pending]: (state, { payload }) => {
      state.Pending = true;
    },
    [getReservation.Pending]: (state, { payload }) => {
      state.Pending = true;
    },
    [getReservation.rejected]: (state, { payload }) => {
      state.Pending = false;
    },
    [getReservation.fulfilled]: (state, { payload }) => {
      state.Pending = false;
      state.Reservation = payload;
    },
    [getSignup.fulfilled]: (state, { payload }) => {
      state.Signup = payload;
    },
    [startupGetData.fulfilled]: (state, { payload }) => {
      state.absence = payload.absence;
      state.Signup = payload.signup;
      state.Bus = payload.bus;
      state.holidays = payload.closing;
      state.MealTypes = payload.meal_type;
    },
  },
});

export const {
  resetPointageScreen,
  setFilter,
  setOperator,
  resetFilter,
  ApplyFilter,
  LoadFilter,
  setTri,
  setSearch,
  setPending,
  setIdDate,
  resetScreen,
  LoadFromStorageFilter,
} = PointageSlice.actions;
export default PointageSlice.reducer;
