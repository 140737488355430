import SwaggerClient from "swagger-client";

export const GetApi = (token = null, Header = "application/json") => {
  return SwaggerClient({
    url: process.env.REACT_APP_SWAGGER_JSON,
    requestInterceptor: (req) => {
      req.headers["content-type"] = Header;
      req.headers["Cache-Control"] = "no-cache";
      req.headers["Authorization"] = `Bearer ${token}`;
      req.headers["X-Pirouette-Client-Version"] = String(
        process.env.REACT_APP_VERSION
      );
      return req;
    },
  });
};

export const GetAuth = (token, Header) =>
  GetApi(token, Header).then((client) => client.apis["Auth"]);
export const GetAssets = (token, Header) =>
  GetApi(token, Header).then((client) => client.apis["Assets"]);
export const GetChildren = (token, Header) =>
  GetApi(token, Header).then((client) => client.apis["Children"]);
export const GetSignupYears = (token, Header) =>
  GetApi(token, Header).then((client) => client.apis["Signup years"]);
export const GetReservations = (token, Header) =>
  GetApi(token, Header).then((client) => client.apis["Reservations"]);
export const GetPrestations = (token, Header) =>
  GetApi(token, Header).then((client) => client.apis["Prestations"]);
export const GetClosingDays = (token, Header) =>
  GetApi(token, Header).then((client) => client.apis["Closing days"]);
export const GetAbsenceType = (token, Header) =>
  GetApi(token, Header).then((client) => client.apis["Absence types"]);
export const GetGroups = (token, Header) =>
  GetApi(token, Header).then((client) => client.apis["Groups"]);
export const GetPresences = (token, Header) =>
  GetApi(token, Header).then((client) => client.apis["Presences"]);
export const GetBuses = (token, Header) =>
  GetApi(token, Header).then((client) => client.apis["Buses"]);
export const GetSync = (token, Header) =>
  GetApi(token, Header).then((client) => client.apis["Sync"]);
export const GetPointages = (token, Header) =>
  GetApi(token, Header).then((client) => client.apis["Pointages"]);
export const GetMealTypes = (token, Header) =>
  GetApi(token, Header).then((client) => client.apis["Meal types"]);
export const GetMealServices = (token, Header) =>
  GetApi(token, Header).then((client) => client.apis["Meal services"]);
export const GetAutorisations = (token, Header) =>
  GetApi(token, Header).then((client) => client.apis["Parameters"]);
export const GetVigilances = (token, Header) =>
  GetApi(token, Header).then((client) => client.apis["Vigilances"]);
export const GetEnd = (token, Header) =>
  GetApi(token, Header).then((client) => client.apis["End"]);