import { formatTimeDisplay } from "./date";
import { getLocationID } from "./useLocationId";

export { SessionHandler } from "./user";
export { TokenHandler } from "./token";
export { crypt } from "./crypt.js";
export { OnlineHandler, isOnline } from "./onlineTester";
export { DeviceIdentityHandler } from "./uuid";
export { Db } from "./db";
export { ChildHandler } from "./dbChild";
export { SignupHandler } from "./dbSignUp";
export { ContactHandler } from "./dbContact";
export { ClosingDayHandler } from "./dbClosingDay";
export { ReservationHandler } from "./dbResa";
export { HistoSyncHandler } from "./dbHistoSync";
export { AbsenceHandler } from "./dbAbsence";
export { PrestationHandler } from "./dbPrestation";
export { DateHandler } from "./date";
export { DeviceHandler } from "./deviceDetect";
export { GroupsHandler } from "./dbGroups";
export { MealServicesHandler } from "./dbMealServices";
export { BusesHandler } from "./dbBus";
export { MealTypesHandler } from "./dbMealtypes";
export { AutorisationsHandler } from "./dbAutorisations";
export { VigilancesHandler } from "./dbVigilances";

export {
  GetAuth,
  GetAssets,
  GetChildren,
  GetSignupYears,
  GetReservations,
  GetPrestations,
  GetClosingDays,
  GetAbsenceType,
  GetGroups,
  GetMealServices,
  GetPresences,
  GetBuses,
  GetMealTypes,
  GetAutorisations,
  GetVigilances,
} from "./Api";

export const cleanString = (word) =>
  word
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");

export const getPrestaLabel = (
  Prestations,
  prestationId,
  knowPresta = false
) => {
  var presta =
    knowPresta === true
      ? Prestations
      : Prestations.find((prestation) => prestation.id === prestationId);
  if (presta === undefined) return "";
  return presta.code ? presta.code : presta.name;
};

export const getPrestaName = (
  Prestations,
  prestationId,
  knowPresta = false
) => {
  var presta =
    knowPresta === true
      ? Prestations
      : Prestations.find((prestation) => prestation.id === prestationId);
  if (presta === undefined) return "";
  return presta.name;
};

export const getPrestaHoraire = (
  Prestations,
  prestationId,
  knowPresta = false
) => {
  var presta = knowPresta === true
    ? Prestations
    : Prestations.find((prestation) => prestation.id === prestationId);
  if (presta === undefined) return "";
  return "de " + formatTimeDisplay(presta.start_time) + " à " + formatTimeDisplay(presta.end_time);
}

export const findPrestations = (prestations, reservation) => {
  return prestations
    // .filter((prestation) => {
    //   if (
    //     document.location.pathname.split("/").length === 4 &&
    //     [10, 8, 6].some((type) =>
    //       getLocationID(document.location).includes(type)
    //     )
    //   )
    //     return (
    //       String(prestation.id) ===
    //       String(document.location.pathname.split("/")[3])
    //     );
    //   return true;
    // })
    .find((prestation) => reservation.prestation_id === prestation.id);
}
export const findReservationPrimaryByChildId = (
  reservations,
  prestations,
  child_id
) => {
  var Resa = reservations.filter((reservation) => {
    var presta = findPrestations(prestations, reservation);
    if (!presta || presta.secondary) return false;
    if (
      document.location.pathname.split("/").length === 4 &&
      [10, 8, 6].some((type) => getLocationID(document.location).includes(type))
    )
      return (
        String(presta.id) === String(document.location.pathname.split("/")[3])
      );
    return true;
  });
  return findReservationByChildId(Resa, child_id);
};

export const findAllReservationSecondaryByChildId = (
  reservations,
  prestations,
  child_id
) =>
  reservations.filter(
    (reservation) =>
      (findPrestations(prestations, reservation)?.secondary || false) &&
        reservation.child_id === child_id
  );

export const findReservationByChildId = (reservations, childId) =>
  reservations.find((reservation) => reservation.child_id === childId);

export const reduceBool = (label) => (old, newValue) => {
  if (!old) return false;
  return newValue[label] !== undefined ? newValue[label] : old;
};

export const reduceBoolTrue = (label) => (old, newValue) => {
  if (old !== undefined && old === true) return true;
  return newValue !== undefined && newValue[label] !== undefined
    ? newValue[label]
    : false;
};

export const useQuery = (location) => new URLSearchParams(location.search);
