import Compressor from "compressorjs";
const maxHeightPicture = 300;
const maxWidthPicture = 300;

export const formatImage = async (image) =>
  new Promise((resolve, reject) => {
    if (!image) return;
    return new Compressor(image, {
      quality: 0.6,
      width: maxWidthPicture,
      height: maxHeightPicture,
      maxHeight: maxHeightPicture,
      maxWidth: maxWidthPicture,
      minHeight: maxWidthPicture - 1,
      minWidth: maxHeightPicture - 1,
      strict: false,
      success(result) {
        resolve({ file: result, name: result.name });
      },
      error(err) {
        reject(err);
      },
    });
  });
