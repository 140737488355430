import React from "react";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { setIdDate } from "../../../actions/Database/Pointage";
import { getPrestaLabel } from "../../../helper";
import {
  checkIfDateIsInAllowed,
  dateIsGreeterThan,
  formatDate,
  incrementDate,
} from "../../../helper/date";

const PrestaSelector = ({
  Prestations,
  dispatch,
  checkDate,
  after,
  before,
}) => {
  var history = useHistory();
  var location = useLocation();
  return (
    <select
      value={location.pathname.split("/")[3]}
      onChange={(e) => {
        dispatch(setIdDate());
        history.push(
          `/pointage/${location.pathname.split("/")[2]}/${
            e.currentTarget.value
          }`
        );
      }}
      className="mw-10"
    >
      {Prestations.filter(
        ({ start_date, end_date, secondary }) =>
          !secondary &&
          (checkDate(start_date) ||
          checkDate(end_date) ||
          dateIsGreeterThan(end_date, before) ||
          dateIsGreeterThan(start_date, after))
      ).map((presta) => (
        <option value={presta.id} key={presta.id}>
          {getPrestaLabel(presta, undefined, true)}
        </option>
      ))}
    </select>
  );
};

export default connect(({ Pointage, Auth, Synchro }) => ({
  Prestations: Pointage.Prestations,
  checkDate: checkIfDateIsInAllowed(Auth, Synchro),
  after: new Date(
    formatDate(
      incrementDate(
        Synchro.lastSync ? new Date(Synchro.lastSync) : new Date(),
        0 - Auth.SessionData.RootPeriodBefore
      )
    )
  ),
  before: new Date(
    formatDate(
      incrementDate(
        Synchro.lastSync ? new Date(Synchro.lastSync) : new Date(),
        0 + Auth.SessionData.RootPeriodAfter
      )
    )
  ),
}))(PrestaSelector);
