import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import TopNavSynchro from "./topBarSynchro";
import { showBurger } from "../../../actions/showModal";
import IcomoonSVG from "../../IcomoonSVG";

const NavBar = ({ ServerAvailable, online, dispatch, SessionData }) => {
  return (
    <div className="TopNavBar">
      <Link to="/">
        {
          <img
            src={
              SessionData.CentreImage
                ? SessionData.CentreImage
                : process.env.PUBLIC_URL + "/pirouette.png"
            }
            alt="logo"
            className="header__logo"
          />
        }
      </Link>

      <div className="TopNavBar__infos">
        <div
          className={
            online && ServerAvailable
              ? "wifi wifi--connected"
              : "wifi wifi--not-connected"
          }
        >
          <span>
            {online && ServerAvailable
              ? "Connecté"
              : !ServerAvailable && online
              ? "Pas d'accès au serveur"
              : "Hors connexion"}
          </span>
          <IcomoonSVG
            icon={
              process.env.PUBLIC_URL +
              (online && ServerAvailable ? "wifi-on" : "wifi-off")
            }
          />
        </div>

        <TopNavSynchro />

        <button onClick={() => dispatch(showBurger())} className="burgerIcon">
          <IcomoonSVG icon="menu" />
        </button>
      </div>
    </div>
  );
};
export default connect(({ ShowModal, Auth }) => ({
  online: ShowModal.online,
  ServerAvailable: ShowModal.ServerAvailable,
  SessionData: Auth.SessionData,
}))(NavBar);
