import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  GetAuth,
  isOnline,
  SessionHandler,
  DeviceIdentityHandler,
  TokenHandler,
  crypt,
} from "../../helper";

import { Login } from "./Login";

export const ShowLostPin = createAsyncThunk(
  "Auth/ShowPinLost",
  async (nothing, { rejectWithValue }) => {
    if (crypt.hasCryptedKey() && TokenHandler.hasCryptedToken())
      return { show: true, to: "ForgotPin" };
    return rejectWithValue();
  }
);

export const HideLostPin = createAsyncThunk(
  "Auth/HideLostPin",
  async (nothing) => ({
    show: true,
    to: TokenHandler.hasCryptedToken() ? "LogWithPin" : "Login",
  })
);

export const ActionLostPin = createAsyncThunk(
  "Auth/LostPin",
  async ({ username, password }, { rejectWithValue, dispatch }) => {
    if (isOnline()) {
      var OldAccount = SessionHandler.getSession();
      return GetAuth().then((client) =>
        client
          .App_Center_API_Controllers_AuthController__getToken(
            {},
            {
              requestBody: {
                username: username,
                password: password,
                device_name: DeviceIdentityHandler.getDeviceName(),
                device_id: DeviceIdentityHandler.getUuid(),
              },
            }
          )
          .then((res) => {
            return GetAuth(res.body.token).then((clientWithToken) =>
              clientWithToken
                .App_Center_API_Controllers_AuthController__getUser()
                .then((res2) => res2.body.data)
                .then((data) => {
                  if (
                    data.first_name === OldAccount.Name &&
                    data.last_name === OldAccount.usersurname &&
                    data.id === OldAccount.id
                  ) {
                    dispatch(Login({ username, password, sync: false }));
                    return { show: true, to: "ForgotPin" };
                  } else {
                    localStorage.setItem("LostCodePin", JSON.stringify(data));
                    return rejectWithValue({
                      code: 0,
                      show: true,
                      to: "ForgotPin",
                    });
                  }
                })
            );
          })
          .catch((err) => {
            return rejectWithValue({
              show: true,
              to: "Login",
              code: err.status,
              ErrorContent: err.response.body,
            });
          })
      );
    } else {
      return rejectWithValue();
    }
  }
);
