
export function FilterCategory(props) {
    return (
        <button onClick={props.handleNav} className={`filter-category ${props.isActive ? "filter-category--active" : ""}`}>
            {props.children}
            {(props.activeAmount > 0 && (
                <div className="filter-category__amount">{props.activeAmount}</div>
            ))}
        </button>
    );
}
