import { TokenHandler, GetAutorisations  } from "../helper";
/**
 * Function that return the Autorisations Table
 * @param {DexieDb} db
 * @returns {AutorisationsTable}
 */
export const getAutorisationsTable = (db) =>
    db.getDB().table(db.getTableName().Autorisations);

export const clearAutorisations = (db) => db.clearTable(db.getTableName().Autorisations);

export const getAllAutorisations = (db) => getAutorisationsTable(db).toArray();

export const tableAutorisationsFiller = (db, dispatch, StepDone) => {
    return GetAutorisations(TokenHandler.getToken()).then((client) =>
        client
            .AutorisationsController__index()
            .then((res) => res.body.data)
            .then((res) => {
                return db.insertLotsOfData(res, db.getTableName().Autorisations).then(() =>
                    dispatch(
                        StepDone({
                            key: "Autorisations",
                            action: "Récupération des autorisations",
                            end: true,
                            size: res.length
                        })
                    )
                );
            })
    );
};

export const AutorisationsHandler = {
    getAllAutorisations,
    tableAutorisationsFiller,
    clearAutorisations,
};