import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  isOnline,
  TokenHandler,
  ReservationHandler,
  AbsenceHandler,
  crypt,
  Db,
  ChildHandler,
  SignupHandler,
  ContactHandler,
  ClosingDayHandler,
  PrestationHandler,
  GroupsHandler,
  AutorisationsHandler,
  VigilancesHandler,
  BusesHandler,
  MealTypesHandler,
  MealServicesHandler,
} from "../helper";
import { emptyChild } from "./Database/Child";
import { hideSynchro } from "./showModal";

/**
 * * Function that fill the DB
 *  * Test if the token is still usable and wipe the data if the API ask to do so
 */
export const FillTheDatabase = createAsyncThunk(
  "database/Filler",
  async (action, { dispatch }) => {
    dispatch(hideSynchro());
    dispatch(emptyChild());
    var WipeDatabase = action.WipeDatabase || false;
    var wipeEverything = action.wipeEverything || false;
    if (
      isOnline() &&
      TokenHandler.hasDecryptedToken() &&
      crypt.hasSessionDecryptedKey()
    ) {
      var Database = new Db(crypt.getSessionDecryptedKey());
      if (WipeDatabase === true) {
        await Promise.all([
          ChildHandler.ClearChild(Database),
          SignupHandler.ClearSignup(Database),
          ClosingDayHandler.ClearClosingDay(Database),
          AbsenceHandler.ClearAbsence(Database),
          ReservationHandler.ClearReservations(Database),
          PrestationHandler.ClearPrestation(Database),
          GroupsHandler.clearGroups(Database),
          AutorisationsHandler.clearAutorisations(Database),
          VigilancesHandler.clearVigilances(Database),
          BusesHandler.cleanBuses(Database),
          MealTypesHandler.cleanMealTypes(Database),
          MealServicesHandler.clearMealServices(Database),
        ]);
      }
      if (wipeEverything === true) return Database.wipeDatabase();
      var allPromise = [
        SignupHandler.TableSignupFiller(Database, dispatch, Step).then(
          (signupID) => {
            return Promise.all([
              ChildHandler.TableChildFiller(
                Database,
                dispatch,
                Step,
                signupID,
              ),
            ]).then(() => {
              return ContactHandler.TableContactFiller(
                Database,
                dispatch,
                Step,
              );
            });
          }
        ),
        ClosingDayHandler.TableClosingDayFiller(Database, dispatch, Step),
        AbsenceHandler.TableAbsenceFiller(Database, dispatch, Step),
        ReservationHandler.TableReservationsFiller(Database, dispatch, Step),
        PrestationHandler.tablePrestationFiller(Database, dispatch, Step),
        GroupsHandler.tableGroupsFiller(Database, dispatch, Step),
        AutorisationsHandler.tableAutorisationsFiller(Database, dispatch, Step),
        VigilancesHandler.tableVigilancesFiller(Database, dispatch, Step),
        BusesHandler.TableBusesFiller(Database, dispatch, Step),
        MealTypesHandler.TableMealTypesFiller(Database, dispatch, Step),
        MealServicesHandler.TableMealServicesFiller(Database, dispatch, Step),
      ];
      dispatch(setNbrAction(allPromise.length + 4));
      return Promise.all(allPromise)
        .then((value) => {
          dispatch(emptyChild());
          return value;
        })
        .catch((err) => {
          dispatch(setError(true));
          console.log(err);
        });
    }
  },
  {
    condition: (something, { getState }) => {
      const { Progress } = getState();
      if (Progress.pending === true) {
        return false;
      }
    },
  }
);

export const Synchronisation = createAsyncThunk(
  "database/Synchro",
  async (action, { dispatch }) => {}
);

const increment = (status, action) => {
  var object;
  if (!status.hasOwnProperty(action.key))
    object = Object.assign({}, action, { index: 0 });
  else object = status[action.key];
  object.index = object.index + 1;
  if (object.index > object.size) object.size = object.index;
  if (action.end) {
    object.size = object.index = action.size;
  }
  if (object.size === object.index) {
    object.ended = true;
  }
  return object;
};
const initialState = (status = false, actionInProgress = false, stat = {}) => {
  return {
    pending: status,
    Status: stat,
    showActionInProgress: actionInProgress,
    nbrAction: 0,
    finished: 0,
    error: false,
  };
};
const ProgressSlicer = createSlice({
  name: "Progress",
  initialState: initialState(),
  reducers: {
    Step: (state, action) => {
      state.Status[action.payload.key] = increment(
        state.Status,
        action.payload
      );
      if (state.Status[action.payload.key].ended)
        state.finished = Object.values(state.Status).reduce(
          (oldValue, value) => {
            if (value.ended) {
              return oldValue + 1;
            }
            return oldValue;
          },
          0
        );
    },
    HideNotification: (state, action) => {
      state.showActionInProgress = false;
    },
    ShowNotification: (state, action) => {
      state.showActionInProgress = true;
    },
    Empty: (state, action) => initialState(),
    setPending: (state, { payload }) => {
      state.pending = payload;
    },
    setNbrAction: (state, { payload }) => {
      state.nbrAction = payload;
    },
    setError: (state, { payload }) => {
      state.error = payload;
    },
  },
  extraReducers: {
    [FillTheDatabase.pending]: (state, action) =>
      initialState(true, true, {
        done: {
          key: "done",
          action:
            "Ne vous déconnectez pas d'internet tant que cette action n'est pas terminée",
          index: 0,
          size: 1,
        },
      }),
    [FillTheDatabase.rejected]: (state, action) => {
      state.Status["done"] = {
        key: "Error",
        action:
          "Impossible d'accéder à l'api/Une erreur a eu lieu lors de l'import des données",
        index: 1,
        size: 1,
      };
      state.pending = false;
    },
    [FillTheDatabase.fulfilled]: (state, action) => {
      state.Status["done"] = {
        key: "done",
        action: "La tablette est prête à être utilisée hors connexion",
        index: 1,
        size: 1,
      };
    },
  },
});

export const {
  ShowNotification,
  HideNotification,
  Empty,
  Step,
  setPending,
  setNbrAction,
  setError,
} = ProgressSlicer.actions;
export default ProgressSlicer.reducer;
