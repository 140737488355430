import React from "react";
import QrCode from "qrcode.react";
import {
  getIdUser,
  getNomCentre,
  getPermissionLevel,
  getUserName,
} from "../helper/user";
class ErrorHandler extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, errorStack: "", error: "" };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log(errorInfo.componentStack, error.message);
    this.setState({
      hasError: true,
      errorStack: errorInfo.componentStack,
      error: error.message,
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div>
          <h1>Une erreur est survenue, veuillez actualiser la page</h1>
          <p>
            Si l'erreur est récurrente veuillez prendre une capture d'écran et
            la transmettre à votre administrateur :
          </p>
          <div style={{ display: "flex" }}>
            <div>
              <p>Version de l'application : {process.env.REACT_APP_VERSION}</p>
              <p>Structure : {getNomCentre()}</p>
              <p>Identifiant utilisateur : {getUserName()}</p>
              <p>Message d'erreur : {this.state.error}</p>
              <button onClick={() => window.location.reload()}>
                Actualiser la page
              </button>
            </div>
            <QrCode
              size={500}
              includeMargin={true}
              value={JSON.stringify({
                url: window.location.href,
                version: process.env.REACT_APP_VERSION,
                error: this.state.error,
                whoFailed: this.state.errorStack,
                centre: getNomCentre(),
                username: getUserName(),
                userId: getIdUser(),
                PermissionsLevel: getPermissionLevel(),
              })}
            />
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}
export default ErrorHandler;
