import React, { useRef, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { ChildHandler } from '../../helper'
import { Image } from 'react-bootstrap'
import { ShowChildModal } from '../../actions/Database/Child'
import ModalChildPicto from '../modal/ModalChildren/modalChildHeaderPicto'
import { dateIsBetweenOrEqualDate, dateStringToDate } from '../../helper/date'
import { getVigilancesForChild } from '../../helper/vigilances'
const ChildrenDisplay = ({
  onClick = null,
  SpecificContent = () => <div></div>,
  date = new Date(),
  Signup,
  child,
  dispatch,
  style,
  setSize,
  windowWidth,
  index,
  even,
  isSortByFirstName = false,
  vigilancesList,
}) => {
  const childRef = useRef()
  const specificRef = useRef()
  const [needSmall, setNeedSmall] = useState(false)
  useEffect(() => {
    if (setSize && childRef.current !== undefined) {
      var height
      if (window.innerWidth >= 1280 || (needSmall && window.innerWidth > 992)) {
        height = 100
      } else if (window.innerWidth >= 992 || needSmall) {
        height = 200
      } else {
        height = 300
      }
      setSize(index, height)
    }
  }, [setSize, index, windowWidth, needSmall])
  if (child === undefined || child.family === undefined) return <div></div>
  var childAge = ChildHandler.GetChildAge(child.birth_date, true, date)
  const OnClick = () => {
    if (onClick === null)
      dispatch(ShowChildModal({ ChildID: child.id, date: new Date(date).toString() }))
    else onClick()
  }
  var signup = child.signups.find(
    (value) =>
      value.year_id ===
      Signup.find((signupG) =>
        dateIsBetweenOrEqualDate(
          new Date(date),
          dateStringToDate(signupG.start_date),
          dateStringToDate(signupG.end_date)
        )
      )?.id
  )
  if (!signup) return <></>
  var signupGlobal = Signup.find((signupG) => signupG.id === signup?.year_id)

  const vigilances = getVigilancesForChild(vigilancesList, child)

  return (
    <div key={child.id} className={`child__line ${even ? 'child__even' : ''}`} style={style}>
      <div ref={childRef} className='child__portrait' onClick={OnClick}>
        {child.has_photo ? (
          <Image className='child__photo' src={child.photo} alt="Photo de l'enfant" />
        ) : (
          <div className='child__nopicture'>
            <svg className='icon icon__no_photography' focusable='false'>
              <use
                href={process.env.PUBLIC_URL + '/picto/symbol-defs.svg#icon__no_photography'}
              ></use>
            </svg>
          </div>
        )}
        <span className='child__moreinfos'>+ d'infos</span>
      </div>

      <div className='child__infos'>
        <div className='child__title'>
          {isSortByFirstName ? (
            <p>
              {child.first_name} {child.last_name}{' '}
            </p>
          ) : (
            <p>
              {child.last_name} {child.first_name}{' '}
            </p>
          )}
          <ModalChildPicto
            isHandicaped={signup?.is_handicapped || false}
            splitParents={false} //signup?.split_parents ||
            hasPai={signup?.has_pai !== 0}
            vigilances={vigilances}
          />
        </div>
        {signup !== undefined && (
          <p>
            {`${childAge} an${childAge > 1 ? 's' : ''}`}
            {signup.level && ' - '}
            {signup.level && ChildHandler.GetStringLevel()[signup.level]}
            {signup.teacher_id !== null && ' : '}
            {signup.teacher_id !== null &&
              signupGlobal.teachers.find((value) => signup.teacher_id === value.id)?.name}
          </p>
        )}
        {child.alias ? (
          <p className='child__family'>{child.alias}</p>
        ) : (
          <p className='child__family'>Famille : {child.family.family_name}</p>
        )}
      </div>
      <SpecificContent
        ref={specificRef}
        index={index}
        child={child.id}
        setNeedSmall={setNeedSmall}
      />
    </div>
  )
}

export default connect((state, ownProps) => ({
  Signup: state.Pointage.Signup,
  child: state.Child.filteredChild.find((child) => child.id === ownProps.index),
  vigilancesList: state.Child.vigilances,
}))(ChildrenDisplay)
