import { TokenHandler, GetGroups } from "../helper";
/**
 * * Function that return the Groups Table
 * @param {DexieDb} db
 * @returns {GroupsTable}
 */
export const getGroupsTable = (db) =>
  db.getDB().table(db.getTableName().Groups);

export const clearGroups = (db) => db.clearTable(db.getTableName().Groups);

export const getAllGroups = (db) => getGroupsTable(db).toArray();

export const tableGroupsFiller = (db, dispatch, StepDone) => {
  return GetGroups(TokenHandler.getToken()).then((client) =>
    client
      .App_Center_API_Controllers_ChildGroupsController__index()
      .then((res) => res.body.data)
      .then((res) => {
        return db.insertLotsOfData(res, db.getTableName().Groups).then(() =>
          dispatch(
            StepDone({
              key: "Groups",
              action: "Récupération des groupes",
              end: true,
              size: res.length,
            })
          )
        );
      })
  );
};

export const GroupsHandler = {
  getAllGroups,
  tableGroupsFiller,
  clearGroups,
};
