import React, { useEffect } from "react";

import { connect } from "react-redux";
import { setPending } from "../../actions/Database/Pointage";
import { GeneraleSync } from "../../actions/Database/Synchro";
import { showAskPin } from "../../actions/showModal";
import Modal from "./Modal";
const ModalReset = ({ ShowModal, hideReset, dispatch, date }) => {
  useEffect(() => {
    if (ShowModal.reset && ShowModal.askPinResult === true) {
      hideReset();
      dispatch(setPending(true));
      dispatch(
        GeneraleSync({
          sync: "nop",
          WipeDatabase: true,
          force: true,
          after: () => dispatch(setPending(false)),
        })
      );
    }
    // eslint-disable-next-line
  }, [ShowModal.askPinResult]);
  return (
    <Modal
      label="Remise à zéro"
      show={ShowModal.reset && !ShowModal.askPin}
      hide={hideReset}
      modalBodyClassName="center"
    >
      {(!ShowModal.online || !ShowModal.ServerAvailable) && (
        <p className="color--error">
          Vous ne pouvez pas remettre à zéro{" "}
          {(!ShowModal.online && "car vous n'êtes pas connecté") ||
            ", le serveur n'est pas accessible"}
          .
        </p>
      )}

      <p className="color--error withMargin">
        <strong>
          Attention la remise à zéro va effacer toutes les modifications que
          vous aurez effectuées depuis la dernière synchronisation et vous
          synchronisera avec le serveur.
        </strong>
      </p>

      <div className="form__btns">
        <button
          onClick={() =>
            dispatch(showAskPin({ for: "remise à zéro de la tablette" }))
          }
          disabled={!ShowModal.online || !ShowModal.ServerAvailable}
          className="btn btn--secondary"
        >
          Remettre à zéro
        </button>
        <button onClick={hideReset} className="btn btn--light btn--link">
          Annuler
        </button>
      </div>
    </Modal>
  );
};

export default connect(({ ShowModal, Pointage }) => ({
  ShowModal: {
    online: ShowModal.online,
    ServerAvailable: ShowModal.ServerAvailable,
    reset: ShowModal.reset,
    askPinResult: ShowModal.askPinResult,
    askPin: ShowModal.askPin,
  },
  date: Pointage.date,
}))(
  React.memo(ModalReset, (oldProps, newProps) => {
    if (
      oldProps.ShowModal.reset !== newProps.ShowModal.reset ||
      newProps.ShowModal.reset
    )
      return false;
    return true;
  })
);
