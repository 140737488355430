import { TokenHandler, GetAbsenceType } from "../helper";

/**
 * * Function that return the Absence table
 * @param {DexieDb} db
 */
export const getAbsenceTable = (db) =>
  db.getDB().table(db.getTableName().Absence);

/**
 *  * Function that clear the Absence table
 * @param {DexieDb} db
 */
export const clearAbsence = (db) => db.clearTable(db.getTableName().Absence);

/**
 * * Function that fill the database
 * @param {DexieDb} db
 * @param {Redux Dispatch} dispatch
 * @param {Redux Action} StepDone
 */
export const tableAbsenceFiller = (db, dispatch, StepDone) => {
  return GetAbsenceType(TokenHandler.getToken()).then((client) => {
    return client
      .App_Center_API_Controllers_AbsenceTypesController__index()
      .then((res) => res.body.data)
      .then((data) => {
        return db.insertLotsOfData(data, db.getTableName().Absence).then(() =>
          dispatch(
            StepDone({
              key: "absenceType",
              action: "Récupération des types d'absence",
              end: true,
              size: data.length,
            })
          )
        );
      });
  });
};

export const getAllAvailableAbsence = (db) => getAbsenceTable(db).toArray();

export const AbsenceHandler = {
  ClearAbsence: clearAbsence,
  TableAbsenceFiller: tableAbsenceFiller,
  getAllAvailableAbsence,
};
