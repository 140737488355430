import React from "react";
import { connect } from "react-redux";
import Modal from "./Modal";
import { ChangeChildPicture } from "../../actions/Database/Child";
import ImageResize from "../ImageResize";

const CropImage = ({ crop, hideCrop, dispatch }) => {
  const handleChangePicture = async (urlNewImg) => {
    return await fetch(urlNewImg)
      .then(r => r.blob())
      .then(blobFile => new File([blobFile], "nomdufichier", {type: "image/jpeg"}))
      .then(function(result) {
        dispatch(
          ChangeChildPicture({
            ChildId: crop.childId,
            Picture: result,
          })
        );
        hideCrop();
      });
  }
  return (
    <Modal size="xl" show={crop.show} hide={hideCrop} label="Validation de l'image">
      <ImageResize handleChangePicture={handleChangePicture} urlFile={crop.picture} hideCrop={hideCrop} />
    </Modal>
  );
};

export default connect(({ ShowModal }) => ({ crop: ShowModal.crop }))(
  CropImage
);

// soluce Stack Overflow https://stackoverflow.com/questions/19262141/resize-image-with-javascript-canvas-smoothly
