import React from "react";
import { connect } from "react-redux";
import { setSearch } from "../../actions/Database/Pointage";
import {
  setShowAllAbsent,
  setShowAllPresent,
  setShowReset,
} from "../../actions/showModal";
import { useLocationId } from "../../helper/useLocationId";

const ActionTopPointage = ({ search, dispatch, location }) => {
  const [locationId] = useLocationId();
  const GlobalAction = [
    {
      label: "Tout pointer présent",
      action: () => dispatch(setShowAllPresent(true)),
    },
    {
      label: "Tout pointer absent",
      action: () => dispatch(setShowAllAbsent(true)),
    },
    {
      label: "Supprimer les pointages",
      action: () => dispatch(setShowReset(true)),
    },
  ];
  if (
    [6, 10, 8].some((Id) => locationId.includes(Id)) &&
    location.pathname.split("/").length === 3
  )
    return <></>;
  return (
    <>
      <input
        className="subBar__search"
        placeholder="Rechercher dans la liste"
        value={search}
        autoComplete={"off"}
        onChange={(e) => {
          dispatch(setSearch(e.currentTarget.value));
        }}
      />
      <div className="dropdown dropdown--secondary">
        <button
          className="btn btn--secondary dropdown-toggle"
          type="button"
          id="dropdownMenuButton"
          onClick={(e) =>
            e.target.closest(".dropdown").classList.toggle("dropdown--show")
          }
        >
          Actions groupées
        </button>
        <div id="globalAction" className="dropdown-menu">
          {GlobalAction.map((action) => (
            <button
              key={action.label}
              className="dropdown-item"
              onClick={(e) => {
                e.target
                  .closest(".dropdown")
                  .classList.toggle("dropdown--show");
                action.action();
              }}
            >
              {action.label}
            </button>
          ))}
        </div>
      </div>
    </>
  );
};

export default connect(({ Pointage }) => ({ search: Pointage.search }))(
  ActionTopPointage
);
