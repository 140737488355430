import { TokenHandler, GetPrestations, DateHandler } from "../helper";

/**
 * * Function that return the Prestation table
 * @param {DexieDb} db
 */
export const getPrestationTable = (db) =>
  db.getDB().table(db.getTableName().Prestations);

/**
 *  * Function that clear the Prestations table
 * @param {DexieDb} db
 */
export const clearPrestation = (db) =>
  db.clearTable(db.getTableName().Prestations);
export const getPrestationByIsInPossibleDayTypeAndDate = (
  db,
  dayOfTheWeek,
  type,
  date
) => {

  if (type === 5 && typeof dayOfTheWeek !== "string") {
    dayOfTheWeek = dayOfTheWeek.toString();
  }

  return getPrestationTable(db)
    .where('possible_days').equals(dayOfTheWeek)
    .and(function(p) {
      /*
      #354
      Les prestations "journée spéciale" sont désormais retournées en même temps que celles de leur service_type
      C'est à dire, si on demande les prestas "ado", on aura aussi les prestas "journée spéciale" avec comme type de service "ado"
      */
      return p.type === type || (p.type === 7 && p.service_type === type)
    })
    .filter((presta) => filterByDate(date, presta));
};

export const getAllPrestations = (db) =>
  getPrestationTable(db).toCollection().toArray();

export const getPrestationByType = (db, type) => {
  return getPrestationTable(db).where({ type: type }).toArray();
}
export const getNbrOfEachTypePresta = async (db) => {
  const result = {};
  await getPrestationTable(db)
    .orderBy("type")
    .eachKey((presta) => {
      result[presta] = (result[presta] || 0) + 1;
    });
  return result;
};

const filterByDate = (date, prestations) => {
  if (prestations.end_date) {
    return DateHandler.DateIsBetweenOrEqualDate(
      date,
      DateHandler.DateStringToDate(prestations.start_date),
      DateHandler.DateStringToDate(prestations.end_date)
    );
  } else {
    return DateHandler.dateIsGreeterThan(
      date,
      DateHandler.DateStringToDate(prestations.start_date)
    );
  }
};

const mapPrestaPossibleDays = (presta) => {
  var result;
  if (presta.possible_days.length !== 0) result = presta.possible_days;
  else {
    if (presta.possible_dates.length > 0) {
      result = presta.possible_dates.reduce((concat, current) => {
        if (
          !concat.includes(DateHandler.DateStringToDate(current.date).getDay())
        ) {
          concat.push(DateHandler.DateStringToDate(current.date).getDay());
        }
        return concat;
      }, []);
    }
    if (presta.type === 8) {
      result = [DateHandler.DateStringToDate(presta.start_date).getDay()];
    }
    if (presta.type === 6) {
      result = [0, 1, 2, 3, 4, 5, 6];
    }
  }
  if (
    result !== undefined &&
    result.includes(7) &&
    result.findIndex((item) => item === 7) !== -1
  )
    result[result.findIndex((item) => item === 7)] = 0;
  return result;
};

export const tablePrestationFiller = (db, dispatch, step) => {
  return GetPrestations(TokenHandler.getToken()).then((client) =>
    client
      .App_Center_API_Controllers_PrestationsController__index()
      .then((res) => res.body.data)
      .then((res) => {
        return db
          .insertLotsOfData(
            res.map((presta) => ({
              ...presta,
              moment: presta.moment === null ? 1 : presta.moment,
              possible_days: mapPrestaPossibleDays(presta),
            })),
            db.getTableName().Prestations
          )
          .then(() =>
            dispatch(
              step({
                key: "Prestations",
                action: "Récupération des prestations et filtres associés",
                end: true,
                size: res.length,
              })
            )
          );
      })
  );
};

export const PrestationHandler = {
  GetPrestationTable: getPrestationTable,
  ClearPrestation: clearPrestation,
  tablePrestationFiller,
  getPrestationByIsInPossibleDayTypeAndDate,
  getPrestationByType,
  getNbrOfEachTypePresta,
};
