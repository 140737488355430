import IcomoonSVG from "../../IcomoonSVG";
import React, { useState } from "react";
import { setFilter } from "../../../actions/Database/Pointage";

export function CheckboxDropdown({
    children,
    isActive,
    dispatch,
    category,
    categoryId,
    id,
    label,
    name,
    childrens,
}) {
    const [isOpened, setIsOpened] = useState(isActive);
    const handleClick = (e) => {
        setIsOpened(!isOpened);
        dispatch(
            setFilter({
                categorie: category,
                checked: e.currentTarget.checked,
                id: categoryId,
            })
        )
        if (!e.currentTarget.checked && (childrens !== null && childrens !== undefined)) {
            childrens.array.forEach(child => {
                dispatch(
                    setFilter({
                        categorie: childrens.category,
                        checked: false,
                        id: child.id
                    })
                )
            })
        }
    };

    return (
        <div className={`checkbox-dropdown ${isOpened ? "opened" : ""}`}>
            <label htmlFor={id} className="form-check">
                <input onClick={handleClick} defaultChecked={isOpened} type="checkbox" name={name} id={id} />
                <span>{label}</span>
                <IcomoonSVG icon="arrow-down"></IcomoonSVG>
            </label>
            <div className="dropdown-content">
                {children}
            </div>
        </div>
    )
}
