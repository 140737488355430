import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  GetAuth,
  Db,
  crypt,
  TokenHandler,
  DeviceIdentityHandler,
} from "../helper";
import { GetApi } from "../helper/Api";
const reject = (rejectWithValue) =>
  rejectWithValue({
    code: 0,
    show: true,
    to: TokenHandler.hasCryptedToken() ? "LogWithPin" : "Login",
  });
export const Startup = createAsyncThunk(
  "Auth/Startup",
  async (nothing, { rejectWithValue, getState }) => {
    var { ShowModal } = getState();
    if (process.env.REACT_APP_DEBUG === "true")
      GetApi().then((client) => console.log(client.apis));
    if (!TokenHandler.hasCryptedToken() && !crypt.hasCryptedKey())
      return rejectWithValue({ code: 1, show: true, to: "Login" });
    if (ShowModal.online && ShowModal.ServerAvailable) {
      return GetAuth()
        .then((client) =>
          client
            .App_Center_API_Controllers_AuthController__getDeviceInfo(
              { id: DeviceIdentityHandler.getUuid() },
              {}
            )
            .then((res) => {
              if (process.env.REACT_APP_DEBUG === "true")
                console.log("startup:", res.body);
              if (res.body.must_wipe) {
                new Db().wipeDatabse();
              }
              if (
                res.body.revoked_at != null &&
                Date.parse(res.body.revoked_at) < Date.now()
              ) {
                TokenHandler.ResetCryptedToken();
                return reject(rejectWithValue);
              }
              return { show: true, to: "LogWithPin" };
            })
        )
        .catch((err) => {
          if (!TokenHandler.hasCryptedToken() && err.status === 404)
            return reject(rejectWithValue);
          return { show: true, to: "LogWithPin" };
        });
    } else return { show: true, to: "LogWithPin" };
  }
);
