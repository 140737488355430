import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { isAndroid } from "../helper/deviceDetect";
import {
  getDontWantInstall,
  isInstalled,
  setDontWantInstall,
} from "../helper/sworker";
import * as serviceWorkerRegistration from "../serviceWorkerRegistration";
import { hideInfo } from "./showModal";

let triggerEvent;
let reg;
export const registerWorker = createAsyncThunk(
  "serviceWorker/Register",
  async (nothing, { dispatch }) => {
    console.log(
      `The application is in ${process.env.NODE_ENV} and run in ${process.env.REACT_APP_VERSION}`
    );
    console.log("Run on PWA MODE", isInstalled());
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      triggerEvent = e;
      if (getDontWantInstall() === null || getDontWantInstall() === "null")
        dispatch(setShowInstallPrompt(true));
      dispatch(setCanBeInstalled(true));
    });
    window.addEventListener("appinstalled", (e) => {
      dispatch(showGoToPWa(true));
    });
    if ("getInstalledRelatedApps" in window.navigator && isAndroid()) {
      navigator.getInstalledRelatedApps().then((relatedApp) => {
        console.log(relatedApp);
        if (relatedApp.length >= 1 && !isInstalled()) {
          dispatch(showGoToPWa(true));
        }
      });
    }
    serviceWorkerRegistration.register({
      onUpdate: (registration) => {
        reg = registration;
        dispatch(hideInfo());
        dispatch(setShowNeedUpdate(true));
      },
      onSuccess: (registration) => {
        dispatch(setShowInstallPrompt(false));
      },
    });
  }
);

export const forceUpgrade = createAsyncThunk(
  "serviceWorker/ForceUpgrade",
  async (nothing, { rejectWithValue, dispatch, getState }) => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.getRegistration().then((registration) => {
        if (registration !== undefined) {
          registration.update();
          setTimeout(() => {
            if (!getState().serviceWorker.showNeedUpdate) {
              dispatch(setError({ why: "Aucune mise à jour disponible." }));
            }
          }, 2000);
        } else {
          dispatch(
            setError({
              why: "Votre navigateur n'est pas compatible avec l'application.",
            })
          );
        }
      });
    }
  }
);

export const validateInstall = createAsyncThunk(
  "serviceWorker/InstallPrompt",
  async (nothing, { dispatch }) => {
    if (!triggerEvent) return false;
    triggerEvent.prompt();
    triggerEvent.userChoice.then((response) => {
      if (response.outcome === "accepted") {
        dispatch(setShowInstallPrompt(false));
        setDontWantInstall(null);
      }
      triggerEvent = null;
    });
  }
);
export const dontWantInstall = createAsyncThunk(
  "serviceWorker/DontWant",
  async (nothing, { dispatch }) => {
    dispatch(setShowInstallPrompt(false));
    setDontWantInstall(false);
  }
);

export const update = createAsyncThunk(
  "serviceWorker/Update",
  async (nothing, { dispatch }) => {
    if (reg) {
      reg.waiting.postMessage({ type: "SKIP_WAITING" });
      window.location.reload();
    }
  }
);

const serviceWorker = createSlice({
  name: "serviceWorker",
  initialState: {
    installed: false,
    showInstallPrompt: false,
    showNeedUpdate: false,
    showGoToPWa: false,
    canBeInstalled: false,
    Pending: false,
    error: {
      show: false,
      why: "",
    },
  },
  reducers: {
    setShowInstallPrompt: (state, { payload }) => {
      state.showInstallPrompt = payload;
    },
    setShowNeedUpdate: (state, { payload }) => {
      state.showNeedUpdate = payload;
    },
    showGoToPWa: (state, { payload }) => {
      state.showGoToPWa = payload;
    },
    setCanBeInstalled: (state, { payload }) => {
      state.canBeInstalled = payload;
    },
    setError: (state, { payload }) => {
      state.error.show = true;
      state.error.why = payload.why;
    },
  },
  extraReducers: {
    [forceUpgrade.pending]: (state) => {
      state.Pending = true;
    },
    [forceUpgrade.fulfilled]: (state) => {
      state.Pending = false;
    },
    [forceUpgrade.rejected]: (state) => {
      state.Pending = false;
    },
  },
});

export const {
  setShowInstallPrompt,
  setShowNeedUpdate,
  showGoToPWa,
  setCanBeInstalled,
  setError,
} = serviceWorker.actions;

export default serviceWorker.reducer;
