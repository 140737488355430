/**
 *
 * Cette fonction permet de récupérer les vigilances (enfant et famille) en passant la liste des vigilances et l'enfant.
 *
 */
export function getVigilancesForChild(vigilances, child) {
  return [
    ...vigilances.filter((vigilance) => child.vigilances.includes(vigilance.id)),
    ...vigilances.filter((vigilance) => child.family.vigilances.includes(vigilance.id)),
  ]
}
