import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { updateReservation } from "../../../actions/Database/Resa";
import { getPrestaHoraire, getPrestaName } from "../../../helper";
import { formatTimeDisplay } from "../../../helper/date";
import Modal from "../../modal/Modal";

const ModalInfo = ({
  updateResa,
  hideUpdateResa,
  child,
  Reservation,
  Prestations,
  dispatch,
}) => {
  const [prestation, setPrestation] = useState(-1);
  const [checked, setChecked] = useState(null);
  useEffect(() => {
    if (updateResa.isLinkHoraire) {
      setPrestation(updateResa.prestaSuggest.id);
      setChecked(updateResa.prestaSuggest.id);
    }
  }, [updateResa.isLinkHoraire, updateResa.prestaSuggest])
  if (updateResa.childId === -1) return <div></div>;
  if (child === undefined) return <div></div>;
  var resaActuelle = Reservation.find((resa) => resa.child_id === child.id);
  var prestaAvailable = updateResa.prestaAvailable;

  return (
    <Modal
      show={updateResa.show}
      hide={hideUpdateResa}
      modalHeaderClassName={updateResa.isLinkHoraire && 'modal-header--error'}
      label={updateResa.isLinkHoraire ? "La prestation actuelle ne couvre pas les horaires pointés" : "Changement de prestation"}
    >
      {updateResa.isLinkHoraire ?
      (<p>Les horaires de pointage ne correspondent pas à la prestation actuellement sélectionnée ({getPrestaName(Prestations, resaActuelle.prestation_id) + " " + getPrestaHoraire(Prestations, resaActuelle.prestation_id)}) pour <strong>{child.first_name} {child.last_name}</strong>.
      Une nouvelle prestation correspondant à ces horaires a été sélectionnée automatiquement, vous pouvez la changer :</p>) :
      (
        <>
          <p>Veuillez sélectionner la nouvelle prestation pour <strong>{child.first_name} {child.last_name}</strong> :</p>
          <p>
            <span className={updateResa.isLinkHoraire ? "tag tag--error" : "tag tag--primary"}>
              Prestation actuelle :{" "}
              <strong>
                {getPrestaName(Prestations, resaActuelle.prestation_id)}
              </strong>
            </span>
          </p>
        </>
      )}
      <br />
      {updateResa.serverAsked && (
        <div>
          <p>Présence de l'enfant :</p>
          <p>Arrivée : {(updateResa.isLinkHoraire && updateResa.resa) ? formatTimeDisplay(updateResa.resa.arrived_at) : formatTimeDisplay(resaActuelle.arrived_at)} </p>
          <p>Depart : {(updateResa.isLinkHoraire && updateResa.resa) ? formatTimeDisplay(updateResa.resa.departed_at) : formatTimeDisplay(resaActuelle.departed_at)}</p>
        </div>
      )}
      <br />
      <ul className="list--unstyled">
        {prestaAvailable.length === 0 && (
          <p className="color--error">
            Aucune autre prestation n'est disponible pour cet enfant.
          </p>
        )}
        {prestaAvailable.filter(presta => presta.id !== resaActuelle.prestation_id)
                        .map((presta) => (
            <li key={presta.id} className="form__grp">
              <label>
                <input
                  type="radio"
                  name="prestaAddResa"
                  value={presta.id}
                  onClick={(e) => setPrestation(e.currentTarget.value)}
                  onChange={() => setChecked(presta.id)}
                  checked={checked === presta.id}
                />
                {presta.name}{" "}
                <p>de {formatTimeDisplay(presta.start_time)} à {formatTimeDisplay(presta.end_time)}</p>
              </label>
            </li>
          ))}
      </ul>

      <div className="form__btns">
        <button
          className="btn btn--secondary"
          disabled={prestation === -1 || prestaAvailable.length <= 0}
          onClick={() =>
            dispatch(
              updateReservation({
                resaId: resaActuelle.id,
                newPrestaId: prestation,
              })
            ).then(() => {
              hideUpdateResa();
              setPrestation(-1);
              setChecked(null);
            })
          }
        >
          Changer la prestation
        </button>
        <button className="btn btn--light btn--link" onClick={hideUpdateResa}>
          {updateResa.isLinkHoraire ? 'Ne pas changer la prestation' : 'Annuler'}
        </button>
      </div>
    </Modal>
  );
};

export default connect(({ ShowModal, Pointage, Child }) => ({
  child: Child.filteredChild.find(
    (childToTreat) => childToTreat.id === ShowModal.updateResa.childId
  ),
  updateResa: ShowModal.updateResa,
  Reservation: Pointage.Reservation,
  Prestations: Pointage.Prestations,
}))(
  React.memo(ModalInfo, (oldProps, newProps) => {
    if (oldProps.updateResa !== newProps.updateResa || newProps.updateResa)
      return false;
    return true;
  })
);
