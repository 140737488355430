import React from "react";
import ModalChildBodyGeneralDispParent from "./modalChildBodyDispParent";

const ModalChildBodyAutorisations = ({ signup, child }) => {
  var Contacts = child.links.filter((contact) => contact.transport);
  return (
    <div className="grid">
      <div className="child__info">
        <dt>Liste des personnes autorisées à venir chercher l'enfant :</dt>
      </div>
      <div className="grid__row">
        {Contacts.length > 0 ? (
          Contacts.map((contact) => (
            <ModalChildBodyGeneralDispParent key={contact.id} {...contact} />
          ))
        ) : (
          <p className="grid__col12">Aucun contact disponible</p>
        )}
      </div>
    </div>
  );
};

export default ModalChildBodyAutorisations;
