import React, { useState } from "react";
import { connect } from "react-redux";
import { Db, crypt, ChildHandler } from "../../../helper";
import { formatDateDisplay } from "../../../helper/date";
import { nl2br } from "../../../helper/format";
import { prestationTypeParser, serviceTypeParser } from "../../../helper/user";
import IcomoonSVG from "../../IcomoonSVG";

const ModalChildBodyGeneral = ({ child, signup, Child, Pointage, Autorisations, dispatch }) => {
  const [comment, setComment] = useState(child.structure_comment || '');
  const saveComment = (newComment) => {
    var db = new Db(crypt.getSessionDecryptedKey());
    dispatch(
      ChildHandler.ChangeChildComment({
        db,
        ChildId: child.id,
        Comment: newComment
      })
    );
  };

  // Fonction appelée à chaque changement dans le textarea
  // eslint-disable-next-line no-unused-vars
  const handleCommentChange = (e) => {
    const newComment = e.target.value;
    setComment(newComment);  // Met à jour l'état
    saveComment(newComment); // Sauvegarde automatiquement à chaque changement
  };
  return (
    <div className="grid">
      <div className="grid__row">
        <div className="grid__col6">
          <div className="child__comment">
            <label id="text-comment">Commentaire</label>
            {comment ? (
            <>
              <div className="child__comment-auteur">
                Dernière modification par {child.structure_lastcomment_by} le {formatDateDisplay(child.structure_lastcomment_at)}
              </div>
              {nl2br(comment)}
            </>
            ) : (
              <div className="child__comment-auteur">
                Pas de commentaire
              </div>
            )}
          </div>
          <br></br>
          <div className="child__autorisations">
            <dl>
              <div className="child__info">
                <dt>Autorisation</dt>
                <dd>
                  {signup.autorisations.length > 0 ?
                    signup.autorisations.map(autorisation => (
                      Autorisations.some(item => item.id === autorisation.id) ?
                      <p
                        className={
                          autorisation.allowed ? "color--success" : "color--error"
                        }
                        key={"autorisation-" + Autorisations.find(item => item.id === autorisation.id).name}
                      >
                        {Autorisations.find(item => item.id === autorisation.id).name} :{" "}
                        <strong>{autorisation.allowed ? "Oui" : "Non"}</strong>
                        <IcomoonSVG icon={autorisation.allowed ? "check" : "cross"} />
                      </p> : ""
                    ))
                  : <p>Aucune autorisation n'a été effectuée</p>}
                </dd>
              </div>
            </dl>
          </div>
        </div>
        <div className="grid__col6">
          {(child.structure_notes ||
            child.family.structure_notes ||
            signup.split_parents ||
            signup.affectations.some(affect => affect.group_id !== null) ||
            signup.affectations.some(affect => affect.mealservice_id !== null) ||
            signup.regime_infos) && (
            <dl>
              {signup.affectations.some(affect => affect.group_id !== null) &&
                  signup.affectations
                    .filter(affect =>
                            affect.group_id !== null &&
                            // Détection si le type du service est lié au type de prestation choisi
                            Pointage.prestationsType.filter(type =>
                              prestationTypeParser()[type] === serviceTypeParser()[affect.type])
                                                    .length > 0)
                    .map(affect => (
                      <div className="child__info">
                        <dt>Groupes</dt>
                        <dd>{Child.childGroup.find(group => group.id === affect.group_id).name}</dd>
                      </div>
                    ))}
              {signup.affectations.some(affect => affect.mealservice_id !== null) &&
                Pointage.prestationsType.includes(5) &&
                <div className="child__info">
                  <dt>Services</dt>
                  {signup.affectations
                    .filter(affect => affect.mealservice_id !== null)
                    .map(affect => (
                      <dd>{Child.childMealService.find(mealservice => mealservice.id === affect.mealservice_id).name}</dd>
                    ))}
                </div>
              }

              {(child.structure_notes || child.family.structure_notes) && (
                <div className="child__info">
                  <dt>Note interne</dt>
                  {child.structure_notes && (
                    <dd><strong>Enfant :</strong><br /> <div dangerouslySetInnerHTML={{ __html: child.structure_notes.replace(/(?:\r\n|\r|\n)/g, '<br />') }}></div></dd>
                  )}
                  {child.family.structure_notes && (
                    <dd><strong>Famille :</strong><br /> <div dangerouslySetInnerHTML={{ __html: child.family.structure_notes.replace(/(?:\r\n|\r|\n)/g, '<br />') }}></div></dd>
                  )}
                </div>
              )}

              {signup.split_parents && (
                <div className="child__info">
                  <dt>Mode garde de l'enfant</dt>
                  <dd>
                    {ChildHandler.GetStringGuardMode()[signup.guard_mode]}
                  </dd>
                </div>
              )}

              {signup.regime_infos && (
                <div className="child__info">
                  <dt>Régime spécial</dt>
                  <dd>{signup.regime_infos}</dd>
                </div>
              )}
            </dl>
          )}
        </div>
      </div>
    </div>
  );
};

export default connect(({ Pointage, Child }) => ({
  Child: Child,
  Pointage: Pointage,
  Autorisations: Child.autorisations,
}))(ModalChildBodyGeneral);
