export const isOnline = () => navigator.onLine;
export const isOnlineListener = (onlineCallback) => {
  window.ononline = onlineCallback;
};
export const isOfflineListener = (offlineCallback) => {
  window.onoffline = offlineCallback;
};

export const isServerReachable = () => {
  return fetch(`${process.env.REACT_APP_SERVEUR}/center/ping`)
    .then((res) => {
      return res.ok;
    })
    .catch(() => false);
};

export const OnlineHandler = {
  isOnline,
  isOnlineListener,
  isOfflineListener,
  isServerReachable,
};
