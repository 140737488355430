import React from "react";
import { PhoneNumber } from "./PhoneNumber";

const ModalChildBodySanitaire = ({ signup }) => {
  return (
    <div className="grid">
      <div className="grid__row">
        <div className="grid__col6">
          <div className="child__info">
            <dt>Difficultés de santé</dt>
            <dd>{signup.health_issues != null ? signup.health_issues : "Non"}</dd>
          </div>

          <div className="child__info">
            <dt>Recommandations utiles</dt>
            <dd>{signup.health_infos != null ? signup.health_infos : "Non"}</dd>
          </div>

          <div className="child__info">
            <dt>Suit un traitement médical</dt>
            <dd>{signup.has_medication ? "Oui" : "Non"}</dd>
          </div>

          <div className="child__info">
            <dt>Est vacciné</dt>
            <dd>{signup.is_vaccinated ? "Oui" : "Non"}</dd>
          </div>

          <div className="child__info">
            <dt>En situation de handicap</dt>
            <dd>{signup.is_handicapped ? "Oui" : "Non"}</dd>
          </div>

          <div className="child__info">
            <dt>Bénéficie d'un PAI</dt>
            <dd>{signup.has_pai ? "Oui" : "Non"}</dd>
          </div>
        </div>
        <div className="grid__col6">
          { signup.allergy_food && (
            <p className="color--error">Allergie alimentaire : <strong>Oui</strong></p>
          )}
          {signup.allergy_medication && (
            <p className="color--error">
              Allergie médicamenteuse : <strong>Oui</strong>
            </p>
          )}
          {signup.allergy_asthma && (
            <p className="color--error">
              Asthme : <strong>Oui</strong>
            </p>
          )}
          {signup.allergy_other && (
            <p className="color--error">
              Autres allergies : <strong>Oui</strong>
            </p>
          )}
          {signup.allergy_infos != null && (
            <div className="child__info">
              <dt>Conduite à tenir</dt>
              <dd>{signup.allergy_infos}</dd>
            </div>
          )}
        </div>
      </div>
      <div className="grid__row">
        <div className="grid__col12">
          <h4>Médecin traitant </h4>
          {signup.doctor ? (
            <p>
              {signup.doctor.name} - Téléphone : <PhoneNumber phone={signup.doctor.phone_number} /> -{' '}
              {signup.doctor.address}
              {signup.doctor.city && (
                <>
                  , {signup.doctor.city.name} {signup.doctor.city.zip_code}
                </>
              )}
            </p>
          ) : (
            <p>Aucun médecin renseigné</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ModalChildBodySanitaire;
