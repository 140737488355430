import React from "react";
import { connect } from "react-redux";
import {
  addReservation,
  updatePresence,
} from "../../../../actions/Database/Resa";
import { getPrestaLabel } from "../../../../helper";

const ResaSecondaireButton = ({
  presta,
  childId,
  resaId,
  resaIsPresent,
  isUnexpected,
  dispatch,
  mainPresta = null,
}) => {
  var color =
    ((resaIsPresent === null || resaIsPresent === undefined) &&
      (resaId === undefined || isUnexpected ? "light" : "base")) ||
    (Number(resaIsPresent) === 1 && "success") ||
    (Number(resaIsPresent) === 0 && "error");
  return (
    <button
      id={presta.id}
      onClick={() => {
        if (resaId === undefined)
          dispatch(addReservation({ childId, prestaId: presta.id, mainPresta: mainPresta }));
        else {
          var presence;

          if (resaIsPresent === null) {
            presence = "1";
          }
          if (
            typeof resaIsPresent === "number" &&
            Number(resaIsPresent) === 0
          ) {
            presence = "2";
          }
          if (
            typeof resaIsPresent === "number" &&
            Number(resaIsPresent) === 1
          ) {
            presence = 0;
          }
          if (Boolean(isUnexpected) && resaIsPresent === 1) presence = "2";
          dispatch(
            updatePresence({
              resaId: resaId,
              presenceId: presence,
            })
          );
        }
      }}
      className={`btn btn--${color}`}
    >
      {getPrestaLabel(presta, null, true)}
    </button>
  );
};

export default connect()(ResaSecondaireButton);
