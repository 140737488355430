import React from "react";
import { connect } from "react-redux";

const PwaInstall = ({ ...props }) => {
  return <div>
            <h1>Application Pirouette installée</h1>
            <p className="text--center">L'application semble installée sur votre périphérique.<br />Nous vous recommandons de l'utiliser en fermant cette page et en passant par l'icône Pirouette présente sur votre écran d'accueil.</p></div>;
};

export default connect((state) => state)(PwaInstall);
